import { urlConditional } from './apiInfo';
import { getToken } from '../constants/localStorage';

const baseURL = urlConditional();

const PromoCodeAPI = {
  validate(request) {
    return fetch(`${baseURL}/validate-promo-code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${getToken()}`,
      },
      body: JSON.stringify(request),
    }).then((response) => response.json());
  },
};

export default PromoCodeAPI;
