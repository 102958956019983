import React from 'react';
import { format } from 'date-fns';
import StarRating from '../../StarRating/StarRating';
import './ProductReview.css';
import { useMediaQuery } from 'react-responsive';
import { Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import useLoggedInMemberInfo from '../../../hooks/useLoggedInMemberInfo';

const ProductReview = ({ review }) => {
  const {
    id,
    title,
    rating,
    description,
    created_at: createdAt,
  } = review || {};
  const history = useHistory();
  const formattedDate = format(new Date(createdAt), 'MMM d, yyyy');
  const mobile = useMediaQuery({ maxWidth: 600 });
  const { memberId } = useLoggedInMemberInfo();
  const isUserReview = memberId == review.member.id;
  const reviewUser = review.member.user;
  const [firstName, lastName] = [reviewUser.first_name, reviewUser.last_name];
  const displayName = `${firstName} ${lastName} ${
    isUserReview ? '(You)' : ''
  }`.trim();

  const handleClickEdit = (id) => {
    history.push(`/review/product/edit?id=${id}`);
  };

  return (
    <div className="container">
      <div className="flex-row">
        <Avatar
          icon={<UserOutlined />}
          src={review.member.image_path || '#'}
          style={{ marginRight: 8 }}
        />
        <span>{displayName}</span>
      </div>
      <div className={mobile ? 'flex-column' : 'flex-row'}>
        <StarRating rating={rating} />
        <div id="review-title">{title}</div>
      </div>
      <div className="flex-row">
        <div id="review-date">Reviewed on {formattedDate}</div>
        {isUserReview && (
          <Button
            size="small"
            style={{ marginLeft: 4 }}
            onClick={() => handleClickEdit(id)}
          >
            Edit
          </Button>
        )}
      </div>
      <div>{description}</div>
    </div>
  );
};

export default ProductReview;
