import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

export const PaymentForm = ({
  secret,
  handlePaymentSuccess,
  handleBackToGearSelect,
  orderProducts,
  order,
  setConfirmButtonClicked,
  confirmButtonClicked,
  paymentResponse,
}) => {
  const { state: pageState } = useLocation();

  const [paymentError, setPaymentError] = useState(() => '');
  // const [confirmButtonClicked, setConfirmButtonClicked] = useState(() => false);
  const [renter, setRenter] = useState(() => pageState.renter);
  const [displayDiscountMessage, setDisplayDiscountMessage] = useState(() => {
    if (
      paymentResponse.payment.rental_charge - paymentResponse.payment.discount <
      1
    )
      return true;
    else return false;
  });

  const stripe = useStripe();
  const elements = useElements();

  const handleConfirmPayment = async (event) => {
    // we don't want to let default form submission happen here,
    // which would refresh the page
    event.preventDefault();

    // clear any previous payment error when trying to pay
    setPaymentError('');

    setConfirmButtonClicked(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded
      // make sure to disable form submission until Stripe.js has loaded
      return;
    }

    const confirmPaymentResponse = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: renter.user.first_name + ' ' + renter.user.last_name,
        },
      },
      receipt_email: renter.user.email,
    });

    if (confirmPaymentResponse.error) {
      setPaymentError(confirmPaymentResponse.error);
    } else {
      if (confirmPaymentResponse.paymentIntent.status === 'succeeded') {
        handlePaymentSuccess(confirmPaymentResponse);
      }
    }
  };

  const displayErrorMessage = () => {
    if (paymentError && paymentError.code === 'card_declined') {
      return (
        <div className="payment-error-message">The card was declined.</div>
      );
    } else if (paymentError && paymentError.code === 'incorrect_cvc') {
      return (
        <div className="payment-error-message">
          The card's cvc code is incorrect.
        </div>
      );
    } else if (paymentError && paymentError.code === 'incorrect_zip') {
      return (
        <div className="payment-error-message">
          The card's zip code is incorrect.
        </div>
      );
    } else if (paymentError && paymentError.code === 'expired_card') {
      return <div className="payment-error-message">The card is expired.</div>;
    } else if (paymentError) {
      return (
        <div className="payment-error-message">
          There was an error processing the payment. Please try again.
        </div>
      );
    }
  };

  function shouldDisplayDiscout() {
    if (parseFloat(paymentResponse.payment.discount) > 0) return true;
    return false;
  }

  function displayDiscountPercentage() {
    if (paymentResponse.payment.discount_percentage)
      return ` (${paymentResponse.payment.discount_percentage}%)`;
  }

  return (
    <div className="payment-details-component">
      <div className="payment-details-item-list">
        {orderProducts.map((orderProduct, index) => {
          return (
            <div key={index} className="payment-details-item-and-price">
              <div className="payment-details-item-quantity">
                {orderProduct.quantity} x
              </div>
              <div className="payment-details-item">
                {orderProduct.product.title}
              </div>
              <div className="payment-details-item-price">
                ${orderProduct.product.price * orderProduct.quantity}
              </div>
            </div>
          );
        })}
      </div>
      <div className="payment-details-container">
        <div className="payment-details-labels">
          <div>Rental duration:</div>
          <div>Subtotal:</div>
          <div>Rental fee (15%):</div>
          <div hidden={!shouldDisplayDiscout()}>
            Discount{displayDiscountPercentage()}:
          </div>
          <div>Total:</div>
        </div>
        <div className="payment-details-values">
          <div>
            {order.duration} {order.duration == 1 ? 'day' : 'days'}
          </div>
          <div>${paymentResponse.payment.subtotal}</div>
          <div>${paymentResponse.payment.rental_fee}</div>
          <div hidden={!shouldDisplayDiscout()}>
            - ${paymentResponse.payment.discount}
          </div>
          <div>${paymentResponse.payment.rental_charge}</div>
        </div>
      </div>
      <div
        className="payment-minimum-transaction-message"
        hidden={!displayDiscountMessage}
      >
        The minimum transaction Stripe can process is $1, so we have to cap your
        discount at $1. Sorry for the inconvenience!
      </div>
      <div className="payment-card-element-container">
        <CardElement
          className="payment-card-element"
          options={{
            style: {
              base: {
                fontSize: '20px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
                lineHeight: '30px',
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
      <div className="payment-form-button-container">
        <button
          className="select-gear-button action"
          onClick={handleConfirmPayment}
          disabled={!stripe || !elements || confirmButtonClicked}
        >
          Confirm
        </button>
        <button
          className="select-gear-button"
          onClick={handleBackToGearSelect}
          disabled={!stripe || !elements || confirmButtonClicked}
        >
          Cancel
        </button>
      </div>
      {displayErrorMessage()}
    </div>
  );
};
