import React from 'react';
import './RentRequest.css';
import useScrollToTop from '../../hooks/useScrollToTop';
import RentRequestFormNew from './RentRequestFormNew';
import tentDesert from '../../images/landingImages/tent-desert-cropped.jpg';

const ItemRequests = ({ routerProps }) => {
  useScrollToTop();

  return (
    <div>
      <section>
        <article
          id="rentRequestPageContainer"
          style={{
            backgroundImage: `url(${tentDesert})`,
          }}
        >
          <div className="rentRequestFlexColumn">
            <RentRequestFormNew />
          </div>
        </article>
      </section>
    </div>
  );
};

export default ItemRequests;
