import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const rentRequestManager = {
  createRentRequests(request) {
    return fetch(`${baseUrl}/rent-request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(request),
    }).then((resp) => resp.json());
  },
};

export default rentRequestManager;
