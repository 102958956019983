import React, { useState, useEffect } from 'react';
import ProfileIcon from '../../images/profileIcon.png';
import './ProfileImage.css';

const ProfileImage = ({ src, memberInfo, classStringImg, classStringDiv }) => {
  return (
    <div className={classStringDiv}>
      {memberInfo.image_path ? (
        <img
          className={classStringImg}
          style={{ boxShadow: 'none' }}
          src={src}
          alt="profile"
        />
      ) : (
        <img
          className="detailsImage"
          style={{ boxShadow: 'none' }}
          src={ProfileIcon}
          alt="profile"
        />
      )}
    </div>
  );
};

export default ProfileImage;
