const zipLists = {
  bayAreaZips: [
    94558, 94533, 95620, 95476, 94559, 94954, 94571, 94535, 94503, 94949, 94945,
    94512, 94591, 94510, 94592, 94589, 94947, 94590, 94946, 94561, 94525, 94569,
    94585, 94103, 94565, 94903, 94520, 94572, 94553, 94547, 94963, 94938, 94502,
    94509, 94960, 94513, 94109, 94521, 94930, 94973, 94933, 94598, 94564, 94801,
    94519, 94806, 94901, 94531, 94803, 94601, 94523, 94518, 94904, 94115, 94549,
    94517, 94805, 94804, 94939, 94964, 94530, 94925, 94596, 94708, 94105, 94941,
    94563, 94720, 94707, 94514, 94970, 94706, 94710, 94104, 94595, 94709, 94703,
    94704, 94507, 94702, 94965, 94556, 94920, 94118, 94705, 94611, 94618, 94609,
    94550, 94608, 94528, 94526, 94506, 94130, 94607, 94123, 94610, 94583, 94602,
    94612, 94546, 94133, 94129, 94606, 94111, 94619, 94121, 94102, 94552, 94501,
    94108, 94605, 94613, 94117, 94122, 94621, 94114, 94107, 94110, 94588, 94131,
    94603, 94116, 94124, 94127, 94577, 94132, 94112, 94134, 94568, 94578, 94015,
    94005, 94014, 94579, 94580, 94541, 94566, 94542, 94544, 94044, 94545, 94586,
    94080, 94587, 94066, 94128, 94401, 94019, 94030, 94555, 94038, 94010, 94536,
    94539, 94402, 94404, 94403, 94538, 94560, 94065, 94063, 94027, 94002, 94070,
    95134, 95002, 94062, 94089, 94301, 94025, 94303, 95035, 95140, 94061, 94043,
    94304, 94305, 94035, 94306, 94028, 94040, 94022, 94085, 94086, 94024, 94087,
  ],
  nashvilleAreaZips: [
    37201, 37203, 37204, 37205, 37206, 37207, 37208, 37209, 37210, 37211, 37212,
    37214, 37215, 37216, 37217, 37218, 37219, 37220, 37221, 37013, 37072, 37076,
    37138, 38401, 38402, 38401, 38402, 37174, 37073, 37172, 37188, 37086, 37127,
    37128, 37129, 37130, 37167, 37022, 37048, 37066, 37072, 37075, 37148, 37186,
    37188, 37014, 37027, 37046, 37062, 37064, 37067, 37069, 37135, 37179, 37121,
    37122, 37087, 37090, 37138, 37184, 37082, 37213,
  ],
  stanfordZips: [
    94002, 94003, 94005, 94010, 94011, 94012, 94013, 94014, 94015, 94017, 94018,
    94019, 94020, 94021, 94022, 94023, 94024, 94025, 94026, 94027, 94028, 94029,
    94030, 94031, 94035, 94037, 94038, 94039, 94040, 94041, 94042, 94043, 94044,
    94045, 94059, 94060, 94061, 94062, 94063, 94064, 94065, 94066, 94067, 94070,
    94071, 94074, 94080, 94083, 94085, 94086, 94087, 94088, 94089, 94090, 94096,
    94098, 94099, 94128, 94301, 94302, 94303, 94304, 94305, 94306, 94307, 94308,
    94309, 94310, 94401, 94402, 94403, 94404, 94405, 94406, 94407, 94408, 94409,
    94497, 94550, 95002, 95006, 95008, 95009, 95011, 95013, 95014, 95015, 95020,
    95021, 95023, 95026, 95030, 95031, 95032, 95033, 95035, 95036, 95037, 95038,
    95042, 95044, 95046, 95050, 95051, 95052, 95053, 95054, 95055, 95056, 95070,
    95071, 95101, 95102, 95103, 95106, 95108, 95109, 95110, 95111, 95112, 95113,
    95114, 95115, 95116, 95117, 95118, 95119, 95120, 95121, 95122, 95123, 95124,
    95125, 95126, 95127, 95128, 95129, 95130, 95131, 95132, 95133, 95134, 95135,
    95136, 95137, 95138, 95139, 95140, 95141, 95142, 95148, 95150, 95151, 95152,
    95153, 95154, 95155, 95156, 95157, 95158, 95159, 95160, 95161, 95164, 95170,
    95171, 95172, 95173, 95190, 95191, 95192, 95193, 95194, 95196,
  ],
  vetAdventureGroupZips: [97701],
};

export default zipLists;
