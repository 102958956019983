import React, { useState, useEffect } from 'react';
import authManager from '../../modules/AuthManager.js';
import { Input, Spin, Checkbox, Modal } from 'antd';
import { termsOfService, privacyPolicy } from '../../constants/termsOfService';
import Recaptcha from 'react-recaptcha';
import compressImage from '../../helpers/compressImage';
import G2GLogo from '../../images/G2G_light_green_transparent_background-01-01.png';
import EventAPI from '../../modules/eventAPI';

const { TextArea } = Input;

const Register = ({ routerProps }) => {
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [isVerifiedNotRobot, setVerifiedNotRobot] = useState(false);
  const [tosOpen, setTosOpen] = useState(false);
  const [ppOpen, setppOpen] = useState(false);
  const [buttonClicked, changeButtonClicked] = useState(false);
  const [image, setImage] = useState({
    imageFile: { image: null },
    imagePath: 'Choose File',
  });
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
    agreed_terms: false,
    newsletter: true,
    referred_by: '',
  });

  const TOSModal = () => {
    return (
      <Modal
        title="Gear2Go Terms of Service"
        visible={tosOpen}
        onOk={() => {
          setCredentials({ ...credentials, agreed_terms: true });
          toggleTOS();
        }}
        onCancel={() => {
          setCredentials({ ...credentials, agreed_terms: false });
          toggleTOS();
        }}
      >
        <div className="terms-container">{termsOfService}</div>
      </Modal>
    );
  };

  const PPModal = () => {
    return (
      <Modal
        title="Gear2Go Privacy Policy"
        visible={ppOpen}
        onOk={() => {
          setCredentials({ ...credentials, agreed_terms: true });
          togglePP();
        }}
        onCancel={() => {
          setCredentials({ ...credentials, agreed_terms: false });
          togglePP();
        }}
      >
        <div className="terms-container">{privacyPolicy}</div>
      </Modal>
    );
  };

  const handleFieldChange = (evt) => {
    if (evt.target.type === 'checkbox') {
      const stateToChange = { ...credentials };
      stateToChange[evt.target.id] = evt.target.checked;
      setCredentials(stateToChange);
    } else {
      const stateToChange = { ...credentials };
      stateToChange[evt.target.id] = evt.target.value;
      setCredentials(stateToChange);
    }
  };

  function handleFileUpload(e) {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;
    if (e.target.files[0]) {
      if (!allowedExtensions.exec(e.target.value)) {
        window.alert(
          'Invalid file type. Please use jpg, jpeg, png, gif, or webp'
        );
      } else if (e.target.files[0].size < 600000) {
        setImage({
          imageFile: e.target.files[0],
          imagePath: e.target.files[0].name,
        });
      } else {
        compressImage(e).then((compressedImage) => {
          setImage({
            imageFile: compressedImage,
            imagePath: compressedImage.name,
          });
        });
      }
    } else {
      console.log('no image');
    }
  }

  const validPassword = (password) => {
    const hasAtLeastOneCapitalLetter = /[A-Z]/g;
    if (hasAtLeastOneCapitalLetter.test(password) && password.length >= 5)
      return true;
    else return false;
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (!validPassword(credentials.password)) {
      window.alert(
        'Please make sure your password is at least 5 characters long and contains at least one capital letter.'
      );
      return;
    }

    const formData = new FormData();

    formData.append('username', credentials.username);
    formData.append('password', credentials.password);
    formData.append('first_name', credentials.first_name);
    formData.append('last_name', credentials.last_name);
    formData.append('email', credentials.email.toLowerCase());
    formData.append('address', parseInt(credentials.address));
    formData.append('phone_number', credentials.phone_number);
    formData.append('agreed_terms', credentials.agreed_terms);
    formData.append('newsletter', credentials.newsletter);
    formData.append('verify_token', isVerifiedNotRobot);
    formData.append('source', window.location.href);
    formData.append('referred_by', credentials.referred_by);
    {
      image.imagePath !== 'Choose File'
        ? formData.append('image_path', image.imageFile, image.imagePath)
        : formData.append('image_path', false);
    }

    if (credentials.password !== credentials.passwordVerify) {
      window.alert('The passwords entered do not match. Please try again.');
    } else if (
      !credentials.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      window.alert('Please enter a valid email.');
    } else if (
      !credentials.phone_number.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      )
    ) {
      window.alert('Please enter a valid phone number.');
    } else if (credentials.agreed_terms !== true) {
      window.alert(
        'You must agree to the terms of service to continue creating your account.'
      );
    } else if (isVerifiedNotRobot === false) {
      window.alert(
        'Please verify that you are human by completing the captcha.'
      );
    } else if (
      credentials.password &&
      credentials.first_name &&
      credentials.last_name &&
      credentials.email &&
      credentials.phone_number
    ) {
      changeButtonClicked(true);

      return authManager
        .registerUser(formData)
        .then((parsedResponse) => {
          if ('token' in parsedResponse) {
            routerProps.history.push('/account-verify');

            // Set sign-up event, so we know to prompt the user to refer a friend next time they visit the rent page
            const email = localStorage.getItem('email');
            EventAPI.setEvent({ event_name: 'sign-up', email: email });
          } else {
            window.alert('Please fix your credentials and try again.');
            changeButtonClicked(false);
          }
        })
        .catch((error) => {
          window.alert(
            'An account already exists for this email address. Please click the Login link at the bottom of the page and login with your username and password.'
          );
          changeButtonClicked(false);
          console.log(error);
        });
    } else {
      window.alert('Please fill out all fields');
    }
  };

  function buttonOrLoader() {
    if (buttonClicked) {
      return <Spin size="small" />;
    } else {
      return (
        <input
          className="btn"
          type="button"
          name=""
          value="Register"
          onClick={handleRegister}
        />
      );
    }
  }

  const toggleTOS = () => {
    setTosOpen(!tosOpen);
  };

  const togglePP = () => {
    setppOpen(!ppOpen);
  };

  function verifyHuman(response) {
    if (response) {
      setVerifiedNotRobot(response);
    }
  }

  var recaptchaLoaded = function () {
    console.log('Recaptha loaded');
  };

  useEffect(() => {
    buttonOrLoader();
  }, [buttonClicked]);

  return (
    <section id="creationForm" className="register-box">
      <form onSubmit={handleRegister}>
        <img className="main-logo" src={G2GLogo} style={{ width: '50%' }} />
        <h1 style={{ color: 'lightgray' }}>Create Account</h1>
        <fieldset>
          <Input
            onChange={handleFieldChange}
            type="text"
            id="first_name"
            placeholder="First Name"
            value={credentials.first_name}
          />
        </fieldset>
        <fieldset>
          <Input
            onChange={handleFieldChange}
            type="text"
            id="last_name"
            placeholder="Last Name"
            value={credentials.last_name}
          />
        </fieldset>
        <fieldset>
          <Input
            onChange={handleFieldChange}
            type="text"
            id="phone_number"
            placeholder="Phone Number"
            value={credentials.phone_number}
          />
        </fieldset>
        <fieldset>
          <Input
            onChange={handleFieldChange}
            type="email"
            id="email"
            placeholder="Email"
            value={credentials.email}
          />
        </fieldset>
        <fieldset>
          <Input
            onChange={handleFieldChange}
            type="password"
            id="password"
            placeholder="Password"
            value={credentials.password}
          />
        </fieldset>
        <fieldset>
          <Input
            onChange={handleFieldChange}
            type="password"
            id="passwordVerify"
            placeholder="Repeat Password"
            value={credentials.passwordVerify}
          />
        </fieldset>
        {/* <p className="referred-by-text">Upload Profile Picture</p>
        <fieldset>
          <Input
            onChange={handleFileUpload}
            type="file"
            id="image_path"
            required
          />
        </fieldset> */}
        <fieldset>
          <p className="referred-by-text">
            Did someone refer you to G2G? <br />
            Tell us who so we can thank them!
          </p>
          <TextArea
            onChange={handleFieldChange}
            type="text"
            id="referred_by"
            placeholder="Referred by..."
            value={credentials.referred_by}
            rows={1}
          />
        </fieldset>
        <fieldset>
          <Checkbox
            id="agreed_terms"
            onChange={handleFieldChange}
            checked={credentials.agreed_terms}
          >
            I have read the{' '}
            <a
              style={{ textDecoration: 'underline', color: '#178ffe' }}
              onClick={toggleTOS}
            >
              terms-of-service
            </a>{' '}
            ,{' '}
            <a href="/FAQs" target="_blank">
              G2G FAQs
            </a>{' '}
            and acknowledge the{' '}
            <a
              style={{ textDecoration: 'underline', color: '#178ffe' }}
              onClick={togglePP}
            >
              privacy policy
            </a>
            .
          </Checkbox>
        </fieldset>
        <fieldset>
          <Checkbox
            id="newsletter"
            onChange={handleFieldChange}
            checked={credentials.newsletter}
          >
            Yes, I’d love to get exclusive discounts and hear about all the
            awesome stuff Gear2Go has planned!
          </Checkbox>
        </fieldset>
        <Recaptcha
          sitekey="6LerR4AaAAAAAP69VAyWuxgBxpgDBMZW7C2-Kdj6"
          render="explicit"
          verifyCallback={verifyHuman}
          onloadCallback={recaptchaLoaded}
        />
        <fieldset>{buttonOrLoader()}</fieldset>
      </form>
      {TOSModal()}
      {PPModal()}
      <h3 style={{ color: 'white' }}>
        Already have an account? <a href="/login">Login</a>
      </h3>
    </section>
  );
};

export default Register;
