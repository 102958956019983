import React from 'react';
import { Button, Modal } from 'antd';
import { useState } from 'react';

const InstructionsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{ marginRight: '20px' }}
      >
        Instructions
      </Button>
      <Modal
        title="Rental Instructions"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="">
          <p>
            You're almost there! Message the lender to request to rent their
            gear. Include the below information to start your conversation:
          </p>
          <ol>
            <li>Name of the item you want to rent</li>
            <li>The dates of your requested rental</li>
            <li>Your preferred pick-up and drop-off times and locations</li>
            <li>
              Ask the lender's preferred form of payment (cash, Venmo, Cash app,
              etc.)
            </li>
            <li>Finally, confirm the rental price with the lender</li>
          </ol>
          <p>
            Payment: Pay for the duration of your rental when you pick up your
            item – just like you would for Facebook Marketplace or craigslist!
          </p>
          <p>Get your Gear and Get Going!</p>
        </div>
      </Modal>
    </>
  );
};
export default InstructionsModal;
