import React from 'react';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import './StarRating.css';
import { useMediaQuery } from 'react-responsive';

const StarRating = ({ rating, editable, style, setRating }) => {
  const stars = [];
  const mobile = useMediaQuery({ maxWidth: 600 });

  const changeRating = (event) => {
    const newRating = event.currentTarget.id.split('--')[1];
    if (editable) {
      setRating(Number(newRating) + 1);
    }
  };

  for (let i = 0; i < 5; i++) {
    const key = `star-rating--${i}`;
    if (i < rating) {
      stars.push(
        <span
          key={key}
          id={key}
          onClick={changeRating}
          className={editable && 'star-hover'}
        >
          <StarFilled style={style} className={!editable && 'star-noedit'} />
        </span>
      );
    } else {
      stars.push(
        <span
          key={key}
          id={key}
          onClick={changeRating}
          className={editable && 'star-hover'}
        >
          <StarOutlined style={style} className={!editable && 'star-noedit'} />
        </span>
      );
    }
  }
  return (
    <div name="rating" style={{ padding: mobile ? 0 : 8, minWidth: 90 }}>
      {stars}
    </div>
  );
};

export default StarRating;
