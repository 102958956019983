import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {};

const RequestGear = (props: Props) => {
  return (
    <div className="search-page-footer">
      <div style={{ flexFlow: 'column' }}>
        <div>Don't see what you want?</div>
        <Link to="/gear-requests">
          <Button variant="contained">Request gear</Button>
        </Link>
      </div>
    </div>
  );
};

export default RequestGear;
