import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SelectGear } from './SelectGear';
import ProductAPI from '../../modules/productManager';
import OrderAPI from '../../modules/orderManager';
import OrderProductAPI from '../../modules/orderProductManager';
import { Payment } from '../payments/Payment';
import g2gLogo from '../../images/G2G_light_green_transparent_background-01-01.png';
import PromoCodeAPI from '../../modules/promoCodeAPI';
import PaymentAPI from '../../modules/paymentAPI';
import EventAPI from '../../modules/eventAPI';

export const Checkout = () => {
  const { state: pageState } = useLocation();
  const history = useHistory();

  const [lender, setLender] = useState(() => {
    if (pageState) return pageState.lender;
  });
  const [lendersGear, setLendersGear] = useState(() => []);
  const [order, setOrder] = useState(() => {
    return {
      duration: 1,
      promoCode: {
        id: 0,
        discount: 0,
        type: '',
      },
    };
  });
  const [orderProducts, setOrderProducts] = useState(() => []);
  // paymentResponse is from gear2go api to setup the payment intent
  const [paymentResponse, setPaymentResponse] = useState(() => {});
  // stripePaymentResponse is response from stripe in confirming the payment
  const [stripePaymentResponse, setStripePaymentResponse] = useState();
  // checkoutStage is the current stage of the checkout process. selectGear > payment > paymentSuccess
  const [checkoutStage, setCheckoutStage] = useState(() => 'selectGear');
  const [confirmButtonClicked, setConfirmButtonClicked] = useState(() => false);
  const [promoCodeResponseMessage, setPromoCodeResponseMessage] = useState(
    () => ''
  );

  useEffect(() => {
    // cancel payment if the user tries to navigate away from the payment page
    if (checkoutStage == 'payment') {
      history.block(() => {
        if (!confirmButtonClicked) {
          const navigateAway = window.confirm(
            'Your order will be cancelled if you leave this page. Are you sure you want to leave?'
          );
          if (navigateAway == true) {
            cancelPayment();
            history.block(() => {});
          }
          return navigateAway;
        }
        return true;
      });
    } else {
      history.block(() => {});
    }
  }, [checkoutStage]);

  useEffect(() => {
    // remove naviation blocking if confirm button is clicked
    history.block(() => {});
  }, [confirmButtonClicked]);

  const getLendersGear = () => {
    if (pageState) {
      ProductAPI.getLenderGear(lender.id).then((response) => {
        setLendersGear(response);
      });
    }
  };

  const setupForPayment = async () => {
    // create order
    const orderRequest = {
      duration: order.duration,
      lender_id: lender.id,
    };
    const newOrder = await OrderAPI.createOrder(orderRequest).then(
      (response) => {
        setOrder({ ...order, id: response.id });
        return response;
      }
    );

    // create order_products
    const orderProductsRequest = {
      order_id: newOrder.id,
      products: [],
    };
    orderProducts.map((orderProduct) => {
      const productObject = {
        id: orderProduct.product.id,
        quantity: orderProduct.quantity,
      };
      orderProductsRequest.products.push(productObject);
    });
    OrderProductAPI.createOrderProducts(orderProductsRequest);

    // gear2go api request for setting up a new payment intent
    const newPaymentRequest = {
      initiate_payment: true,
      lender_id: lender.id,
      order_id: newOrder.id,
      channel_name: pageState.channelName,
      promo_code_id: order.promoCode.id,
    };

    PaymentAPI.setupPaymentIntent(newPaymentRequest).then((response) => {
      setPaymentResponse(response);
      setCheckoutStage('payment');
    });
  };

  function validatePromoCode(code) {
    setPromoCodeResponseMessage('');
    PromoCodeAPI.validate({ text: code }).then((response) => {
      if (!response.valid) {
        setPromoCodeResponseMessage('Invalid code');
      } else if (response.valid && response.redeemed) {
        setPromoCodeResponseMessage('Code previously redeemed');
      } else {
        setOrder({
          ...order,
          promoCode: {
            id: response.id,
            discount: response.discount,
            type: response.type,
          },
        });
      }
    });
  }

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const cancelPayment = () => {
    const requestBody = {
      payment_id: paymentResponse.payment.stripe_payment_id,
    };
    PaymentAPI.cancelPayment(requestBody);
  };

  const handleBackToGearSelect = () => {
    if (window.confirm('Are you sure you want to cancel this payment?')) {
      cancelPayment();
      setOrder({
        ...order,
        promoCode: {
          id: 0,
          discount: 0,
          type: '',
        },
      });
      setOrderProducts([]);
      setCheckoutStage('selectGear');
    }
  };

  const displayReachedPageInError = () => {
    return (
      <div className="checkout-reached-page-in-error">
        You have reached this page in error. Please message one of our lenders
        to rent some gear.
      </div>
    );
  };

  const displaySelectGear = () => {
    return (
      <SelectGear
        lendersGear={lendersGear}
        order={order}
        setOrder={setOrder}
        setupForPayment={setupForPayment}
        handleBackButtonClick={handleBackButtonClick}
        orderProducts={orderProducts}
        setOrderProducts={setOrderProducts}
        validatePromoCode={validatePromoCode}
        promoCodeResponseMessage={promoCodeResponseMessage}
      />
    );
  };

  const handlePaymentSuccess = (response) => {
    setStripePaymentResponse(response);
    setCheckoutStage('paymentSuccess');

    const requestBody = {
      payment_id: response.paymentIntent.id,
      promo_code_id: order.promoCode.id,
    };
    PaymentAPI.paymentSuccess(requestBody);

    // Set payment event, so we know to prompt the user to invite a friend next time they visit the rent page
    EventAPI.setEvent({ event_name: 'payment' });
  };

  const displayConfirmPayment = () => {
    return (
      <Payment
        secret={paymentResponse.secret}
        handlePaymentSuccess={handlePaymentSuccess}
        handleBackToGearSelect={handleBackToGearSelect}
        orderProducts={orderProducts}
        order={order}
        cancelPayment={cancelPayment}
        setConfirmButtonClicked={setConfirmButtonClicked}
        confirmButtonClicked={confirmButtonClicked}
        paymentResponse={paymentResponse}
      />
    );
  };

  const displayPaymentSuccess = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    return (
      <div className="payment-success-component">
        <div className="payment-success-image-container">
          <img className="payment-success-image" src={g2gLogo} />
        </div>
        <div className="payment-success-message">
          Your payment was processed! You will be emailed a receipt of your
          payment.
        </div>
      </div>
    );
  };

  const handleCheckoutDisplay = () => {
    // return displayPaymentSuccess();
    if (!pageState) return displayReachedPageInError();
    else if (checkoutStage == 'selectGear') return displaySelectGear();
    else if (checkoutStage == 'payment') return displayConfirmPayment();
    else if (checkoutStage == 'paymentSuccess') return displayPaymentSuccess();
  };

  useEffect(() => {
    getLendersGear();
  }, [lender]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [checkoutStage]);

  return <div className="checkout-component">{handleCheckoutDisplay()}</div>;
};
