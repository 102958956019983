import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const MemberManager = {
  getCustomer(member_id) {
    return fetch(`${baseUrl}/members/${member_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((resp) => resp.json());
  },
  updateMember(member_id, memberObject) {
    return fetch(`${baseUrl}/members/${member_id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: memberObject,
    }).then((resp) => resp.json());
  },
  deleteAccount(member_id) {
    return fetch(`${baseUrl}/members/${member_id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  },
};

export default MemberManager;
