import { getToken, LOCAL_STORAGE } from '../constants/localStorage';

const useLoggedInMemberInfo = () => {
  const token = getToken();
  const memberId = Number(localStorage.getItem(LOCAL_STORAGE.memberId));
  const userLocation = localStorage.getItem(LOCAL_STORAGE.location);

  return { memberId, isLoggedIn: Boolean(token), token, userLocation };
};

export default useLoggedInMemberInfo;
