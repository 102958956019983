import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const productAPI = {
  getProducts(pageNumber = 1, category = 'all', searchTerm = '') {
    // console.log(`${baseUrl}/products?page=${pageNumber}&category=${category}&searchTerm=${searchTerm}`)
    const region = localStorage.getItem('location');
    return fetch(
      `${baseUrl}/products?page=${pageNumber}&category=${category}&searchTerm=${searchTerm}&region=${region}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        return resp;
      });
  },
  getProductTypes() {
    return fetch(`${baseUrl}/product-types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((resp) => resp.json());
  },
  async addProductToCart(productId: number | string, product: any) {
    const resp = await fetch(`${baseUrl}/orders/${productId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ product }),
    });
    return await resp.json();
  },
  async getProductDetail(productId: number | string) {
    const resp = await fetch(`${baseUrl}/products/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        // Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return await resp.json();
  },
  // Taking in a form data object
  postSellableProduct(product: any) {
    return fetch(`${baseUrl}/lend`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: product,
    }).then((resp) => resp.json());
  },
  updateProduct(productId: number | string, updatedProduct: any) {
    return fetch(`${baseUrl}/products/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(updatedProduct),
    }).then((resp) => resp.json());
  },
  getProduct(productId: number) {
    return fetch(`${baseUrl}/products/${productId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json());
  },
  patchProduct(patchProductId: string | number, quant: string | number) {
    return fetch(`${baseUrl}/products/${patchProductId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ quantity: quant }),
    });
    // .then(resp => resp.json())
  },
  deleteGear(gearId: string | number) {
    return fetch(`${baseUrl}/products/${gearId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  },
  getLenderGear(lenderId: string | number) {
    return fetch(`${baseUrl}/lenderproducts/${lenderId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
  async getHomeGear() {
    const region = localStorage.getItem('location');
    return await fetch(`${baseUrl}/products?home_gear=true&region=${region}`, {
      headers: { Authorization: `Token ${localStorage.getItem('token')}` },
    }).then((resp) => resp.json());
  },
};

export default productAPI;
