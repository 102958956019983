import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const PaymentTypeManager = {
  getPaymentListByCustomer() {
    return fetch(`${baseUrl}/paymenttypes`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
  addNewPaymentType(newPaymentType) {
    return fetch(`${baseUrl}/paymenttypes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newPaymentType),
    }).then((resp) => resp.json());
  },
  deletePaymentType(id) {
    return fetch(`${baseUrl}/paymenttypes/${id}`, {
      method: 'DELETE',
    });
  },
};

export default PaymentTypeManager;
