import _ from 'lodash';
import USER_LOCATIONS from '../../../constants/locations';
import useLoggedInMemberInfo from '../../../hooks/useLoggedInMemberInfo';
import zipLists from '../../../modules/maps/mapData';

const ZIP_CODE = 'zipcode';

export const zipToNumber = (zip: string) => {
  return _.toNumber(zip);
};

export const saveZipToLocal = (zip: string): void => {
  localStorage.setItem(ZIP_CODE, zip);
};

export const getZipFromLocal = (): string => {
  return localStorage.getItem(ZIP_CODE) || '';
};

export const useValidateLocationFromZip = () => {
  const { userLocation } = useLoggedInMemberInfo();

  /** Check if a zipcode is within the allowed list
   * for that user location
   */
  const isValidZipCode = (zipcode: string | number): boolean => {
    switch (userLocation) {
      case USER_LOCATIONS.BayArea:
        return zipLists.bayAreaZips.includes(Number(zipcode));
      case USER_LOCATIONS.Nashville:
        return zipLists.nashvilleAreaZips.includes(Number(zipcode));
      case USER_LOCATIONS.VetAdventureGroup:
        return zipLists.vetAdventureGroupZips.includes(Number(zipcode));
      case USER_LOCATIONS.Stanford:
        return zipLists.stanfordZips.includes(Number(zipcode));
      default:
        return false;
    }
  };

  return { isValidZipCode };
};
