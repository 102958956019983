import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

export default {
  async getLenderReviews({ lenderId }) {
    const resp = await fetch(`${baseUrl}/lender-reviews?member=${lenderId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return await resp.json();
  },
  async getLenderReview({ reviewId }) {
    const resp = await fetch(`${baseUrl}/lender-reviews/${reviewId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return await resp.json();
  },
  async postLenderReview(review) {
    const resp = await fetch(`${baseUrl}/lender-reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(review),
    });
    return await resp.json();
  },
  async updateLenderReview({ reviewId, updatedReview }) {
    const resp = await fetch(`${baseUrl}/lender-reviews/${reviewId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(updatedReview),
    });
    return resp;
  },
  async deleteLenderReview({ productId, reviewId }) {
    const resp = await fetch(`${baseUrl}/lender-reviews/${reviewId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(productId),
    });
    return await resp;
  },
};
