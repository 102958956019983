import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const gearRequestManager = {
  getProductRequests(searchTerm = '', pageNumber = 1) {
    return fetch(
      `${baseUrl}/productrequest?searchTerm=${searchTerm}&pageNumber=${pageNumber}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    ).then((resp) => resp.json());
  },
  createProductRequest(request) {
    return fetch(`${baseUrl}/productrequest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(request),
    }).then((resp) => resp.json());
  },
  updateProductRequest(updatedRequest) {
    return fetch(`${baseUrl}/productrequest/${updatedRequest.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(updatedRequest),
    });
  },
  deleteProductRequest(requestId) {
    return fetch(`${baseUrl}/productrequest/${requestId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  },
};

export default gearRequestManager;
