import React, { useState, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Navbar from './components/navigation/Navbar';
import Footer from './components/navigation/Footer';
import BodyRouter from './components/BodyRouter';
import LocationPopUp from './components/locationSetting/LocationPopUp';
import './components/navigation/Navigation.css';
import './components/orders/Orders.css';
import './Gear2Go.css';
import useLoggedInMemberInfo from './hooks/useLoggedInMemberInfo';
// import Header from './components/navigation/Header.js';
import OneSignal from 'react-onesignal';

function Gear2GoApp() {
  const [searchTerm, changeSearchTerm] = useState('');
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [locationBoolean, setLocationBoolean] = useState(true);
  const history = useHistory();
  const { userLocation } = useLoggedInMemberInfo();
  const [onesignalInitialized, setOnesignalInitialized] = useState(false);

  // Location-selection relevant components are in:
  // Navbar, Products, Lend, and the Location Select Popup

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    }).then(() => {
      setOnesignalInitialized(true);
    });
  }, []);

  useEffect(() => {
    if (!userLocation || !locationBoolean) {
      setLocationModalVisible(true);
    }
  }, [locationBoolean]);

  return (
    <Route
      path="/"
      render={(routerProps) => {
        return (
          <div style={{ position: 'relative' }}>
            {locationBoolean ? null : (
              <LocationPopUp
                setLocationBoolean={setLocationBoolean}
                locationModalVisible={locationModalVisible}
                setLocationModalVisible={setLocationModalVisible}
              />
            )}
            <Navbar
              changeSearchTerm={changeSearchTerm}
              setLocationBoolean={setLocationBoolean}
              locationBoolean={locationBoolean}
              routerProps={routerProps}
            />
            <div id="mainBodyContainer">
              <BodyRouter
                setLocationBoolean={setLocationBoolean}
                searchTerm={searchTerm}
                locationBoolean={locationBoolean}
                changeSearchTerm={changeSearchTerm}
                onesignalInitialized={onesignalInitialized}
              />
            </div>
            <Footer />
          </div>
        );
      }}
    />
  );
}

export default Gear2GoApp;
