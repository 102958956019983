import React from 'react';
import { Layout } from 'antd';
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import './Footer.css';
import { linkConditional } from '../../modules/apiInfo';

const { Footer } = Layout;
const MainFooter = () => {
  return (
    <Footer id="footer" style={{ textAlign: 'center' }}>
      <div className="footer-mobile-links">
        Want Gear2Go on the go? Download our mobile app on the iOS{' '}
        <a href="https://apps.apple.com/us/app/gear2go/id1588246074">
          App Store
        </a>{' '}
        or{' '}
        <a href="https://play.google.com/store/apps/details?id=com.Gear2Go.android&hl=en_US&gl=US">
          Google Play
        </a>
      </div>
      <div className="footerLinks">
        <ul style={{ listStyleType: 'none' }}>
          <li>
            <a href={linkConditional() + '/instructions'}>Instructions</a>
          </li>
          <li>
            <a href={linkConditional() + '/faqs'}>FAQs</a>
          </li>
          <li>
            <a href={linkConditional() + '/tos'}>Terms Of Service</a>
          </li>
          <li>
            <a href={linkConditional() + '/privacy'}>Privacy Policy</a>
          </li>
          <li>
            <a href={linkConditional() + '/contact'}>Contact</a>
          </li>
        </ul>
      </div>
      <div className="footer-text">
        <p>
          Checkout our{' '}
          <a href="/safety" target="_blank">
            safety
          </a>{' '}
          page
          <br /> and help us identify bugs / function improvements using this{' '}
          <a
            target="_blank"
            href="https://berkeley.qualtrics.com/jfe/form/SV_cAywUp93ulAzxNs"
            rel="noreferrer"
          >
            survey
          </a>
        </p>
        <p>
          To learn more about us,
          <br /> visit{' '}
          <a href="https://about.gear2go.us" target="_blank" rel="noreferrer">
            about.gear2go.us
          </a>
          , check out our{' '}
          <a href="https://blog.gear2go.us" target="_blank" rel="noreferrer">
            {' '}
            blog
          </a>
          , or follow us on social media. <br />
        </p>
      </div>
      <div className="social-icons">
        <a
          href="https://www.facebook.com/gear2go1"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookOutlined
            className="footer-icon"
            style={{ fontSize: '16px' }}
          />
        </a>
        <a
          href="https://instagram.com/gear2go.us/"
          className="footer-icon"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramOutlined />
        </a>
        <a
          href="https://twitter.com/gear2go2"
          className="footer-icon"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterOutlined />
        </a>
      </div>
    </Footer>
  );
};

export default MainFooter;
