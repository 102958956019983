import { urlConditional } from '../apiInfo';

const baseUrl = urlConditional();

const memberBlocking = {
  blockMember(memberObj) {
    return fetch(`${baseUrl}/member-blocking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(memberObj),
    }).then((resp) => resp.json());
  },
  unblockMember(memberObj) {
    return fetch(`${baseUrl}/member-blocking`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(memberObj),
    }).then((resp) => resp.json());
  },
  getBlockedStatus(memberObj) {
    // Making the member's id first and then the blocked member's id after the plus.
    return fetch(
      `${baseUrl}/member-blocking/${memberObj.current_member}+${memberObj.other_member}`
    ).then((resp) => resp.json());
  },
};

export default memberBlocking;
