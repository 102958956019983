import React, { useState } from 'react';
import { Select } from 'antd';
import { CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, TextField } from '@mui/material';

const { Option } = Select;

export const SelectGear = ({
  lendersGear,
  order,
  setOrder,
  setupForPayment,
  handleBackButtonClick,
  orderProducts,
  setOrderProducts,
  validatePromoCode,
  promoCodeResponseMessage,
}) => {
  const [promoCodeInput, setPromoCodeInput] = useState('');
  const [displayDiscountMessage, setDisplayDiscountMessage] = useState(false);
  const setDurationOptions = () => {
    return Array.from({ length: 30 }, (_, i) => i + 1).map((num) => (
      <Option key={num} value={num}>
        {num}
      </Option>
    ));
  };

  const setQuantityOptions = () => {
    return Array.from({ length: 50 }, (_, i) => i + 1).map((num) => (
      <Option key={num} value={num}>
        {num}
      </Option>
    ));
  };

  const calculateSubtotal = () => {
    if (orderProducts.length > 0) {
      let subtotal = 0;
      orderProducts.map((orderProduct) => {
        subtotal +=
          parseInt(orderProduct.product.price) *
          orderProduct.quantity *
          order.duration;
      });
      return subtotal;
    }
    return 0;
  };

  const calculateFee = () => {
    return calculateSubtotal() * 0.15;
  };

  const calculateDiscount = () => {
    if (order.promoCode.type == 'Monetary')
      return parseFloat(order.promoCode.discount);
    else if (order.promoCode.type == 'Percentage')
      return (calculateSubtotal() + calculateFee()) * order.promoCode.discount;
    else return 0;
  };

  const calculateTotal = () => {
    const total = calculateSubtotal() + calculateFee();
    const subtotal = calculateSubtotal();
    const discount = calculateDiscount();
    const itemsAreSelectedForRental = subtotal > 0;
    const discountHasBeenRedeemed = order.promoCode.id > 0;
    const totalReducedPastMinimum = total - discount < 1;
    if (
      (itemsAreSelectedForRental || discountHasBeenRedeemed) &&
      totalReducedPastMinimum
    ) {
      if (displayDiscountMessage === false) setDisplayDiscountMessage(true);
      return 1;
    }
    if (displayDiscountMessage === true) setDisplayDiscountMessage(false);
    return total - discount;
  };

  const displayDiscount = () => {
    if (order.promoCode.type == 'Percentage')
      return ` (${order.promoCode.discount * 100}%)`;
    else return '';
  };

  const handleRemoveSelectedItem = (id) => {
    setOrderProducts(
      orderProducts.filter((orderProduct) => orderProduct.product.id !== id)
    );
  };

  const handleItemSelection = (selectedProduct) => {
    if (
      orderProducts.find(
        (orderProduct) => orderProduct.product.id == selectedProduct.id
      )
    ) {
      setOrderProducts(
        orderProducts.filter(
          (orderProduct) => orderProduct.product.id !== selectedProduct.id
        )
      );
    } else {
      const newOrderProduct = {
        quantity: 1,
        product: selectedProduct,
      };
      setOrderProducts([...orderProducts, newOrderProduct]);
    }
  };

  const handleItemCSS = (id) => {
    if (orderProducts.find((orderProduct) => orderProduct.product.id == id)) {
      return 'select-gear-item-list-item selected-item';
    } else {
      return 'select-gear-item-list-item';
    }
  };

  const handleQuantityChange = (productId, quantity) => {
    const quantityAsInt = parseInt(quantity);
    // avoid returning a NaN value
    const validatedQuantity = quantityAsInt >= 0 ? quantityAsInt : 0;
    const updatedOrderProducts = orderProducts.map((orderProduct) => {
      if (orderProduct.product.id == productId)
        orderProduct.quantity = validatedQuantity;
      return orderProduct;
    });
    setOrderProducts(updatedOrderProducts);
  };

  const displayItemList = () => {
    return (
      <div className="select-gear-item-list-component">
        <div className="select-gear-item-list-title">Item List</div>
        <hr className="select-gear-hr" />
        <div className="select-gear-item-list-items-container">
          {lendersGear.map((item, index) => {
            return (
              <div
                key={index}
                className={handleItemCSS(item.id)}
                onClick={() => handleItemSelection(item)}
              >
                <div className="select-gear-item-list-item-title">
                  {item.title}
                </div>
                <div>${item.price}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const displayOrderDetails = () => {
    return (
      <div className="select-gear-order-details-component">
        <div className="productDetails-backButton-container checkout-back-button-container">
          <ArrowLeftOutlined
            className="productDetails-backButton checkout-back-button"
            onClick={handleBackButtonClick}
          />
        </div>
        <div className="select-gear-order-details-title">Order Details</div>
        <hr className="select-gear-hr" />
        <div className="select-gear-order-details-items-container">
          {orderProducts.length == 0 ? (
            <div className="select-gear-order-details-item-placeholder">
              No items selected
            </div>
          ) : (
            orderProducts.map((orderProduct, index) => {
              return (
                <div key={index} className="select-gear-order-details-item">
                  <div className="select-gear-order-details-image-remove-quantity">
                    <div className="select-gear-order-details-image-and-remove">
                      <div className="select-gear-order-details-item-image-container">
                        <img
                          className="select-gear-order-details-item-image"
                          src={orderProduct.product.image_paths[0]}
                        ></img>
                      </div>
                      <div className="select-gear-order-details-item-remove">
                        <CloseOutlined
                          style={{ fontSize: '20px', color: 'gray' }}
                          onClick={() =>
                            handleRemoveSelectedItem(orderProduct.product.id)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <span className="select-gear-order-details-item-quantity-label">
                        Quantity
                      </span>
                      <Select
                        className="select-gear-order-details-item-quantity"
                        size="medium"
                        defaultValue={1}
                        placeholder="1"
                        onChange={(quantity) =>
                          handleQuantityChange(
                            orderProduct.product.id,
                            quantity
                          )
                        }
                      >
                        {setQuantityOptions()}
                      </Select>
                    </div>
                  </div>
                  <div className="select-gear-order-details-item-info">
                    <div className="select-gear-order-details-item-title">
                      {orderProduct.product.title}
                    </div>
                    <div>
                      ${orderProduct.product.price * orderProduct.quantity}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className="select-gear-order-details-duration-select-container">
          <span>Rental period</span>
          <Select
            disabled={orderProducts == []}
            className="select-gear-order-details-duration-select"
            defaultValue={1}
            placeholder="1"
            size="medium"
            onChange={(value) => setOrder({ ...order, duration: value })}
          >
            {setDurationOptions()}
          </Select>
          <span>day(s)</span>
        </div>
        <div className="select-gear-order-details-subtotal">
          <div>Subtotal:</div>
          <div>${calculateSubtotal().toFixed(2)}</div>
        </div>
        <div className="select-gear-order-details-fees">
          <div>Rental fee (15%):</div>
          <div>${calculateFee().toFixed(2)}</div>
        </div>
        <div
          className="select-gear-order-details-fees"
          hidden={order.promoCode.id == 0}
        >
          <div>Discount{displayDiscount()}:</div>
          <div>- ${calculateDiscount().toFixed(2)}</div>
        </div>
        <div className="select-gear-order-details-total">
          <div>Total:</div>
          <div>${calculateTotal().toFixed(2)}</div>
        </div>
        <div
          className="select-gear-minimum-transaction-message"
          hidden={!displayDiscountMessage}
        >
          The minimum transaction Stripe can process is $1, so we have to cap
          your discount at $1. Sorry for the inconvenience!
        </div>
        <div className="select-gear-verify-details-message">
          You are about to proceed to payment. Please verify that your order
          details are correct before continuing.
        </div>
        <div className="select-gear-promo-code-container">
          <TextField
            id="promo-code-input"
            label="Enter promo code"
            defaultValue=""
            variant="filled"
            helperText={promoCodeResponseMessage}
            disabled={order.promoCode.id > 0}
            onChange={(e) => setPromoCodeInput(e.target.value)}
          />
          <Button
            id="promo-code-submit-button"
            variant="contained"
            disabled={promoCodeInput == '' || order.promoCode.id > 0}
            onClick={() => validatePromoCode(promoCodeInput)}
          >
            Redeem
          </Button>
        </div>
        <div className="select-gear-button-container">
          <button
            className={`select-gear-button action`}
            onClick={setupForPayment}
            disabled={orderProducts == []}
          >
            Checkout
          </button>
          <button
            className="select-gear-button"
            onClick={handleBackButtonClick}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const displaySelectGear = () => {
    const isSlimView = window.innerWidth < 1000;
    if (isSlimView) {
      return (
        <>
          {displayOrderDetails()}
          {displayItemList()}
        </>
      );
    } else {
      return (
        <>
          {displayItemList()}
          {displayOrderDetails()}
        </>
      );
    }
  };

  return <div className="select-gear-component">{displaySelectGear()}</div>;
};
