import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import RenterReviewManager from '../../../modules/RenterReviewManager';
import useSearchParams from '../../../hooks/useSearchParams';
import StarRating from '../../StarRating/StarRating';
import useScrollToTop from '../../../hooks/useScrollToTop';
import MemberQuickInfo from './MemberQuickInfo';
import useRouteOtherMember from '../../../helpers/routeOtherMember';

const EditRenterReview = () => {
  useScrollToTop();
  const history = useHistory();
  const { id: reviewId } = useSearchParams();
  const [renterDetails, setRenterDetails] = React.useState();
  const [renterId, setRenterId] = React.useState();
  const [rating, setRating] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const getReviewDetails = async () => {
    const resp = await RenterReviewManager.getRenterReview({ reviewId });
    const {
      renter,
      renter_id,
      rating,
      title,
      description: prevDescription,
    } = resp || {};
    setRenterDetails(renter);
    setRenterId(renter_id);
    setRating(rating);
    setTitle(title);
    setDescription(prevDescription);
  };

  React.useEffect(() => {
    getReviewDetails();
  }, [reviewId]);

  const handleRatingChange = (newRating) => {
    setError(false);
    setRating(newRating);
  };

  const { routeOtherMember } = useRouteOtherMember();
  const historyPushMemberPage = () => {
    routeOtherMember(renterId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!rating) {
      setError(true);
    }
    setLoading(true);
    const updatedReview = {
      renter_id: renterId,
      rating,
      title,
      description,
    };
    await RenterReviewManager.updateRenterReview({ reviewId, updatedReview });
    setLoading(false);
    historyPushMemberPage();
  };

  const goToMemberPage = () => {
    if (window.confirm('You have unsaved changes. Proceed?')) {
      historyPushMemberPage();
    }
  };

  const handleDeleteReview = async () => {
    if (window.confirm('Are you sure you want to remove this review?')) {
      await RenterReviewManager.deleteRenterReview({
        reviewId,
      });
      historyPushMemberPage();
    }
  };

  if (!renterDetails) {
    return <div />;
  }

  return (
    <div className="center">
      <div className="review-header">Edit Review</div>
      <MemberQuickInfo member={renterDetails} handleOnClick={goToMemberPage} />
      <div style={{ marginTop: 16 }}>
        <form onSubmit={handleSubmit}>
          <div className="header">Rating</div>
          {error && (
            <div
              style={{ border: '2px solid red', borderRadius: 4, padding: 8 }}
            >
              Please rate this user
            </div>
          )}
          <StarRating
            editable
            style={{ fontSize: 36 }}
            rating={rating}
            setRating={handleRatingChange}
          />
          <div className="flex-column">
            <label htmlFor="title" className="header">
              Title
            </label>
            <input
              type="text"
              name="title"
              className="input-text"
              value={title}
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </div>
          <div className="flex-column">
            <label htmlFor="description" className="header">
              Description
            </label>
            <textarea
              name="description"
              className="input-textarea"
              rows={5}
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                color: error ? 'grey' : 'black',
                backgroundColor: error ? 'lightgrey' : 'lightgreen',
                borderColor: error ? 'grey' : 'black',
                marginTop: 8,
                height: isMobile ? 48 : undefined,
                // float: isMobile ? 'none' : 'right',
              }}
              disabled={loading || error}
              loading={loading}
              block={isMobile}
            >
              SUBMIT
            </Button>
          </div>
        </form>
      </div>
      <div
        style={{
          borderTop: '1px solid grey',
          marginTop: 16,
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <span>OR</span>
        <Button
          style={{
            color: 'black',
            backgroundColor: 'tomato',
            marginTop: 16,
            height: 40,
            fontWeight: 400,
            borderColor: 'black',
          }}
          onClick={handleDeleteReview}
          block
          icon={<DeleteOutlined />}
        >
          DELETE REVIEW
        </Button>
      </div>
    </div>
  );
};

export default EditRenterReview;
