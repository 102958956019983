import React from 'react';
import bikes from '../../images/icons/bikes.png';
import climbing from '../../images/icons/climbing.png';
import electronics from '../../images/icons/electronics.png';
import games from '../../images/icons/games.png';
import garden from '../../images/icons/gardening.jpg';
import other from '../../images/icons/ICON_other-01.png';
import outdoors from '../../images/icons/hiking.png';
import sports from '../../images/icons/sports.png';
import tools from '../../images/icons/tools.jpg';
import weight from '../../images/icons/weights.jpg';
import waterSports from '../../images/icons/water-sports.png';
import winterSports from '../../images/icons/winter-sports.png';
import { useHistory } from 'react-router-dom';

export default function HomeCategories() {
  const history = useHistory();
  function categoryClick(e) {
    history.push('/rent', e.target.id);
  }

  return (
    <div className="search-icons-container-parent">
      <div className="search-icons-container">
        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img
              id="Bikes%2C%20Boards%20and%20Scooters"
              src={bikes}
              className="category-icon"
            />
          </div>
          <p id="icon-text">Bikes, Boards and Scooters</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img
              id="Camping%20%26%20Hiking"
              src={outdoors}
              className="outdoors-icon category-icon"
            />
          </div>
          <p id="icon-text">Camping & Hiking</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img id="Climbing" src={climbing} className="category-icon" />
          </div>
          <p id="icon-text">Climbing</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img id="Electronics" src={electronics} className="category-icon" />
          </div>
          <p id="icon-text">Electronics</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img
              id="Exercise%20Equipment"
              src={weight}
              className="category-icon"
            />
          </div>
          <p id="icon-text">Exercise Equipment</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img
              id="Games%20%26%20Toys"
              src={games}
              className="category-icon"
            />
          </div>
          <p id="icon-text">Games & Toys</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img
              id="Home%20%26%20Garden"
              src={garden}
              className="category-icon"
            />
          </div>
          <p id="icon-text">Home & Garden</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img id="Other" src={other} className="category-icon" />
          </div>
          <p id="icon-text">Other</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img id="Sporting%20Goods" src={sports} className="category-icon" />
          </div>
          <p id="icon-text">Sporting Goods</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img id="Tools" src={tools} className="category-icon" />
          </div>
          <p id="icon-text">Tools</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img
              id="Water%20Sports"
              src={waterSports}
              className="category-icon"
            />
          </div>
          <p id="icon-text">Water Sports</p>
        </div>

        <div className="img-text-container" onClick={categoryClick}>
          <div className="icon-img-container">
            <img
              id="Winter%20Sports"
              src={winterSports}
              className="category-icon"
            />
          </div>
          <p id="icon-text">Winter Sports</p>
        </div>
      </div>
    </div>
  );
}
