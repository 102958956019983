import React, { useEffect } from 'react';
import HomeGearSection from './HomeGearSection';
import { Spin } from 'antd';

export default function HomeGear({ homeGear }) {
  function createGearSections() {
    if (homeGear !== null && homeGear !== undefined) {
      return (
        <>
          <HomeGearSection
            gearSection={homeGear['viewed']}
            title="Most Viewed Gear"
          />
          <HomeGearSection
            gearSection={homeGear['recommended']}
            title="Gav's Gear Recs"
          />
          <HomeGearSection
            gearSection={homeGear['recent']}
            title="Recent Gear"
          />
        </>
      );
    } else if (homeGear === null) {
      return (
        <h1 style={{ marginLeft: '15%', marginTop: '100px' }}>
          Finding Gear <Spin />
        </h1>
      );
    }
  }

  useEffect(() => {
    createGearSections();
  }, [homeGear]);

  return createGearSections();
}
