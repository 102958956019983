import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import productAPI from '../../modules/productManager';
import CategorySidebar from './CategorySidebar';
import ProductCard from './ProductCard';
import { Pagination, Collapse } from 'antd';
import './Buy.css';
import GearLoader from '../GearLoader';
import { useFormattedLocation } from '../../constants/locations';
import RequestGear from './RequestGear';
import EventAPI from '../../modules/eventAPI';
import { ReferAFriend } from '../prompts/ReferAFriend';

const ProductMain = ({
  searchTerm,
  changeSearchTerm,
  locationBoolean,
  setLocationBoolean,
  routerProps,
}) => {
  const [selectedCategory, changeCategory] = useState(
    parseURICategory(routerProps.location.search)
  );
  const [products, setAllProducts] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [productAmountTotal, setProductAmountTotal] = useState(1);
  const [homeCategorySearch, setHomeCategorySearch] = useState(null);
  const [displayReferAFriend, setDisplayReferAFriend] = useState(false);
  const history = useHistory();
  const userLocation = useFormattedLocation();

  const { Panel } = Collapse;

  function parseURICategory(string) {
    const params = string.split('&');
    let result = 'all';
    params.forEach((param) => {
      if (param.includes('category') && !param.includes('all')) {
        const category = param.split('=')[1];
        let decodedCategory = decodeURI(category);
        if (decodedCategory.includes('%26'))
          decodedCategory = decodedCategory.replace(/%26/g, '&');
        const decodedCategorySplit = decodedCategory.split(' ');
        const capitalizedCategoryString = decodedCategorySplit
          .map((word) => {
            return word[0].toUpperCase() + word.substring(1);
          })
          .join(' ');
        result = capitalizedCategoryString;
      }
    });
    return result;
  }

  function getNextProducts(
    pageNumberParameter,
    category = encodeURIComponent(selectedCategory),
    search = searchTerm
  ) {
    setIsLoading(true);
    return productAPI
      .getProducts(pageNumberParameter, category, search)
      .then((resp) => {
        setAllProducts(resp['results']);
        setProductAmountTotal(resp['count']);
        setIsLoading(false);
        return setPageNumber[pageNumberParameter];
      })
      .then(window.scroll(0, 0));
  }

  function productFilterUltraFunction() {
    const loweredSearchTerm = searchTerm ? searchTerm.toLowerCase() : null;
    if (
      selectedCategory === 'all' &&
      (searchTerm == null || searchTerm == '')
    ) {
      return products;
    } else if (selectedCategory === 'all' && searchTerm !== null) {
      const filtered = products.filter((product) => {
        if (
          product.title.toLowerCase().includes(loweredSearchTerm) ||
          product.price.includes(loweredSearchTerm) ||
          product.description.toLowerCase().includes(loweredSearchTerm) ||
          product.zipcode.toLowerCase().includes(loweredSearchTerm)
        ) {
          return true;
        }
      });
      return filtered;
    } else if (selectedCategory !== 'all' && searchTerm !== null) {
      const filtered = products.filter((product) => {
        if (
          (product.title.toLowerCase().includes(loweredSearchTerm) ||
            product.price.includes(loweredSearchTerm) ||
            product.description.toLowerCase().includes(loweredSearchTerm) ||
            product.zipcode.toLowerCase().includes(loweredSearchTerm)) &&
          product.product_type.name === selectedCategory
        ) {
          return true;
        }
      });
      return filtered;
    } else if (selectedCategory !== 'all' && searchTerm === null) {
      const filtered = products.filter(
        (productObj) => productObj.product_type.name === selectedCategory
      );
      return filtered;
    }
  }

  function locationFilter() {
    if (locationBoolean) {
      const filteredProducts = productFilterUltraFunction();
      return filteredProducts.filter((prod) => prod.location !== 'none');
    } else {
      return productFilterUltraFunction();
    }
  }

  function createProductCards() {
    if (products && !isLoading) {
      const filteredProducts = products;

      if (filteredProducts.length === 0) {
        return (
          <>
            <h1 style={{ margin: '60px auto 30px auto', padding: '30px' }}>
              No search results on this page, try another search
            </h1>
          </>
        );
      } else {
        return (
          <>
            {filteredProducts.map((productObj, i) => (
              <ProductCard
                key={i}
                productObj={productObj}
                routerProps={routerProps}
                isGearBag={false}
              />
            ))}
          </>
        );
      }
    } else if (!products || isLoading) {
      return <GearLoader />;
    }
  }

  function searchedTermConditional() {
    if (searchTerm) {
      return (
        <p className="searchTerm">
          Searching for: {searchTerm}{' '}
          <button onClick={() => changeSearchTerm('')}>X</button>
        </p>
      );
    }
  }

  function changeLocation() {
    setLocationBoolean(false);
  }

  const updateURLQueryParams = (category, search) => {
    if (search)
      history.push(`?category=${category.toLowerCase()}&search=${search}`);
    else history.push(`?category=${category.toLowerCase()}`);
  };

  function paginator() {
    if (products) {
      return (
        <Pagination
          className="search-page-footer"
          defaultPageSize={20}
          total={productAmountTotal}
          onChange={(pn) => getNextProducts(pn)}
          showQuickJumper
          responsive={false}
          showSizeChanger={false}
          hideOnSinglePage
        />
      );
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (locationBoolean) {
      setAllProducts(null);
      if (history.location.state && !homeCategorySearch) {
        const convertedCategory = history.location.state
          .split('%20')
          .join(' ')
          .split('%26')
          .join('&');
        changeCategory(convertedCategory);
        getNextProducts(1, history.location.state);
        setHomeCategorySearch(true);
      } else {
        const convertedSearchTerm = searchTerm
          .toLowerCase()
          .split(' ')
          .join('+');
        const convertedCategory = selectedCategory
          .split(' ')
          .join('%20')
          .split('&')
          .join('%26');
        setPageNumber(1);
        getNextProducts(1, convertedCategory, convertedSearchTerm);
        updateURLQueryParams(convertedCategory, convertedSearchTerm);
      }
    }
  }, [selectedCategory, searchTerm, locationBoolean]);

  function closeReferAFriend() {
    setDisplayReferAFriend(false);
  }

  function checkForEvents() {
    // Check if the user signed up or made a payment recently and display refer a friend modal if so - chasefite 2/19/23
    const request = {
      event_names: ['sign-up', 'payment'],
    };
    EventAPI.checkForAndClearEvent(request).then((response) => {
      if (response.isSuccessful) setDisplayReferAFriend(true);
    });
  }

  useEffect(() => {
    checkForEvents();
  }, []);

  return (
    <>
      <div id="rentPageContainer">
        <CategorySidebar
          changeCategory={changeCategory}
          changeSearchTerm={changeSearchTerm}
          selectedCategory={selectedCategory}
        />

        <div className="productContainer">
          <div className="info-tag-container">
            <p className="region-card" onClick={changeLocation}>
              Region: {userLocation}
            </p>
            {searchedTermConditional()}
          </div>
          {createProductCards()}
        </div>
        {paginator()}
        <RequestGear />
        <ReferAFriend
          display={displayReferAFriend}
          handleClose={closeReferAFriend}
        />

        <div className="rent-page-faqs-container">
          <div className="rent-page-faqs">
            <h1>Renter FAQs</h1>
            <Collapse>
              <Panel
                id="question"
                header="What if the equipment borrowed does not work / meet my expectations?"
              >
                <div className="answer">
                  <p>
                    Gear2Go will guarantee you a full-refund of the money you
                    spent on the rental. A Gear2Go teammate will also personally
                    coordinate an alternative rental to compensate you for your
                    trouble. Bottom line—we’ve got you covered!
                  </p>
                </div>
              </Panel>

              <Panel
                id="question"
                header="What if I get injured using the equipment?"
              >
                <div className="answer">
                  <p>
                    By participating on the platform and using the equipment,
                    you’re agreeing to assume the personal risk of undertaking
                    whatever activity may result in injury. Please check the
                    equipment prior to use and have the requisite skills to
                    perform your activities safely! On that note, our community
                    of lenders generally love to share their knowledge; if you
                    have questions just ask them to show you how to use the gear
                    properly!
                  </p>
                </div>
              </Panel>
              <Panel
                id="question"
                header="What if I break the lender’s equipment?"
              >
                <div className="answer">
                  <p>
                    If the item breaks while you have it, please proactively
                    contact the Gear2Go team. Our team will review situations on
                    a case-by-case basis to determine liability and payment. If
                    we determine you broke the item due to negligence, we will
                    request you pay the replacement amount. Our community is
                    based on trust; please let us know if you break something!
                  </p>
                  <p>
                    If the item broke due to normal wear and tear or was broke
                    prior to rental, don't worry, you won't be held responsible
                    for repairs.
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductMain;
