import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Spin } from 'antd';
import sendLenderEmail from '../../modules/messagingModules/sendLenderEmail';
import Recaptcha from 'react-recaptcha';

const { TextArea } = Input;

function EmailLenderPopup({ toggleEmailModal, isEmailModalVisible, gearInfo }) {
  const [emailToLender, setEmailToLender] = useState({
    lender_id: gearInfo.member_id,
    product_id: gearInfo.id,
    message: '',
  });
  const [sendClicked, setSendClicked] = useState(false);
  const [isVerifiedNotRobot, setVerifiedNotRobot] = useState(false);

  const handleFieldChange = (evt) => {
    const stateToChange = { ...emailToLender };
    stateToChange[evt.target.id] = evt.target.value;
    setEmailToLender(stateToChange);
  };

  const handleOk = () => {
    setSendClicked(true);
    if (!isVerifiedNotRobot) {
      window.alert('Please verify that you are human before sending.');
      setSendClicked(false);
    } else if (emailToLender.message.split(' ').join('').length > 12) {
      const formData = new FormData();
      formData.append('lender_id', emailToLender.lender_id);
      formData.append('product_id', emailToLender.product_id);
      formData.append('message', emailToLender.message);
      formData.append('verify_token', isVerifiedNotRobot);
      sendLenderEmail(formData).then((resp) => {
        console.log(resp);
        toggleEmailModal();
        setEmailToLender({ ...emailToLender, message: '' });
        setTimeout(function () {
          setSendClicked(false);
        }, 500);
      });
    } else {
      window.alert('Please enter a valid message to this lender.');
      setSendClicked(false);
    }
  };

  function buttonOrLoading() {
    return sendClicked ? (
      <Spin style={{ marginLeft: '8px' }} />
    ) : (
      <Button key="submit" type="primary" onClick={handleOk}>
        Send
      </Button>
    );
  }

  const handleCancel = () => {
    toggleEmailModal();
  };

  function verifyHuman(response) {
    if (response) {
      setVerifiedNotRobot(response);
    }
  }

  var recaptchaLoaded = function () {
    console.log('Recaptha loaded');
  };

  useEffect(() => {
    setEmailToLender({
      ...emailToLender,
      lender_id: gearInfo.member_id,
      product_id: gearInfo.id,
    });
  }, [gearInfo]);

  return (
    <>
      <Modal
        title={`Email ${gearInfo.member.user.first_name} about ${gearInfo.title}`}
        visible={isEmailModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          buttonOrLoading(),
        ]}
      >
        <form>
          <fieldset>
            <TextArea
              onChange={handleFieldChange}
              rows={4}
              id="message"
              placeholder='"Is this item available this weekend?"'
              value={emailToLender.message}
              required
            />
          </fieldset>
        </form>
        <p style={{ marginTop: '15px' }}>
          *Your email will be provided to the lender to follow up with your
          contact.
        </p>
        <Recaptcha
          sitekey="6LerR4AaAAAAAP69VAyWuxgBxpgDBMZW7C2-Kdj6"
          render="explicit"
          verifyCallback={verifyHuman}
          onloadCallback={recaptchaLoaded}
        />
      </Modal>
    </>
  );
}

export default EmailLenderPopup;
