import React, { useState } from 'react';
import { Modal } from 'antd';
import { urlConditional } from '../../modules/apiInfo';
import ProductAPI from '../../modules/productManager';
import './Payments.css';

const ReserveGearButton = ({
  currentMember,
  otherMember,
  channelName,
  routerProps,
}) => {
  const [noAccountModalVis, setNoAccountModalVis] = useState(false);

  const checkIfOtherMemberHasGearForRent = () => {
    ProductAPI.getLenderGear(otherMember.id).then((response) => {
      setOtherMemberHasGearForRent(response.some((x) => x));
    });
  };

  const [otherMemberHasGearForRent, setOtherMemberHasGearForRent] = useState(
    () => checkIfOtherMemberHasGearForRent()
  );

  function sendLenderSignupEmail() {
    const orderCopy = {
      initiate_payment: false,
      lender_id: otherMember.id,
    };
    if (
      window.confirm(
        `Send ${otherMember.user.first_name} an email letting them know you want to rent something from them?`
      )
    ) {
      setNoAccountModalVis(false);
      fetch(`${urlConditional()}/payments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(orderCopy),
      }).then((resp) => resp.json());
    }
  }

  const redirectToCheckout = () => {
    routerProps.history.push({
      pathname: '/checkout',
      state: {
        lender: otherMember,
        renter: currentMember,
        channelName: channelName,
      },
    });
  };

  const noStripeAccount = !otherMember.stripe_account_authorized;
  const buttonAction =
    noStripeAccount || !otherMemberHasGearForRent
      ? () => setNoAccountModalVis(true)
      : () => redirectToCheckout();
  const modalMessage = noStripeAccount
    ? `${otherMember.user.first_name} has not signed up through stripe yet.
            Click the button below, and we will let them know that you are
            interested in renting something from them.`
    : `${otherMember.user.first_name} does not currently have any gear for
            rent.`;

  return (
    <>
      <button
        onClick={buttonAction}
        style={{
          width: 140,
          backgroundColor: 'rgb(120, 202, 99)',
          border: '0.5px solid #444',
          fontWeight: 500,
          fontSize: 14,
          borderRadius: 2,
          height: 32,
          margin: '0 8px',
        }}
      >
        <span style={{ paddingLeft: 4 }}>Reserve Gear</span>
      </button>
      <Modal
        className="payment-modal inactive-other"
        mask={true}
        title="Cannot Rent Gear"
        footer={false}
        visible={noAccountModalVis}
        onCancel={() => setNoAccountModalVis(false)}
      >
        <h3>{modalMessage}</h3>
        {noStripeAccount && (
          <button onClick={sendLenderSignupEmail}>Send Notification</button>
        )}
      </Modal>
    </>
  );
};

export default ReserveGearButton;
