import React from 'react';
import g2gLogo from '../../images/G2G_black_transparent_background.png';
import './GearLoader.css';

const GearLoader = () => {
  return (
    <div className="gear-loader-container">
      <div className="gear-loader">
        <img src={g2gLogo} />
      </div>
    </div>
  );
};

export default GearLoader;
