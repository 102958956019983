import React, { useState, useEffect } from 'react';
import './HomePage.css';
import HomePageSearchBar from './HomePageSearchBar';
import coolTent from '../../images/landingImages/cool-tent.jpg';
import tentDesert from '../../images/landingImages/tent-desert-cropped.jpg';
import tentsMaxPatch from '../../images/landingImages/tents-max-patch.jpg';
import skis1 from '../../images/landingImages/skis1.jpg';
import biking1 from '../../images/landingImages/biking1.jpg';
import mountainMan from '../../images/landingImages/mountain-man-cropped.jpg';
import HomeGear from './HomeGear';
import RegisterButton from './RegisterButton';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import OneSignal from 'react-onesignal';
import productAPI from '../../modules/productManager.ts';
import HomeCategories from './HomeCategories';

const HomePage = ({
  routerProps,
  changeSearchTerm,
  setLocationBoolean,
  locationBoolean,
  onesignalInitialized,
}) => {
  const { userLocation } = useLoggedInMemberInfo();
  const [homeGear, setHomeGear] = useState(null);
  // const [registerOrLogin, setRegisterOrLogin] = useState(true);

  function randomHomeImage() {
    const imagePaths = [
      coolTent,
      tentDesert,
      tentsMaxPatch,
      biking1,
      skis1,
      mountainMan,
    ];

    const randomIndex = Math.floor(Math.random() * imagePaths.length);

    return imagePaths[randomIndex];
    // return tentDesert;
  }

  {
    /* ---ORIGINAL article conditional before edit---for reference and posterity
    <article id='homeContainer' style={{ backgroundImage:`url(${randomHomeImage()})` }}>
                    {!memberId ? <HomeLogin /> : <div className='logged-in-box home-message'><HomePageSearchBar /><div className='logo-container'><img className='main-logo' src={G2GLogo}/><h1>Share what you love.</h1></div></div>}
                    <DownArrow />
                </article> */
  }

  useEffect(() => {
    if (onesignalInitialized) {
      OneSignal.showSlidedownPrompt();
      const memberId = localStorage.getItem('memberId');
      if (memberId)
        OneSignal.setExternalUserId(memberId).then((response) => {
          console.log('Here is member Id: ', memberId);
          // remove console log once we're confident in onesignal - chase fite 3/1/23
          console.log('setExternalUserId response: ', response);
        });
    }
  }, [onesignalInitialized]);

  async function getHomeGear() {
    const homeGearResp = await productAPI.getHomeGear();
    setHomeGear(homeGearResp);
  }

  useEffect(() => {
    if (!userLocation) {
      setLocationBoolean(false);
    }
  }, [setLocationBoolean, userLocation]);

  useEffect(() => {
    getHomeGear();
  }, []);

  return (
    <div>
      <section>
        <article
          id="homeContainer"
          style={{
            backgroundImage: `url(${randomHomeImage()})`,
          }}
        >
          <div className="flexColumn">
            <HomePageSearchBar
              changeSearchTerm={changeSearchTerm}
              setLocationBoolean={setLocationBoolean}
              locationBoolean={locationBoolean}
              routerProps={routerProps}
            />
            <RegisterButton />
          </div>
        </article>
      </section>
      <div style={{ paddingTop: '20px' }}>
        {userLocation && homeGear ? <HomeGear homeGear={homeGear} /> : null}
      </div>
      <HomeCategories />
    </div>
  );
};

export default HomePage;
