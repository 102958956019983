import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

export default {
  async getRenterReviews({ renterId }) {
    const resp = await fetch(`${baseUrl}/renter-reviews?member=${renterId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return await resp.json();
  },
  async getRenterReview({ reviewId }) {
    const resp = await fetch(`${baseUrl}/renter-reviews/${reviewId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return await resp.json();
  },
  async postRenterReview(review) {
    const resp = await fetch(`${baseUrl}/renter-reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(review),
    });
    return await resp.json();
  },
  async updateRenterReview({ reviewId, updatedReview }) {
    const resp = await fetch(`${baseUrl}/renter-reviews/${reviewId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(updatedReview),
    });
    return resp;
  },
  async deleteRenterReview({ reviewId }) {
    const resp = await fetch(`${baseUrl}/renter-reviews/${reviewId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return await resp;
  },
};
