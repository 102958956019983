import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Spin, message } from 'antd';
import {
  ArrowLeftOutlined,
  EditOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import productManager from '../../modules/productManager';
import orderManager from '../../modules/orderManager';
import orderProductManager from '../../modules/orderProductManager';
import DetailsMap from '../mapComponents/DetailsMap';
import EditProductModal from './EditProductModal';
import ProfileImage from '../userProfile/ProfileImage';
import ProductReviews from './ProductReviews';
import StarRatingAvg from '../StarRatingAvg';
import filterWords from '../../modules/filters/bad-words';
import './ProductDetails.css';
import '../StarRating/StarRating.css';
import snapToTopOfPage from '../../helpers/snapToTopOfPage';
import { ProductDetailImages } from '../productImage/ProductDetailImages';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import PricePerDuration from '../PricePerDuration';
import { QuickMessage } from '../messages/QuickMessageMui';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import useRouteOtherMember from '../../helpers/routeOtherMember';
import { Button } from '@mui/material';
import SignUpLoginButtonCombo from './SignUpLoginButtonCombo';

const ProductDetail = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [productDetails, setProductDetails] = useState({});
  const [orders, setOrders] = useState([]);
  const [memberGearBag, setMemberGearBag] = useState(null);
  const [duplicateGearBag, setDuplicateGearBag] = useState(null);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isQuickMessageVisible, setIsQuickMessageVisible] = useState(false);

  const avgRating = productDetails.avg_rating;
  const totalRatings = productDetails.count_ratings;
  const { memberId, isLoggedIn, token } = useLoggedInMemberInfo();

  function goToMessages() {
    if (!isLoggedIn) {
      window.alert('Please log in or create an account to email lender');
    } else {
      // setIsEmailModalVisible(!isEmailModalVisible)
      routerProps.history.push(`/messages?direct=${true}`, {
        memberId: memberId,
        lenderId: productDetails.member_id,
        product_id: productDetails.id,
        messagePrompt: true,
      });
      snapToTopOfPage();
    }
  }

  const productId = props.productId;
  const routerProps = props.routerProps;

  const getOrders = () => {
    if (memberId) {
      orderManager.getUserOrders().then((orders) => {
        setOrders(orders);
      });
    }
  };
  const getMemberGearBagFunction = () => {
    if (memberId) {
      orderProductManager.getMemberGearBag(memberId).then((resp) => {
        setMemberGearBag(resp);
      });
    }
  };
  function filterGearBag() {
    if (memberGearBag != null) {
      const filteredBag = memberGearBag.filter(
        (gear) => gear.product_id == productId
      );
      return filteredBag.length > 0
        ? setDuplicateGearBag(filteredBag)
        : setDuplicateGearBag(false);
    }
  }

  const addToCart = () => {
    // filter out just the single open order from all the users orders
    const openOrder = orders.filter((order) => order.payment_type === null);

    // if there is already an open order, add the product to that order
    if (memberId == productDetails.member_id) {
      window.alert(
        'This is your own product! Other folks have also posted awesome gear, check some of theirs out!'
      );
    } else if (duplicateGearBag != null && duplicateGearBag.length > 0) {
      window.alert('This is already in your gear bag! Try adding other gear.');
    } else if (openOrder.length === 1) {
      const newOrderProduct = {
        order_id: openOrder[0].id,
        product_id: productDetails.id,
      };

      orderProductManager.createOrderProduct(newOrderProduct).then(() => {
        setDuplicateGearBag([1]);
        if (
          window.confirm(
            `${filterWords.cleanHacked(
              productDetails.title
            )} was added to your gear bag`
          )
        ) {
          window.location.reload();
        }
      });
    } else {
      //if there is not already an open order, open a new order and then add the product to that new order
      const newOrderProduct = {
        product_id: productDetails.id,
      };

      orderManager.createOrder().then((orderData) => {
        newOrderProduct.order_id = orderData.id;
        orderProductManager
          .createOrderProduct(newOrderProduct)
          .then(setOrders([orderData]))
          .then(() => {
            setDuplicateGearBag([1]);
            if (
              window.confirm(
                `${productDetails.title} was added to your gear bag`
              )
            ) {
              window.location.reload();
            }
          });
      });
    }
  };

  const dateFormatter = (dateString) => {
    return dateString.split('T')[0];
  };

  const deleteGear = () => {
    if (
      window.confirm(
        `${productDetails.title} will be deleted from your postings`
      )
    ) {
      productManager.deleteGear(productDetails.id).then(() => {
        routerProps.history.push('/rent');
      });
    }
  };

  const deleteFromBag = () => {
    if (duplicateGearBag) {
      if (
        window.confirm(
          `${productDetails.title} will be deleted from your gear bag`
        )
      ) {
        orderProductManager
          .deleteOrderProduct(duplicateGearBag[0].id)
          .then(() => {
            window.location.reload();
          });
      }
    }
  };

  const ifQuantityAddButton = (int) => {
    if (isLoggedIn) {
      if (memberId == productDetails.member_id) {
        return (
          <Button className="buy_product clickable" onClick={deleteGear}>
            Delete My Posting
          </Button>
        );
      } else if (int > 0 && duplicateGearBag === false) {
        return (
          <Button className="buy_product clickable" onClick={addToCart}>
            Add To Gear Bag
          </Button>
        );
      } else if (int > 0 && duplicateGearBag) {
        return (
          <Button className="buy_product clickable" onClick={deleteFromBag}>
            Remove from Gear Bag
          </Button>
        );
      } else if (!int) {
        return (
          <h2>
            Loading <Spin />
          </h2>
        );
      }
    }
  };

  function messageOrEditConditional() {
    if (memberId !== productDetails.member_id && productDetails.member) {
      return null;
    } else if (productDetails.member) {
      return (
        <EditProductModal
          productInfo={productDetails}
          setProductInfo={setProductDetails}
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
        />
      );
    }
  }

  function modalOpenButtonConditional() {
    if (productDetails.member && memberId !== productDetails.member_id) {
      if (!isLoggedIn) {
        return (
          <SignUpLoginButtonCombo
            member={productDetails.member.user.first_name}
            productId={productDetails.id}
          />
        );
      }
      return (
        <Button
          className="headline-font details-message-button"
          onClick={displayQuickMessage}
          variant="contained"
          sx={{ borderRadius: 100, margin: '5px 0' }}
        >
          Rent from {productDetails.member.user.first_name}
        </Button>
      );
    } else if (productDetails.member) {
      return (
        <Button
          onClick={() => setIsEditModalVisible(!isEditModalVisible)}
          className="edit-button"
        >
          <EditOutlined />
        </Button>
      );
    }
  }

  const getProductDetail = async () => {
    await productManager.getProductDetail(productId).then((resp) => {
      setProductDetails(resp);
      if (location?.state?.openModal) {
        setIsQuickMessageVisible(true);
      }
    });
  };

  const { routeOtherMember } = useRouteOtherMember();
  function handleNameClick(e) {
    if (memberId && token) {
      memberId === productDetails.member_id
        ? history.push('/myprofile')
        : routeOtherMember(e.target.id);
    } else {
      history.push('/login');
    }
  }

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const displayQuickMessage = () => {
    const memberIdLS = localStorage.getItem(LOCAL_STORAGE.memberId);
    const productMemberId = productDetails.member_id.toString();
    if (!isLoggedIn) {
      window.alert('Please log in or create an account to message lender');
    } else if (memberIdLS == productMemberId) {
      window.alert(
        'This is your own product! Other folks have also posted awesome gear, check some of theirs out!'
      );
    } else if (!isQuickMessageVisible) {
      setIsQuickMessageVisible(true);
    }
  };

  const closeQuickMessage = () => {
    if (isQuickMessageVisible) setIsQuickMessageVisible(false);
  };

  useEffect(() => {
    getProductDetail();
    getOrders();
    getMemberGearBagFunction();
  }, []);
  useEffect(() => {
    messageOrEditConditional();
    modalOpenButtonConditional();
  }, [productDetails]);

  useEffect(() => {
    filterGearBag();
  }, [memberGearBag]);
  useEffect(() => {
    ifQuantityAddButton(productDetails.quantity);
  }, [duplicateGearBag]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        // Successful copy
        message.success('Gear Link has been copied to clipboard!');
      })
      .catch((err) => {
        // Failed to copy
        message.error('Failed to copy Gear Link');
        console.error('Failed to copy URL: ', err);
      });
  };

  return (
    <>
      <div id="productDetails-mainContainer ">
        <div className="productDetails-imageAndDescription">
          {/* TOP SECTION */}

          <div className="productDetails-backButton-container">
            <ArrowLeftOutlined
              className="productDetails-backButton"
              onClick={handleBackButtonClick}
            />
          </div>
          <div className="productDetails-imageContainer">
            {productDetails.image_paths ? (
              <ProductDetailImages imagePaths={productDetails.image_paths} />
            ) : (
              <div></div>
            )}
          </div>
          <div className="productDetails-titleDescriptionInstructions">
            {/* TITLE DETAILS INSTRUCTIONS SECTION */}
            <div>
              <h1 className="product-details-title">
                {productDetails.title
                  ? filterWords.cleanHacked(productDetails.title)
                  : null}
              </h1>
              <div style={{ fontSize: 18 }}>
                <StarRatingAvg avgRating={avgRating} />
                <span style={{ paddingLeft: 8, verticalAlign: 'middle' }}>
                  {totalRatings ? (
                    <a
                      href="#product-reviews"
                      title="Go to reviews"
                    >{`${totalRatings} ratings`}</a>
                  ) : null}
                </span>
              </div>
              <p>
                {productDetails.description
                  ? filterWords.cleanHacked(productDetails.description)
                  : null}
              </p>
              <p>
                <strong>Rental Instructions:</strong>{' '}
                {productDetails.condition
                  ? filterWords.cleanHacked(productDetails.condition)
                  : null}
              </p>
            </div>
            <PricePerDuration
              price={productDetails.price}
              duration={productDetails.duration}
            />
            {/* Middle Section */}
            <div className="productDetails-details">
              {productDetails.product_type ? (
                <div>
                  <span className="body-font">Type: </span>
                  {productDetails.product_type.name}
                </div>
              ) : null}
              <div>
                <span className="body-font">Condition: </span>
                {productDetails.quality}
              </div>
              <div>
                <span className="body-font">Quantity: </span>
                {productDetails.quantity}
              </div>

              {productDetails.zipcode !== 'none' ? (
                <div>
                  <span className="body-font">Zip Code: </span>
                  {productDetails.zipcode}
                </div>
              ) : null}
              <div>
                <Button startIcon={<CopyOutlined />} onClick={copyToClipboard}>
                  Copy Gear Link
                </Button>
              </div>
            </div>
            {modalOpenButtonConditional()}
            <div className="productDetails-rentMoreFrom">
              {productDetails.member ? (
                <ProfileImage
                  classStringDiv={'profile-image-container-product-details'}
                  classStringImg={'profileImage-product-details'}
                  src={productDetails.member.image_path}
                  memberInfo={productDetails.member}
                />
              ) : null}
              {productDetails.member ? (
                <h4
                  className="rent-more-from"
                  // onClick={handleNameClick}
                  id={productDetails.member_id}
                  // style={{ textAlign: "left" }}
                >
                  Rent more from{' '}
                  <Link
                    className="rent-more-hyperlink"
                    id={productDetails.member_id}
                    to={`/othermember/${productDetails.member_id}`}
                  >
                    {productDetails.member.user.first_name}
                  </Link>
                </h4>
              ) : null}
            </div>
            {/* TITLE DETAILS INST SECTION END */}
          </div>
          {/* TOP SECTION END */}
        </div>
        <ProductReviews productId={productId} />

        <div id="detail-map-container">
          <DetailsMap zip={productDetails.zipcode} />
        </div>
      </div>
      {/* MAP SECTION */}
      {messageOrEditConditional()}
      <QuickMessage
        isQuickMessageVisible={isQuickMessageVisible}
        closeQuickMessage={closeQuickMessage}
        productId={productDetails.id}
        productTitle={productDetails.title}
      />
    </>
  );
};

export default ProductDetail;
