import React, { useState, useEffect } from 'react';
import CustomerManager from '../../modules/customerManager';
import productManager from '../../modules/productManager';
import './Profile.css';
import ProfileIcon from '../../images/profileIcon.png';
import { Button, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import EditProfileModal from './EditProfileModal';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import MyGear from './MyGear';

const UserProfile = () => {
  const [memberInfo, setMemberInfo] = useState(null);
  const [lenderProducts, setLenderProducts] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isProductEditModalVisible, setIsProductEditModalVisible] =
    useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductDetails, setSelecteProductDetails] = useState();
  const { memberId } = useLoggedInMemberInfo();

  const getCurrentMember = (member_id) => {
    CustomerManager.getCustomer(member_id).then((resp) => {
      setMemberInfo(resp);
    });
  };

  const phoneNumberGenerator = (p) => {
    return `(${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}${p[5]} ${p[6]}${p[7]}${p[8]}${p[9]}`;
  };
  const getLenderGear = (lender_id) => {
    productManager.getLenderGear(lender_id).then((resp) => {
      setLenderProducts(resp);
    });
  };

  const getProductDetail = async (productId) => {
    await productManager
      .getProductDetail(productId)
      .then(setSelecteProductDetails);
  };

  useEffect(() => {
    if (selectedProductId) {
      getProductDetail(selectedProductId);
      setIsProductEditModalVisible(true);
    }
  }, [selectedProductId]);

  useEffect(() => {
    if (!selectedProductDetails) {
      setSelectedProductId(null);
    }
  }, [selectedProductDetails]);

  useEffect(() => {
    getCurrentMember(memberId);
    getLenderGear(memberId);
  }, [isProductEditModalVisible]);

  if (memberInfo && lenderProducts) {
    return (
      <div className="">
        <div id="profile-section-container">
          <div className="flex-column">
            <div className="profile-image-container">
              {memberInfo.image_path ? (
                <img
                  className="profileImage"
                  style={{ boxShadow: 'none' }}
                  src={memberInfo.image_path}
                  alt="profile"
                />
              ) : (
                <img
                  className="detailsImage"
                  style={{ boxShadow: 'none' }}
                  src={ProfileIcon}
                  alt="profile"
                />
              )}
            </div>
          </div>
          <div id="profile-details-container">
            <h1 className="headline-font">
              {memberInfo.user.first_name} {memberInfo.user.last_name}
            </h1>
            <h3>
              <div className="body-font">Username: </div>
              <div className="subHeadline-font">{memberInfo.user.username}</div>
            </h3>
            <h3 className="body-font">
              Phone:{' '}
              <div className="subHeadline-font">
                {phoneNumberGenerator(memberInfo.phone_number)}
              </div>
            </h3>
            <h3 className="body-font">{memberInfo.address}</h3>
            <h3 className="body-font">
              Hobbies:
              <div className="subHeadline-font">{memberInfo.hobbies}</div>
            </h3>
            <Button onClick={() => setIsEditModalVisible(true)}>
              Edit Profile <EditOutlined />
            </Button>
          </div>
        </div>

        <div className="profileContainer">
          <EditProfileModal
            memberInfo={memberInfo}
            setMemberInfo={setMemberInfo}
            isEditModalVisible={isEditModalVisible}
            setIsEditModalVisible={setIsEditModalVisible}
          />
        </div>
      </div>
    );
  } else {
    return (
      <h1 style={{ width: '100%', textAlign: 'center', marginTop: '20%' }}>
        Finding Your Profile <br />
        <Spin size="large" />
      </h1>
    );
  }
};

export default UserProfile;
