import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const favoritesProductManager = {
  getOrderProductsByOrder(orderId) {
    return fetch(`${baseUrl}/orderproducts?order_id=${orderId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${sessionStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
  createFavoritesProduct(productId) {
    return fetch(`${baseUrl}/favorites-products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ product_id: productId }),
    }).then((resp) => resp.json());
  },
  deleteFavoritesProduct(favoritesProductId) {
    return fetch(`${baseUrl}/favorites-products/${favoritesProductId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  },
  // Using to get the gearbag for the member
  getMemberGearBag(memberId) {
    const token = localStorage.getItem('token');
    return fetch(`${baseUrl}/favorites-products/${memberId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  },
};

export default favoritesProductManager;
