import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Input, Spin } from 'antd';
import productManager from '../../modules/productManager';
import countyLists from '../../modules/maps/mapData';
import { useMediaQuery } from 'react-responsive';

const EditProductModal = ({
  productInfo,
  setProductInfo,
  isEditModalVisible,
  setIsEditModalVisible,
  setSelectedProductId,
}) => {
  const { TextArea } = Input;
  const [productTypes, setProductTypes] = useState([]);
  const [buttonClicked, changeButtonClicked] = useState(false);
  const [credentials, setCredentials] = useState({
    title: productInfo.title,
    price: productInfo.price,
    duration: 'Day',
    description: productInfo.description,
    quality: productInfo.quality,
    quantity: productInfo.quantity,
    condition: productInfo.condition,
    product_type: productInfo.product_type.name,
  });
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const handleFieldChange = (evt) => {
    const stateToChange = { ...credentials };
    stateToChange[evt.target.id] = evt.target.value;
    setCredentials(stateToChange);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changeButtonClicked(true);
    //   This is how our content type is known

    const priceToNumber = parseFloat(credentials.price);
    const quantityToNumber = parseInt(credentials.quantity);
    if (credentials.title.split(' ').join('').length < 3) {
      window.alert('Please enter a valid title');
      changeButtonClicked(false);
    } else if (credentials.price === '' || isNaN(priceToNumber)) {
      window.alert('Please enter a valid price. Enter a number value.');
      changeButtonClicked(false);
    } else if (credentials.quantity === '' || isNaN(quantityToNumber)) {
      window.alert('Quantity can only be a number, please try again');
      changeButtonClicked(false);
    } else if (credentials.description.split(' ').join('').length < 8) {
      window.alert('Please enter a valid description.');
      changeButtonClicked(false);
    } else if (!credentials.quality || !credentials.condition) {
      window.alert('Please complete all form items.');
      changeButtonClicked(false);
    } else {
      const formData = {
        title: credentials.title,
        price: parseFloat(credentials.price),
        duration: credentials.duration,
        description: credentials.description,
        quality: credentials.quality,
        quantity: parseInt(credentials.quantity),
        condition: credentials.condition,
        product_type: credentials.product_type,
      };
      productManager
        .updateProduct(productInfo.id, formData)
        .then((productReturn) => {
          if ('id' in productReturn) {
            window.alert('Your gear has been updated successfully');
            setIsEditModalVisible(false);
            setProductInfo(productReturn);
            if (setSelectedProductId) {
              setSelectedProductId(null);
            }
            setTimeout(() => changeButtonClicked(false), 500);
          } else {
            window.alert('Please fix the product info and try again.');
            changeButtonClicked(false);
          }
        });
    }
  };

  function buttonOrLoading() {
    return buttonClicked ? (
      <Button key="submit" type="primary" disabled={true}>
        <Spin style={{ marginLeft: '8px' }} />
      </Button>
    ) : (
      <Button key="submit" type="primary" onClick={handleSubmit}>
        Submit
      </Button>
    );
  }

  const handleCancel = () => {
    setIsEditModalVisible(false);
    if (setSelectedProductId) {
      setSelectedProductId(null);
    }
  };
  const handleDelete = (e) => {
    if (
      window.confirm(`Are you sure you want to delete ${productInfo.title}?`)
    ) {
      e.preventDefault();
      changeButtonClicked(true);

      productManager.deleteGear(productInfo.id).then((resp) => {
        setIsEditModalVisible(false);
        history.push('/rent');
      });
    }
  };

  const getProductTypes = () => {
    productManager.getProductTypes().then((productTypes) => {
      setProductTypes(productTypes);
    });
  };

  useEffect(() => {
    setCredentials({
      title: productInfo.title,
      price: productInfo.price,
      duration: productInfo.duration,
      description: productInfo.description,
      quality: productInfo.quality,
      quantity: productInfo.quantity,
      condition: productInfo.condition,
      product_type: productInfo.product_type.name,
    });
  }, [productInfo]);

  useEffect(() => {
    getProductTypes();
  }, []);
  useEffect(() => {
    buttonOrLoading();
  }, [buttonClicked]);

  return (
    <Modal
      title="Edit Gear"
      visible={isEditModalVisible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      bodyStyle={isMobile ? { padding: 0 } : undefined}
      style={isMobile ? { top: 0 } : undefined}
      footer={[
        <Button key="delete" onClick={handleDelete} type="primary" danger>
          Delete
        </Button>,
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        buttonOrLoading(),
      ]}
    >
      <section id="creationForm">
        <form onSubmit={handleSubmit}>
          <fieldset>
            <label className="edit-label" htmlFor="title">
              Item Title
            </label>
            <Input
              onChange={handleFieldChange}
              type="text"
              id="title"
              name="title"
              placeholder="Title"
              value={credentials.title}
              maxLength="50"
              required
            />
          </fieldset>
          <fieldset>
            <label className="edit-label" htmlFor="price">
              Price
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                onChange={handleFieldChange}
                type="number"
                id="price"
                name="price"
                placeholder="Price"
                min="0"
                step="1"
                value={credentials.price}
                required
                addonBefore="$"
                style={{ width: 100 }}
              />
              <label
                className="edit-label"
                htmlFor="duration"
                style={{ padding: '0 4px' }}
              >
                Per Day
              </label>
              {/* <select
                onChange={handleFieldChange}
                type="text"
                id="duration"
                name="duration"
                placeholder="Rental Period"
                value={credentials.duration}
                required
                className="ant-input"
              >
                <option value="Hour">Hour</option>
                <option value="Day">Day</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
              </select> */}
            </div>
          </fieldset>
          <fieldset>
            <label className="edit-label" htmlFor="quantity">
              Quantity
            </label>
            <Input
              onChange={handleFieldChange}
              type="number"
              id="quantity"
              name="quantity"
              placeholder="Quantity"
              value={credentials.quantity}
              required
            />
          </fieldset>
          <fieldset>
            <label className="edit-label" htmlFor="description">
              Description
            </label>
            <TextArea
              onChange={handleFieldChange}
              type="text"
              id="description"
              placeholder="Description"
              value={credentials.description}
              required
              rows={4}
              maxLength="1000"
            />
          </fieldset>
          <fieldset>
            <label className="edit-label" htmlFor="condition">
              Return Instructions
            </label>
            <TextArea
              onChange={handleFieldChange}
              type="text"
              id="condition"
              name="condition"
              placeholder="Return Instructions"
              value={credentials.condition}
              maxLength="250"
              required
            />
          </fieldset>
          <fieldset>
            <label className="edit-label" htmlFor="product_type">
              Product Type:
            </label>
            <select
              className="select ant-input"
              id="product_type"
              name="product_type"
              onChange={handleFieldChange}
              required
              value={credentials.product_type}
            >
              {/* Value must be set to empty string to know it has not been selected */}
              {productTypes.map((productType) => {
                return (
                  <option key={productType.id} value={productType.name}>
                    {productType.name}
                  </option>
                );
              })}
            </select>
          </fieldset>
          <fieldset>
            <label className="edit-label" htmlFor="quality">
              Quality:{' '}
            </label>
            <select
              onChange={handleFieldChange}
              type="text"
              id="quality"
              name="quality"
              placeholder="Quality"
              value={credentials.quality}
              maxLength="75"
              required
              className="ant-input"
            >
              <option value="New">New</option>
              <option value="Like New">Like New</option>
              <option value="Gently Used">Gently Used</option>
              <option value="Used">Used</option>
              <option value="Heavily Used">Heavily Used</option>
            </select>
          </fieldset>
          {/* <fieldset>
                    <Checkbox value={localPickup} onChange={handleCheckBox}>Local Pickup</Checkbox>
                </fieldset> */}
        </form>
      </section>
    </Modal>
  );
};

export default EditProductModal;
