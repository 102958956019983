import React from 'react';
import './Faq.css';
import { Collapse } from 'antd';

const Faq = (routerProps) => {
  const props = routerProps.routerProps;
  const { Panel } = Collapse;

  function callback(key) {
    console.log(key);
  }
  return (
    <div id="faqs-container">
      <Collapse>
        <h1>Lender FAQs</h1>
        <Panel id="question" header='Can I set my own “terms of use"?'>
          <div className="answer">
            <p>
              Yes, you can determine price, how long to rent an item, and how
              the item should be used. You will also determine the lending /
              return process (whether at your house, Target parking lot, etc.).{' '}
            </p>
          </div>
        </Panel>

        <Panel id="question" header="What if someone breaks my stuff?">
          <div className="answer">
            <p>
              Gear2Go will guarantee you a full-refund of the value of your item
              should the renter damage or break your equipment. (The founders
              ask that you do not take advantage by claiming items to be broken
              if they were not, the company has yet to raise a round of
              funding!)
            </p>
          </div>
        </Panel>

        <Panel
          id="question"
          header="Will I be held liable if someone becomes injured using my equipment?"
        >
          <div className="answer">
            <p>
              No, you will not be held liable if someone injures themselves
              using your equipment. By agreeing to the terms of service, renters
              agree to participate at their own risk.
            </p>
          </div>
        </Panel>
        <h1>Renter FAQs</h1>
        <Panel
          id="question"
          header="What if the equipment borrowed does not work / meet my expectations?"
        >
          <div className="answer">
            <p>
              Gear2Go will guarantee you a full-refund of the money you spent on
              the rental. A Gear2Go teammate will also personally coordinate an
              alternative rental to compensate you for your trouble. Bottom
              line—we’ve got you covered!
            </p>
          </div>
        </Panel>

        <Panel
          id="question"
          header="What if I get injured using the equipment?"
        >
          <div className="answer">
            <p>
              By participating on the platform and using the equipment, you’re
              agreeing to assume the personal risk of undertaking whatever
              activity may result in injury. Please check the equipment prior to
              use and have the requisite skills to perform your activities
              safely! On that note, our community of lenders generally love to
              share their knowledge; if you have questions just ask them to show
              you how to use the gear properly!
            </p>
          </div>
        </Panel>
        <Panel id="question" header="What if I break the lender’s equipment?">
          <div className="answer">
            <p>
              If the item breaks while you have it, please proactively contact
              the Gear2Go team. Our team will review situations on a
              case-by-case basis to determine liability and payment. If we
              determine you broke the item due to negligence, we will request
              you pay the replacement amount. Our community is based on trust;
              please let us know if you break something!
            </p>
            <p>
              If the item is damaged due to normal wear and tear or was damaged
              prior to rental, don't worry, you won't be held responsible for
              repairs.
            </p>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Faq;
