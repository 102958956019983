import React, { Component } from 'react';
import { urlConditional } from '../../modules/apiInfo';
import navlogo from '../../images/gearG.png';

// TODO: convert to functional component
class EmailVerify extends Component {
  constructor(props) {
    super(props);
    this.state = { valid: 0 };
  }

  async componentDidMount(props) {
    // const apiURL = process.env.REACT_APP_BASE_URL
    const token = this.props.routerProps.match.params.token;
    const apiURL = `${urlConditional()}/verify-email/`;

    if (token) {
      this.setState({ token: this.props.routerProps.match.params.token });

      fetch(apiURL, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status == 'success') {
            this.setState({ valid: 1 });
            console.log('Succesful validations');
            //   localStorage.setItem(EMAIL_VERIFIED, 1)
          } else if (response.status == 'failed') {
            this.setState({ valid: 2 });
            console.log('Failed to validate');
          }
        });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="custom-alert">
              {this.state.valid == 1 && (
                <div
                  className="login-box"
                  role="alert"
                  style={{ left: '50%', textAlign: 'center' }}
                >
                  <img src={navlogo} style={{ width: '150px' }} />
                  <p>
                    Email Verification Done! You are now free to login and use
                    your account.
                  </p>
                  <a href="/login">Go to Login</a>
                </div>
              )}
              {this.state.valid == 2 && (
                <div
                  className="login-box"
                  role="alert"
                  style={{
                    left: '50%',
                    textDecoration: 'none !important',
                    textAlign: 'center',
                  }}
                >
                  <img src={navlogo} style={{ width: '150px' }} />
                  <p>
                    Email Verification Failed. Email may be already verified or
                    the link is broken.
                  </p>
                  <a href="/account-verify">Try resending here!</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailVerify;
