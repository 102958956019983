import React, { useState, useEffect } from 'react';
import { urlConditional } from '../../modules/apiInfo';
import GearLoader from '../../images/gear-loader.gif';
import G2GLogo from '../../images/G2G_black_transparent_background.png';

function StripeVerify({ routerProps }) {
  const memberId = routerProps.match.params.memberId;

  const [respStatus, setRespStatus] = useState(null);

  function verifyStripeAccount() {
    const url = urlConditional();
    setTimeout(() => {
      fetch(`${url}/stripe-verify-account/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          member_id: memberId,
          url: window.location.href.includes('localhost')
            ? 'http://localhost:3000'
            : 'https://www.gear2go.us',
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp);
          setRespStatus(resp);
          // setTimeout(()=>{
          //     window.location.href ='/messages'
          // }, 5000)
        });
    }, 2000);
  }

  function recheckStripeStatus() {
    setRespStatus(null);
    verifyStripeAccount();
  }

  function messageConditional() {
    if (respStatus === null) {
      return <img src={GearLoader} className="" style={{ width: '50px' }} />;
    } else if (respStatus.status === 'success') {
      setTimeout(() => {
        window.location.href = '/lend';
      }, 5000);
      return (
        <div>
          <h2 style={{ color: 'green' }}>Success!</h2>
          <p>
            Congratulations! Your Stripe account has been authorized and you can
            now lend gear and safely receive payments through Gear2Go.
            <br />
            <br />
            You are being redirected . . .
          </p>
          <img src={GearLoader} className="" style={{ width: '30px' }} />
        </div>
      );
    } else if (respStatus.status === 'no-account') {
      setTimeout(() => {
        window.location.href = '/lend';
      }, 5000);
      return (
        <>
          <p>
            There has not been a Stripe account created for you. Please click on
            the "connect with stripe" button to set up your account.
            <br />
            <br />
            You are being redirected . . .
          </p>
          <img src={GearLoader} className="" style={{ width: '30px' }} />
        </>
      );
    } else if (respStatus.status === 'failed') {
      return (
        <div>
          <h2 style={{ color: 'red' }}>Failed</h2>
          <p>Your Stripe account has not been completed.</p>
          <p>
            The Following Reason was given:
            <br />
            <br />
            <strong>{respStatus.message}</strong>
          </p>
          <p
            onClick={recheckStripeStatus}
            style={{ cursor: 'pointer', color: '#1890ff' }}
          >
            Retry Stripe Verification
          </p>
          <p>
            <a href={respStatus.account_link}>Click Here</a> to return to Stripe
            and correct any errors.
          </p>
        </div>
      );
    } else if (respStatus.status === 'duplicate') {
      setTimeout(() => {
        window.location.href = '/lend';
      }, 5000);
      return (
        <>
          <p>
            Your Stripe account has already been verified. You must have gotten
            here accidentally.
            <br />
            <br />
            You are being redirected . . .
          </p>
          <img src={GearLoader} className="" style={{ width: '30px' }} />
        </>
      );
    }
  }

  useEffect(() => {
    verifyStripeAccount();
  }, []);

  return (
    <div className="stripe-authorization-container">
      <img className="main-logo" src={G2GLogo} />
      <h1>Verifying Stripe Account Creation</h1>
      {messageConditional()}
    </div>
  );
}

export default StripeVerify;
