import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import G2GLogo from '../../images/G2G_light_green_transparent_background-01-01.png';
import { Spin, Button, Input } from 'antd';
import { urlConditional } from '../../modules/apiInfo';

export default function VerifyAccountPage({ routerProps }) {
  const history = useHistory();
  const [memberEmail, setMemberEmail] = useState({ email: '' });
  const [resendClicked, setResendClicked] = useState(false);

  const handleFieldChange = (evt) => {
    const stateToChange = { ...memberEmail };
    stateToChange[evt.target.id] = evt.target.value;
    setMemberEmail(stateToChange);
  };

  function resendVerification() {
    setResendClicked('button-clicked');

    if (
      memberEmail.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      const apiURL = `${urlConditional()}/verify-email-resend/`;
      fetch(apiURL, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: memberEmail.email,
          source: window.location.href,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === 'Valid') {
            setResendClicked('success');
          }
        })
        .catch((error) => setResendClicked('error'));
    } else {
      window.alert('Please enter a valid email.');
      setResendClicked(false);
    }
  }

  function resendOrInput() {
    // console.log(window.location.href)
    if (resendClicked === true) {
      return (
        <div>
          <Input
            style={{ width: '65%' }}
            id="email"
            placeholder="Enter Email"
            onChange={handleFieldChange}
            value={memberEmail.email}
          />
          <Button onClick={resendVerification}>Resend</Button>
        </div>
      );
    } else if (resendClicked === false) {
      return (
        <h5
          style={{
            color: 'lightblue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => setResendClicked(true)}
        >
          Click Here to send another email
        </h5>
      );
    } else if (resendClicked === 'button-clicked') {
      return <Spin />;
    } else if (resendClicked === 'success') {
      return <h5 style={{ color: 'white' }}>Verification Resent!</h5>;
    } else if (resendClicked === 'error') {
      return (
        <h5
          style={{
            color: 'lightblue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => setResendClicked(true)}
        >
          We don't have that email, please try again.
        </h5>
      );
    }
  }

  return (
    <div className="login-box-main login-box">
      <img className="main-logo" src={G2GLogo} />
      <h3 style={{ textAlign: 'center' }}>Account creation success.</h3>
      <p>
        We have sent a validation email to your inbox. <br /> Once your account
        is validated you will be free to join the Gear2Go community!
      </p>
      <p style={{ color: 'yellow' }}>(Check your junk folder)</p>
      {resendOrInput()}
      <h2
        onClick={() => history.push('/login')}
        style={{ color: 'lightblue', cursor: 'pointer' }}
      >
        Go To Login
      </h2>
    </div>
  );
}
