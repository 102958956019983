import { urlConditional } from '../apiInfo';

const baseUrl = urlConditional();

async function sendReportEmail(reportObj) {
  const resp = await fetch(`${baseUrl}/report-member/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(reportObj),
  });
  return await resp.json();
}
export default sendReportEmail;
