import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ProfileIcon from '../../images/profileIcon.png';
import moment from 'moment';
import filterWords from '../../modules/filters/bad-words';
import { set } from 'date-fns';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import { linkConditional } from '../../modules/apiInfo';
import { Link } from 'react-router-dom';

export default function ChannelsMGContainer({
  channelsMemberGroups,
  selectedChannel,
  setSelectedChannel,
  setOtherMember,
  setOtherMemberId,
  setCurrentMember,
  setProductSelected,
  setBlockedStatus,
  slideChannelsContainer,
}) {
  const history = useHistory();
  const { memberId } = useLoggedInMemberInfo();

  function selectChannelMG(channel) {
    if (channel.name !== selectedChannel) {
      setBlockedStatus(null);
    }
    setSelectedChannel(channel.name);
    setProductSelected({
      product: channel.product_clicked,
      productId: channel.product_clicked_id,
    });
    const otherMember =
      channel.member1_id == memberId ? channel.member2 : channel.member1;
    setOtherMember(otherMember);
    setOtherMemberId(
      channel.member1_id == memberId ? channel.member2_id : channel.member1_id
    );
  }

  function truncateAndFilter(input) {
    if (input) {
      if (input.length > 15) {
        return filterWords.cleanHacked(input).substring(0, 30) + '...';
      }
      return filterWords.cleanHacked(input);
    }
  }

  function createChannelCards() {
    if (!channelsMemberGroups) {
      return <Spin />;
    } else if (channelsMemberGroups === 'no-groups') {
      return <h3>No Contacts . . . reach out to a Lender</h3>;
    } else {
      return channelsMemberGroups.map((channel, i) => {
        const otherMember =
          channel.member1_id == memberId ? channel.member2 : channel.member1;
        const otherMemberId =
          channel.member1_id == memberId
            ? channel.member2_id
            : channel.member1_id;
        const currentMember =
          channel.member1_id == memberId ? channel.member1 : channel.member2;
        const selectedChannelClass =
          channel.name === selectedChannel ? 'selected-channel' : null;
        let d = new Date(channel.most_recent_timestamp);
        let date = moment(d);
        return (
          <article
            id={channel.name}
            key={channel.id}
            className={`indv-channel-container ${selectedChannelClass}`}
            onClick={(e) => {
              slideChannelsContainer(e);
              selectChannelMG(channel);
            }}
          >
            {otherMember.image_path ? (
              <Link to={`/othermember/${otherMemberId}`}>
                <img
                  className="member-channel-icon"
                  style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                  src={otherMember.image_path}
                />
              </Link>
            ) : (
              <Link to={`/othermember/${otherMemberId}`}>
                <img
                  className="member-channel-icon"
                  style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                  src={ProfileIcon}
                />
              </Link>
            )}
            <div>
              <div style={{}}>
                <p>{otherMember.user.first_name}</p>
                <p>{date.format('ddd h:mma')}</p>
              </div>
              <div>
                <p style={{ color: '#8c8c8c' }}>
                  {truncateAndFilter(channel.most_recent_message)}
                </p>
              </div>
            </div>
          </article>
        );
      });
    }
  }

  useEffect(() => {
    createChannelCards();
  }, [channelsMemberGroups]);

  const isMobileView = () => {
    if (window.innerWidth < 420) return true;
    else return false;
  };

  const handleBackButtonClick = () => {
    history.goBack();
    if (history.location.pathname == '/messages') history.goBack();
  };

  return (
    <section
      id="channels-mg-container"
      className="channels-slide-in"
      style={{ zIndex: 2 }}
    >
      <div
        style={{
          color: 'white',
          backgroundColor: '#27a927',
          padding: '15px 20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {isMobileView() ? (
          <div className="mg-container-back-button">
            <ArrowLeftOutlined onClick={handleBackButtonClick} />
          </div>
        ) : null}
        <h1
          className="mg-container-title"
          style={{ margin: '0px', textAlign: 'center' }}
        >
          Messages
        </h1>
        <a
          id="more-gear-channels"
          className="pointer"
          style={{
            color: 'white',
            backgroundColor: '#27a927',
            padding: '5px 3px',
            borderRadius: '4px',
          }}
          href={linkConditional() + '/rent'}
        >
          Find More Gear
        </a>
      </div>
      {createChannelCards()}
    </section>
  );
}
