import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import G2GLogo from '../../images/g2g-logo.png';
import { Spin, Button, Input } from 'antd';
import { urlConditional } from '../../modules/apiInfo';

export default function VerifyAccountPage({ routerProps }) {
  const history = useHistory();
  const [memberEmail, setMemberEmail] = useState({ email: '' });
  const [resendClicked, setResendClicked] = useState(false);

  const handleFieldChange = (evt) => {
    const stateToChange = { ...memberEmail };
    stateToChange[evt.target.id] = evt.target.value;
    setMemberEmail(stateToChange);
  };

  function resendVerification() {
    setResendClicked('button-clicked');

    if (
      memberEmail.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      const convertEmail = memberEmail['email'].split('@').join('%40');

      const apiURL = `${urlConditional()}/request-reset-email/?email=${convertEmail}`;
      fetch(apiURL)
        .then((res) => res.json())
        .then((response) => {
          if (response.success === true) {
            setResendClicked('success');
          } else if (response.success === false) {
            setResendClicked('error');
            setTimeout(() => setResendClicked(false), 2000);
          }
        })
        .catch((error) => setResendClicked('error'));
    } else {
      window.alert('Please enter a valid email.');
      setResendClicked(false);
    }
  }

  function resendOrInput() {
    if (resendClicked === false) {
      return (
        <div>
          <Input
            style={{ width: '65%' }}
            id="email"
            placeholder="Enter Email"
            onChange={handleFieldChange}
            value={memberEmail.email}
          />
          <Button onClick={resendVerification}>Send</Button>
        </div>
      );
    } else if (resendClicked === 'button-clicked') {
      return <Spin />;
    } else if (resendClicked === 'success') {
      return (
        <h5 style={{ color: 'white' }}>
          Password Reset Request has been sent to your email!
        </h5>
      );
    } else if (resendClicked === 'error') {
      return (
        <h5
          style={{
            color: 'lightblue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => setResendClicked(false)}
        >
          We don't have that email, please try again.
        </h5>
      );
    }
  }

  useEffect(() => {
    resendOrInput();
  }, [resendClicked]);

  return (
    <div className="login-box-main login-box">
      <img className="main-logo" src={G2GLogo} />
      <h3 style={{ textAlign: 'center' }}>Reset Password Request.</h3>
      <p>
        Please enter the email you used to create your Gear2Go account. We will
        send you a link to reset your password.
      </p>
      <p style={{ color: 'yellow' }}>(Check your junk folder)</p>
      {resendOrInput()}
      <h2
        onClick={() => history.push('/login')}
        style={{ color: 'lightblue', cursor: 'pointer' }}
      >
        Go To Login
      </h2>
    </div>
  );
}
