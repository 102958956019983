import { createTheme } from '@mui/material';

const g2gTheme = {
  g2gDark: '#5a7949',
  /* g2gDark: '#537558', old dark*/
  g2gLight: '#c2ee76',
  g2gMedium: '#7bd71f',
  g2gLight2: 'rgb(187, 206, 190)',
  g2gLightYellow: 'rgb(224, 227, 210)',
};

export const theme = createTheme({
  palette: {
    primary: {
      main: g2gTheme.g2gMedium,
    },
  },
});
