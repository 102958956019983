import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const PaymentAPI = {
  setupPaymentIntent(body) {
    return fetch(`${baseUrl}/payments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  },
  paymentSuccess(body) {
    return fetch(`${baseUrl}/payments`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ ...body, reason: 'payment_success' }),
    }).then((resp) => resp.json());
  },
  cancelPayment(body) {
    return fetch(`${baseUrl}/payments`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ ...body, reason: 'cancel' }),
    }).then((resp) => resp.json());
  },
};

export default PaymentAPI;
