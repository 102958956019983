import React from 'react';
import { Collapse } from 'antd';
import './SafetyPage.css';

const Safety = (routerProps) => {
  const props = routerProps.routerProps;
  const { Panel } = Collapse;

  return (
    <div id="instructions-container">
      <h1 id="info-title">Community Safety is our Top Priority</h1>{' '}
      <Collapse>
        <Panel className="title" header="Lender Safety" key="1">
          <p>
            As a lender, you retain the right to decline a reservation request
            or cancel a transaction at any time if you feel uncomfortable.
            <br />
            <br />
            The map functionality currently shows the center of the zip code you
            entered, not your actual address. This function was added so renters
            will know approximately how far they may have to travel to pick up
            your gear.
            <br />
            <br />
            Please set pick-up / drop-off locations that you are comfortable
            with. Don't want to meet at your house? No worries, meet in the
            Target parking lot. If the renter is unwilling / unable to meet at a
            certain location, simply decline the transaction.
          </p>
        </Panel>
        <Panel className="title" header="Renter Safety" key="2">
          <p>
            As a renter, you retain the right to decline a reservation request
            or cancel a transaction at any time if you feel uncomfortable.
            <br />
            <br />
            If you are uncomfortable with the pick-up / drop-off location
            requested by the lender, simply request an alternate location. It
            will be up to the lender to confirm the suitability of the suggested
            location.
          </p>
        </Panel>
      </Collapse>
      <br />
      <h2 className="contact-text">
        Our team is available 24/7 to handle issues. Please call us any time at
        (510) 982-6146 for personalized customer support.
      </h2>
      <h1 id="info-title">
        <b>An Expansion Plan Prioritizing Safety</b>
      </h1>
      <h2>
        <b>Block Unsafe Users:</b>
      </h2>
      <p className="plan-text">
        Block unsafe users easily using the Block Button located within the
        messaging application. This will block all future correspondence from
        the user.
      </p>
      <h2>
        <b>Flag Unsafe Content:</b>
      </h2>
      <p className="plan-text">
        Flag and report unsafe or inappropriate behavior directly to the Gear2Go
        support team by using the Flag Button within the messaging application.
      </p>
      <h2>
        <b>Reviews:</b>
      </h2>
      <p className="plan-text">
        Unsure if a transaction will meet your expectations? Look to our
        upcoming review feature to see what others thought about the item,
        lender, and renter. Both sides will not be able to review each other
        until after the transaction is complete so you will know that the review
        is based on an actual platform experience.{' '}
      </p>
      <h2>
        <b>Profile Creation:</b>
      </h2>
      <p className="plan-text">
        We will be asking lenders and renters to provide their full name, date
        of birth, phone number, payment information, and email.
      </p>
      <h2>
        <b>"High-Risk" Gear Checks:</b>
      </h2>
      <p className="plan-text">
        Lenders should ensure all items are fully functional, defect free, and
        completely safe when used appropriately. Lenders should inspect items
        and ensure items are safe for their intended use and fully functional
        before posting them on our site. Renters should carefully inspect items
        received for any defects, problems, or safety issues prior to using the
        item. If any item is found to be defective or unsafe for its intended
        purpose, the Lender should be notified immediately. The Gear2Go team may
        individually contact lenders to physically inspect any items to ensure
        maintenance status and quality. Items not passing review will be removed
        from the site. That said, the Gear2Go team are not experts and cannot
        completely ensure any item is 100% safe and effective for its intended
        use. Lenders are responsible for ensuring their items are safe to use,
        and Renters should always inspect items prior to use to ensure there are
        no safety issues. If any issues arise, contact the Gear2Go team.{' '}
      </p>
    </div>
  );
};

export default Safety;
