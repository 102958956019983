import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Spin, Select } from 'antd';
import { SoundOutlined, StopOutlined } from '@ant-design/icons';
import sendReportEmail from '../../modules/safety/sendMemberReport';
import memberBlocking from '../../modules/safety/blockMemberStatus';
import Recaptcha from 'react-recaptcha';
const { TextArea } = Input;
const { Option } = Select;

export default function ReportModal({
  currentMember,
  otherMember,
  toggleReportModal,
  isReportModalVisible,
  setIsReportModalVisible,
  blockedStatus,
  setBlockedStatus,
  newMessage,
}) {
  const [reportEmail, setReportEmail] = useState({ message: '' });
  const [sendClicked, setSendClicked] = useState(false);
  const [isVerifiedNotRobot, setVerifiedNotRobot] = useState(false);
  const [modalStatus, setModalStatus] = useState('select');

  const handleFieldChange = (evt) => {
    const stateToChange = { ...reportEmail };
    stateToChange[evt.target.id] = evt.target.value;
    setReportEmail(stateToChange);
  };

  function getBlockedStatus() {
    memberBlocking
      .getBlockedStatus({
        other_member: otherMember.id,
        current_member: currentMember.id,
      })
      .then((resp) => {
        if (resp.length == 2) {
          setBlockedStatus('both');
        } else if (resp.length == 1) {
          if (resp[0].blocked_member_id == otherMember.id) {
            setBlockedStatus('blocking');
          } else if (resp[0].blocked_member_id == currentMember.id) {
            setBlockedStatus('blocked');
          }
        } else {
          setBlockedStatus(false);
        }
      });
  }

  const submitReport = () => {
    setSendClicked(true);
    if (!isVerifiedNotRobot) {
      window.alert('Please verify that you are human before sending.');
      setSendClicked(false);
    } else if (reportEmail.message.split(' ').join('').length > 2) {
      const emailPost = { ...reportEmail };
      emailPost['reported_member'] = otherMember.user.email;
      emailPost['reporting_member'] = currentMember.user.email;
      emailPost['verify_token'] = isVerifiedNotRobot;
      sendReportEmail(emailPost).then((resp) => {
        toggleReportModal();
        setModalStatus('select');
        setReportEmail({ message: '' });
        setTimeout(function () {
          setSendClicked(false);
        }, 500);
      });
    } else {
      window.alert('Please enter a message.');
      setSendClicked(false);
    }
  };

  function changeBlockMemberStatus() {
    setSendClicked(true);
    memberBlocking
      .blockMember({
        blocked_member: otherMember.id,
        blocking_member: currentMember.id,
      })
      .then((resp) => {
        getBlockedStatus();
        setIsReportModalVisible(false);
        setSendClicked(false);
        setModalStatus('select');
        // } else if (resp.is_blocked == false) {
        //   setBlockedStatus(false);
        //   setSendClicked(false);
        //   setModalStatus('select');
        //   setIsReportModalVisible(false);
        // }
      });
  }

  function buttonOrLoading() {
    if (sendClicked) {
      return <Spin style={{ marginLeft: '8px' }} />;
    } else if (modalStatus == 'report') {
      return (
        <Button key="submit" type="primary" onClick={submitReport} danger>
          Report
        </Button>
      );
    } else if (modalStatus == 'block') {
      return (
        <Button
          key="submit"
          type="primary"
          onClick={changeBlockMemberStatus}
          danger
        >
          Block
        </Button>
      );
    } else if (modalStatus == 'unblock') {
      return (
        <Button
          key="submit"
          type="primary"
          onClick={changeBlockMemberStatus}
          danger
        >
          Unblock
        </Button>
      );
    }
  }

  const handleCancel = () => {
    setModalStatus('select');
    toggleReportModal();
  };

  function verifyHuman(response) {
    if (response) {
      setVerifiedNotRobot(response);
    }
  }

  var recaptchaLoaded = function () {
    console.log('Recaptha loaded');
  };

  // SECTION FOR DIFFERENT MODAL CONTENT

  function modalContentSelector(state) {
    if (otherMember) {
      if (state == 'select') {
        return (
          <Select
            placeholder="Select Option"
            style={{ width: '100%' }}
            onChange={(val) => setModalStatus(val)}
          >
            <Option value="report">
              <SoundOutlined /> Report {otherMember.user.first_name} for
              misconduct.
            </Option>
            {blockedStatus == 'blocking' || blockedStatus == 'both' ? (
              <Option value="unblock">
                <StopOutlined /> Unblock {otherMember.user.first_name}?
              </Option>
            ) : (
              <Option value="block">
                <StopOutlined /> Block {otherMember.user.first_name}?
              </Option>
            )}
          </Select>
        );
      } else if (state == 'report') {
        return (
          <>
            <TextArea
              onChange={handleFieldChange}
              rows={4}
              id="message"
              placeholder="Type out details here"
              value={reportEmail.message}
              required
            />

            <p style={{ marginTop: '15px' }}>
              *We will look into your report and follow up with you. Thank you
              for working to keep Gear2Go a safe community.
            </p>
            <Recaptcha
              sitekey="6LerR4AaAAAAAP69VAyWuxgBxpgDBMZW7C2-Kdj6"
              render="explicit"
              verifyCallback={verifyHuman}
              onloadCallback={recaptchaLoaded}
            />
          </>
        );
      } else if (state == 'block') {
        return (
          <>
            <h3>
              Are you sure you want to block {otherMember.user.first_name}?
            </h3>
            <p>
              You will not be able to send or receive messages or transactions.
            </p>
          </>
        );
      } else if (state == 'unblock') {
        return (
          <>
            <h3>
              Are you sure you want to unblock {otherMember.user.first_name}?
            </h3>
            <p>They will be able to send messages to you again.</p>
          </>
        );
      }
    }
  }

  function modalTitleSelector(state) {
    if (otherMember) {
      if (state == 'select') {
        return 'Report or Block';
      } else if (state == 'report') {
        return `Report ${
          otherMember ? otherMember.user.first_name : null
        } for misconduct.`;
      } else if (state == 'block') {
        return 'Block';
      }
    }
  }

  useEffect(() => {
    if (otherMember) {
      getBlockedStatus();
    }
  }, [otherMember, newMessage]);

  return (
    <Modal
      title={modalTitleSelector(modalStatus)}
      visible={isReportModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        buttonOrLoading(),
      ]}
    >
      {otherMember ? modalContentSelector(modalStatus) : null}
    </Modal>
  );
}
