import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { Button, Input, InputAdornment, TextField } from '@mui/material';
import gearRequestManager from '../../modules/gearRequestManager';
import Recaptcha from 'react-recaptcha';
import './GearRequest.css';
import { AttachMoney, Money } from '@mui/icons-material';

const GearRequestFormNew = ({ routerProps }) => {
  const [buttonClicked, changeButtonClicked] = useState(false);
  const [gearRequest, setGearRequest] = useState({ duration: 'Day' });
  const [isVerifiedNotRobot, setVerifiedNotRobot] = useState(false);

  const handleFieldChange = (evt) => {
    const stateToChange = { ...gearRequest };
    stateToChange[evt.target.id] = evt.target.value;
    setGearRequest(stateToChange);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const requestInfo = {
      product_requested: gearRequest.product_requested,
      offer_price: gearRequest.offer_price,
      duration: gearRequest.duration,
      verify_token: isVerifiedNotRobot,
    };
    changeButtonClicked(true);
    if (!isVerifiedNotRobot) {
      window.alert(
        'Please verify that you are human by completing the captcha.'
      );
      changeButtonClicked(false);
    } else if (
      !requestInfo.product_requested ||
      !requestInfo.offer_price ||
      !requestInfo.duration ||
      requestInfo.product_requested.split(' ').join('').length < 3
    ) {
      window.alert('Please complete all form items.');
      changeButtonClicked(false);
    } else {
      return gearRequestManager
        .createProductRequest(requestInfo)
        .then((parsedResponse) => {
          window.alert('Request succesfully submitted!');
        });
    }
  };

  function verifyHuman(response) {
    if (response) {
      setVerifiedNotRobot(response);
    }
  }

  return (
    <div
      style={{
        border: '0.5px solid black',
        borderRadius: 4,
        flexFlow: 'column',
        padding: 8,
      }}
    >
      <div style={{ padding: 4 }}>
        <Input
          id="product_requested"
          onChange={handleFieldChange}
          type="text"
          placeholder="Gear Request"
          name=""
          value={gearRequest.product_requested}
          fullWidth
        />
      </div>

      <fieldset style={{ display: 'flex', alignItems: 'center', padding: 4 }}>
        <Input
          onChange={handleFieldChange}
          type="number"
          id="offer_price"
          placeholder="Offer Price"
          min="0"
          step="1"
          value={gearRequest.offer_price}
          required
          startAdornment={
            <InputAdornment position="start">
              <AttachMoney />
            </InputAdornment>
          }
        />
        <label style={{ padding: '0 4px' }}> Per </label>
        <select
          onChange={handleFieldChange}
          type="text"
          id="duration"
          placeholder="Rental Period"
          value={gearRequest.duration}
          required
        >
          <option value="Day">Day</option>
        </select>
      </fieldset>
      <div style={{ padding: 4 }}>
        <Recaptcha
          sitekey="6LerR4AaAAAAAP69VAyWuxgBxpgDBMZW7C2-Kdj6"
          render="explicit"
          verifyCallback={verifyHuman}
          onloadCallback={() => console.log('recaptcha loaded')}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleSubmit} variant="contained">
          Request Gear
        </Button>
      </div>
    </div>
  );
};

export default GearRequestFormNew;
