import React from 'react';

const MemberQuickInfo = ({ member, handleOnClick }) => {
  const { image_path, user } = member;

  return (
    <div onClick={handleOnClick} className="hover-button tooltip">
      <span className="tooltiptext">Go to member page</span>
      <div className="flex-row">
        <div className="review-image">
          <img src={image_path || '#'} alt={'No image available'} />
        </div>
        <div className="header" style={{ paddingLeft: 12 }}>
          {user.first_name} {user.last_name}
        </div>
      </div>
    </div>
  );
};

export default MemberQuickInfo;
