import React, { useState, useEffect } from 'react';
import gearRequestManager from '../../modules/gearRequestManager';
import SearchBar from './GearRequestSearchBar';
import { useHistory } from 'react-router-dom';
import './GearRequest.css';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import useRouteOtherMember from '../../helpers/routeOtherMember';
import useScrollToTop from '../../hooks/useScrollToTop';
import GearRequestFormNew from './GearRequestFormNew';

const ProductRequests = ({ routerProps }) => {
  useScrollToTop();
  const [requests, setAllRequests] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [totalRequestsCount, setTotalRequestsCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const [buttonClicked, changeButtonClicked] = useState(false);
  const history = useHistory();
  const { memberId, token } = useLoggedInMemberInfo();

  const getProductRequests = () => {
    gearRequestManager.getProductRequests(searchTerm).then((resp) => {
      setAllRequests(resp.results);
      setTotalRequestsCount(resp.count);
    });
  };

  const { routeOtherMember } = useRouteOtherMember();
  function handleNameClick(e) {
    if (memberId && token) {
      memberId === e.target.id
        ? history.push('/myprofile')
        : routeOtherMember(e.target.id);
    } else {
      history.push('/login');
    }
  }

  function requestFilterUltraFunction() {
    const requestSearchTerm = searchTerm ? searchTerm.toLowerCase() : null;
    if (searchTerm == '') {
      return requests;
    } else if (searchTerm !== null) {
      const filtered = requests.filter((request) => {
        if (
          request.product_requested.toLowerCase().includes(requestSearchTerm) ||
          request.duration.toLowerCase().includes(requestSearchTerm) ||
          request.member.user.first_name
            .toLowerCase()
            .includes(requestSearchTerm) ||
          request.member.user.last_name
            .toLowerCase()
            .includes(requestSearchTerm)
        ) {
          return request;
        }
      });
      return filtered;
    }
  }

  function requestCards() {
    let displayRequests = requests;
    if (!requests) return <div />;
    if (searchTerm) {
      displayRequests = requestFilterUltraFunction();
    }

    if (!displayRequests.length) {
      return (
        <div className="no-results-container">
          <h1> No search results. Please try another search term.</h1>
        </div>
      );
    } else {
      const requestTags = displayRequests.map((request) => {
        return (
          <tr key={request.id}>
            <td>{request.product_requested}</td>
            <td>
              ${request.offer_price}/{request.duration}
            </td>
            <td
              id={request.member_id}
              className="posted-by-link"
              onClick={handleNameClick}
            >
              {request.member.user.first_name} {request.member.user.last_name}
            </td>
          </tr>
        );
      });
      return requestTags;
    }
  }

  useEffect(() => {
    getProductRequests();
  }, []);

  return (
    <div className="gear-request-container">
      <h1>Looking for gear but don't see it here?</h1>
      <h3>
        Submit a request to be featured in our next newsletter. We'll also let
        you know when the gear is available to rent!
      </h3>
      <GearRequestFormNew />
      <section className="gear-request-header">
        {/* <h1 className="gear-request-title">Gear Requests</h1> */}
        <SearchBar
          value="Request Gear"
          className="search-bar"
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
      </section>
      <table>
        <tr>
          <th scope="col">Gear Requested</th>
          <th scope="col">Offer Price</th>
          <th scope="col">Posted By</th>
        </tr>
        {requestCards()}
      </table>
    </div>
  );
};

export default ProductRequests;
