import React from 'react';
import Icon, { StarOutlined, StarFilled } from '@ant-design/icons';
import './PartialStar.css';

const getClass = (value) => {
  switch (true) {
    case value < 0.34:
      return 'clip-third';
    case value < 0.51:
      return 'clip-half';
    case value < 0.68:
      return 'clip-twothirds';
    default:
      return 'clip-almostfull';
  }
};

const PartialStar = ({ decimal }) => {
  return (
    <span style={{ display: 'flex', position: 'relative' }}>
      <Icon component={StarOutlined} />
      <Icon
        component={StarFilled}
        style={{ position: 'absolute', top: 0, left: 0 }}
        className={getClass(decimal)}
      />
    </span>
  );
};

export default PartialStar;
