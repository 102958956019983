import React from 'react';
import { useHistory } from 'react-router-dom';
import withNoAuth from '../../helpers/withNoAuth';
import './RegisterButton.css';

const RegisterButton = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/register');
  };
  return (
    <div className="button--register-container">
      <div className="button--register" onClick={handleClick}>
        Sign Up
      </div>
    </div>
  );
};

export default withNoAuth(RegisterButton);
