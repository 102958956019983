import React, { useEffect, useState } from 'react';
import { Input, Spin } from 'antd';
import { urlConditional } from '../../modules/apiInfo';
import navlogo from '../../images/navlogo.png';

function PasswordReset({ routerProps }) {
  const [valid, setValid] = useState(0);
  const [token, setToken] = useState(routerProps.match.params.token);
  const [uidb64, setUidb64] = useState(routerProps.match.params.uidb64);
  const [authToken, setAuthToken] = useState(null);

  const [newPassword, setNewPassword] = useState({
    password: '',
    passwordVerify: '',
  });

  // const apiURL = process.env.REACT_APP_BASE_URL
  const validateURL = `${urlConditional()}/password-reset/${uidb64}/${token}/`;
  const submitURL = `${urlConditional()}/password-reset-complete`;

  const handleFieldChange = (evt) => {
    const stateToChange = { ...newPassword };
    stateToChange[evt.target.id] = evt.target.value;
    setNewPassword(stateToChange);
  };

  function verifyToken() {
    if (token) {
      fetch(validateURL)
        .then((res) => res.json())
        .then((response) => {
          if (response.status) {
            setValid(1);

            setAuthToken(response.auth_token);
            //   localStorage.setItem(EMAIL_VERIFIED, 1)
          } else {
            setValid(2);
          }
        });
    }
  }

  function submitNewPassword() {
    if (newPassword.password !== newPassword.passwordVerify) {
      window.alert('The passwords entered do not match. Please try again.');
    } else if (newPassword.password.length < 6) {
      window.alert(
        'Your password must be greater than 6 characters. Please try again.'
      );
    } else {
      fetch(submitURL, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          token: token,
          uidb64: uidb64,
          password: newPassword.password,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            setValid(3);

            //   localStorage.setItem(EMAIL_VERIFIED, 1)
          } else {
            setValid(2);
          }
        });
    }
  }

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="custom-alert">
            {valid == 1 && (
              <div
                className="login-box"
                role="alert"
                style={{ left: '50%', textAlign: 'center' }}
              >
                <img src={navlogo} style={{ width: '150px' }} />
                <form>
                  <fieldset>
                    <Input
                      onChange={handleFieldChange}
                      type="password"
                      id="password"
                      placeholder="Password"
                      value={newPassword.password}
                    />
                  </fieldset>
                  <fieldset>
                    <Input
                      onChange={handleFieldChange}
                      type="password"
                      id="passwordVerify"
                      placeholder="Repeat Password"
                      value={newPassword.passwordVerify}
                    />
                  </fieldset>
                  <input
                    className="btn"
                    type="button"
                    name=""
                    value="Reset Password"
                    onClick={submitNewPassword}
                  />
                </form>
              </div>
            )}
            {valid == 2 && (
              <div
                className="login-box"
                role="alert"
                style={{
                  left: '50%',
                  textDecoration: 'none !important',
                  textAlign: 'center',
                }}
              >
                <img src={navlogo} style={{ width: '150px' }} />
                <p>Password Reset Failed.</p>
                <a href="/reset-request">Try resending here!</a>
              </div>
            )}
            {valid == 3 && (
              <div
                className="login-box"
                role="alert"
                style={{
                  left: '50%',
                  textDecoration: 'none !important',
                  textAlign: 'center',
                }}
              >
                <img src={navlogo} style={{ width: '150px' }} />
                <p>Your password was reset!</p>
                <a href="/login">Click here to login</a>
              </div>
            )}
            {valid == 0 && <Spin />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
