import React, { useState, useEffect, useRef } from 'react';
import productManager from '../../modules/productManager';
import { Input, Spin, Collapse } from 'antd';
import './Sell.css';
import Recaptcha from 'react-recaptcha';
import handleImageUpload from '../../helpers/compressImage';
import { urlConditional } from '../../modules/apiInfo';
import GearLoader from '../GearLoader';
import LenderCreateStripeAccount from './LenderCreateStripeAccount';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo.js';
import USER_LOCATIONS, {
  useFormattedLocation,
} from '../../constants/locations.js';
import {
  getZipFromLocal,
  saveZipToLocal,
  useValidateLocationFromZip,
  zipToNumber,
} from './utils/zipCode';
import { rentalInstructions } from '../../constants/textStrings';
import { IconButton } from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import filterWords from '../../modules/filters/bad-words';

const MAXIMUM_FILE_UPLOAD = 4;

const EmptyProductImage = () => {
  return (
    <div
      className="empty-images"
      style={{
        height: 200,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed grey',
        borderRadius: 4,
        margin: '0 4px',
      }}
    >
      <label htmlFor="image_path">{`Add up to ${MAXIMUM_FILE_UPLOAD} photos`}</label>
    </div>
  );
};

const Sell = ({ routerProps }) => {
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const [member, setMember] = useState(null);
  const [productInfo, setProductInfo] = useState({
    title: '',
    price: '',
    duration: 'Day',
    description: '',
    quality: '',
    quantity: 1,
    zipcode: getZipFromLocal(),
    condition: '',
    product_type: '',
  });
  const [productTypes, setProductTypes] = useState([]);
  const [isVerifiedNotRobot, setVerifiedNotRobot] = useState(false);
  const [buttonClicked, changeButtonClicked] = useState(false);
  const url = urlConditional();
  const [images, setImages] = useState([]);
  const { memberId, userLocation } = useLoggedInMemberInfo();
  const formattedLocation = useFormattedLocation();
  const { isValidZipCode } = useValidateLocationFromZip();

  let titleInput = useRef();
  let priceInput = useRef();
  let quantityInput = useRef();
  let zipcodeInput = useRef();
  let descriptionInput = useRef();
  let conditionInput = useRef();
  let productTypeInput = useRef();
  let qualityInput = useRef();
  let imageFileInput = useRef();

  const handleFieldChange = (evt) => {
    const stateToChange = { ...productInfo };
    stateToChange[evt.target.id] = evt.target.value;
    setProductInfo(stateToChange);
  };

  function getCurrentMember() {
    if (memberId) {
      fetch(`${url}/members/${memberId}`)
        .then((resp) => resp.json())
        .then((resp) => setMember(resp));
    }
  }

  const updateImageArray = (newFile, imgLink) => {
    const duplicate = images.find(
      ({ imagePath }) => newFile.name === imagePath
    );
    if (!duplicate) {
      setImages((prev) => {
        return [
          ...prev,
          {
            imageFile: newFile,
            imagePath: newFile.name,
            imagePreview: imgLink,
          },
        ];
      });
    }
  };

  const checkMaxPhotos = (newFiles) => {
    return images.length + newFiles > MAXIMUM_FILE_UPLOAD;
  };

  function handleFileUpload(e) {
    const files = e.target.files;
    let fileCount = files.length;

    if (files) {
      if (checkMaxPhotos(fileCount)) {
        window.alert(
          'Maximum number of photos that can be uploaded is ' +
            MAXIMUM_FILE_UPLOAD
        );
      } else {
        for (let file of files) {
          if (file) {
            if (file.size < 600000) {
              // console.log('file size small: ', file.size);
              let img = new Image();
              img.src = URL.createObjectURL(file);
              img.title = file.name;
              updateImageArray(file, img.src);
            } else {
              // compress files that are too large
              handleImageUpload(file).then((compressedImage) => {
                let img = new Image();
                img.src = URL.createObjectURL(compressedImage);
                img.title = compressedImage.name;
                updateImageArray(compressedImage, img.src);
              });
            }
          }
        }
      }
    }
  }

  const removeImage = (image) => {
    setImages(images.filter((item) => item !== image));
  };

  function verifyHuman(response) {
    if (response) {
      setVerifiedNotRobot(response);
    }
  }

  var recaptchaLoaded = function () {
    console.log('Recaptcha loaded');
  };

  const removeSubstring = (str, searchstr) => {
    const index = str.indexOf(searchstr);
    if (index === -1) {
      return str;
    }
    return str.slice(0, index) + str.slice(index + searchstr.length);
  };

  const removeRedInputHighlight = (inputRef) => {
    if (inputRef.current && inputRef.current.resizableTextArea) {
      const initialClassName = removeSubstring(
        inputRef.current.resizableTextArea.textArea.className,
        'highlight-red'
      );
      inputRef.current.resizableTextArea.textArea.className = initialClassName;
    } else if (
      inputRef.current &&
      inputRef.current.input.className.includes('highlight-red')
    ) {
      const initialClassName = removeSubstring(
        inputRef.current.input.className,
        'highlight-red'
      );
      inputRef.current.input.className = initialClassName;
    }
  };

  const removeRedSelectHighlight = (id) => {
    if (document.getElementById(id).className.includes('highlight-red')) {
      const initialClassName = removeSubstring(
        document.getElementById(id).className,
        'highlight-red'
      );
      document.getElementById(id).className = initialClassName;
    }
  };

  const removeAllRedInputHighlights = () => {
    const inputList = [
      titleInput,
      priceInput,
      quantityInput,
      zipcodeInput,
      descriptionInput,
      conditionInput,
      // imageFileInput,
    ];
    inputList.forEach((inputRef) => {
      removeRedInputHighlight(inputRef);
    });
    removeRedSelectHighlight('product_type');
    removeRedSelectHighlight('quality');
  };

  const highlightInputRed = (inputRef) => {
    if (inputRef.current.resizableTextArea) {
      inputRef.current.resizableTextArea.textArea.className += ' highlight-red';
    } else if (!inputRef.current.input.className.includes('highlight-red')) {
      inputRef.current.input.className += ' highlight-red';
    }
  };

  const highlightSelectRed = (id) => {
    if (!document.getElementById(id).className.includes('highlight-red')) {
      document.getElementById(id).className += ' highlight-red';
    }
  };

  function badInputMessage(message) {
    window.alert(message);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    changeButtonClicked(true);
    removeAllRedInputHighlights();
    //   This is how our content type is known
    const priceToNumber = parseFloat(productInfo.price);
    const zipCodeToNumber = zipToNumber(productInfo.zipcode);

    if (isValidZipCode(productInfo.zipcode)) {
      saveZipToLocal(productInfo.zipcode);
    }

    if (productInfo.title.split(' ').join('').length < 3) {
      badInputMessage('Please enter a valid title.\n');
      highlightInputRed(titleInput);
      changeButtonClicked(false);
    } else if (filterWords.isProfane(productInfo.title)) {
      badInputMessage('Please remove profanity from the title.\n');
      highlightInputRed(titleInput);
      changeButtonClicked(false);
    } else if (productInfo.price === '' || isNaN(priceToNumber)) {
      badInputMessage('Please enter a valid price. Enter a number value.\n');
      highlightInputRed(priceInput);
      changeButtonClicked(false);
    } else if (productInfo.zipcode === '' || isNaN(zipCodeToNumber)) {
      badInputMessage('Zipcode can only be a number, please try again.\n');
      highlightInputRed(zipcodeInput);
      changeButtonClicked(false);
    } else if (!isValidZipCode(productInfo.zipcode)) {
      badInputMessage(
        `Product must be in ${formattedLocation}. Please enter a valid ${formattedLocation} zipcode.\n`
      );
      highlightInputRed(zipcodeInput);
      changeButtonClicked(false);
    } else if (
      userLocation == USER_LOCATIONS.VetAdventureGroup &&
      productInfo.zipcode != '97701'
    ) {
      badInputMessage('Incorrect zipcode for this group.');
      highlightInputRed(zipcodeInput);
      changeButtonClicked(false);
    } else if (filterWords.isProfane(productInfo.description)) {
      badInputMessage('Please remove profanity from the description.\n');
      highlightInputRed(descriptionInput);
      changeButtonClicked(false);
    } else if (!productInfo.condition) {
      badInputMessage('Please input rental instructions.\n');
      highlightInputRed(conditionInput);
      changeButtonClicked(false);
    } else if (filterWords.isProfane(productInfo.condition)) {
      badInputMessage(
        'Please remove profanity from the rental instructions.\n'
      );
      highlightInputRed(conditionInput);
      changeButtonClicked(false);
    } else if (productInfo.product_type === '') {
      badInputMessage('Please select a product type.\n');
      highlightSelectRed('product_type');
      changeButtonClicked(false);
    } else if (productInfo.quality === '') {
      badInputMessage('Please select a product quality.\n');
      highlightSelectRed('quality');
      changeButtonClicked(false);
    } else if (images.length == 0) {
      badInputMessage('Please upload an image for your gear.\n');
      // highlightInputRed(imageFileInput);
      changeButtonClicked(false);
    } else if (!isVerifiedNotRobot) {
      badInputMessage('Please verify that you are human.\n');
      changeButtonClicked(false);
    } else {
      const formData = new FormData();
      formData.append('title', productInfo.title);
      formData.append('price', parseInt(productInfo.price));
      formData.append('duration', productInfo.duration);
      formData.append('description', productInfo.description);
      formData.append('quality', productInfo.quality);
      formData.append('quantity', parseInt(productInfo.quantity));
      formData.append('condition', productInfo.condition);
      formData.append('zipcode', productInfo.zipcode);
      formData.append('region', userLocation);
      formData.append('product_type', productInfo.product_type);
      formData.append('verify_token', isVerifiedNotRobot);
      for (let i = 0; i < images.length; ++i) {
        // console.log(images[i].imageFile.size);
        formData.append('image_path', images[i].imageFile, images[i].imagePath);
      }

      try {
        productManager.postSellableProduct(formData).then((productReturn) => {
          if (productReturn.id) {
            routerProps.history.push(`/rent/${productReturn.id}`);
          } else {
            // Add alert here
            window.alert('Error in uploading gear');
            changeButtonClicked(false);
          }
        });
      } catch (e) {
        window.alert(`The following error has occured, ${e}`);
        changeButtonClicked(false);
      }
    }
  };

  function buttonOrLoader() {
    if (buttonClicked) {
      return <Spin size="small" />;
    } else {
      return (
        <input
          className="btn"
          type="button"
          name=""
          value="Submit"
          onClick={handleSubmit}
        />
      );
    }
  }

  const getProductTypes = () => {
    productManager.getProductTypes().then((productTypes) => {
      setProductTypes(productTypes);
    });
  };

  useEffect(() => {
    getProductTypes();
    getCurrentMember();
  }, []);
  useEffect(() => {
    buttonOrLoader();
  }, [buttonClicked]);

  if (!member) {
    return <GearLoader />;
  } else if (member.stripe_account_authorized) {
    return (
      <>
        <h3
          style={{
            width: '100%',
            textAlign: 'center',
            padding: '10px',
            backgroundColor: '#78a9f5',
            color: 'white',
          }}
        >
          Region: {formattedLocation}
        </h3>
        <div id="lendingForm">
          <form onSubmit={handleSubmit}>
            <h1 className="headline-font" style={{ color: 'black' }}>
              {' '}
              Lend Some Gear{' '}
            </h1>

            <fieldset>
              <section className="selectedImages">
                {images.length
                  ? images.map((image, index) => {
                      return (
                        <div key={`image--${image.imagePath}`}>
                          <IconButton
                            onClick={() => removeImage(image)}
                            sx={{
                              display: { top: -80, left: 40 },
                            }}
                          >
                            <RemoveCircle />
                          </IconButton>
                          <img src={image.imagePreview} key={index} />
                        </div>
                      );
                    })
                  : null}
                {images.length < MAXIMUM_FILE_UPLOAD ? (
                  <EmptyProductImage />
                ) : null}
              </section>
              <input
                onChange={handleFileUpload}
                type="file"
                accept="image/*"
                multiple
                id="image_path"
                className=""
                ref={imageFileInput}
                style={{ display: 'none' }}
                required
              />
              {images.length < MAXIMUM_FILE_UPLOAD ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', padding: 4 }}
                >
                  <label htmlFor="image_path" className="upload-image-btn">
                    Add Photos
                  </label>
                </div>
              ) : null}
            </fieldset>
            <fieldset className="fieldset-ant-parent">
              <h3 className="body-font lend-field-header">Item Title</h3>
              <Input
                onChange={handleFieldChange}
                type="text"
                id="title"
                multiple
                ref={titleInput}
                className=""
                placeholder="Max title 50 char"
                value={productInfo.title}
                maxLength="50"
                required
              />
            </fieldset>
            <h3 className="body-font lend-field-header">Price</h3>
            <fieldset
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Input
                onChange={handleFieldChange}
                type="number"
                id="price"
                ref={priceInput}
                className=""
                placeholder=" ex. 50.00"
                min="0"
                step="1"
                value={productInfo.price}
                required
                addonBefore="$"
              />
              <label className="body-font" style={{ padding: '0 14px' }}>
                {' '}
                Per Day
              </label>
            </fieldset>
            <fieldset>
              <h3 className="body-font lend-field-header">Zip Code</h3>
              <Input
                onChange={handleFieldChange}
                type="text"
                id="zipcode"
                ref={zipcodeInput}
                className=""
                placeholder="Zipcode"
                value={productInfo.zipcode}
                required
                maxLength={5}
              />
            </fieldset>
            <fieldset>
              <h3 className="body-font lend-field-header">Description</h3>
              <TextArea
                onChange={handleFieldChange}
                type="text"
                id="description"
                ref={descriptionInput}
                className=""
                placeholder="Max description 1000 char"
                value={productInfo.description}
                required
                maxLength="1000"
                rows={4}
              />
            </fieldset>
            <fieldset id="rental-instructions">
              <h3 className="body-font lend-field-header">
                Rental Instructions
              </h3>
              <TextArea
                onChange={handleFieldChange}
                type="text"
                id="condition"
                ref={conditionInput}
                className=""
                placeholder={rentalInstructions}
                value={productInfo.condition}
                maxLength="1000"
                required
                rows={2}
              />
            </fieldset>

            <fieldset className="lend-field-header dropdownContainer">
              <h3 className="body-font dropdownTitle">Product Types:</h3>
              <select
                className="select dropdown ant-input"
                id="product_type"
                ref={productTypeInput}
                onChange={handleFieldChange}
                required
                value={productInfo.product_type || ''}
              >
                {/* Value must be set to empty string to know it has not been selected */}
                <option disabled={true} value="">
                  Select
                </option>
                {productTypes.map((productType) => {
                  return (
                    <option key={productType.id} value={productType.id}>
                      {productType.name}
                    </option>
                  );
                })}
              </select>
            </fieldset>

            <fieldset className="lend-field-header dropdownContainer">
              <h3 className="body-font dropdownTitle">Quality:</h3>
              <select
                onChange={handleFieldChange}
                type="text"
                id="quality"
                ref={qualityInput}
                className="dropdown ant-input"
                placeholder="Quality"
                value={productInfo.quality}
                maxLength="75"
                required
              >
                <option disabled={true} selected value="">
                  Select
                </option>
                <option selected value="New">
                  New
                </option>
                <option value="Like New">Like New</option>
                <option value="Gently Used">Gently Used</option>
                <option value="Used">Used</option>
                <option value="Heavily Used">Heavily Used</option>
              </select>
            </fieldset>
            <div className="notARobot">
              <Recaptcha
                className="notARobot lend-field-header"
                sitekey="6LerR4AaAAAAAP69VAyWuxgBxpgDBMZW7C2-Kdj6"
                render="explicit"
                verifyCallback={verifyHuman}
                onloadCallback={recaptchaLoaded}
              />
            </div>
            <fieldset>{buttonOrLoader()}</fieldset>
          </form>
        </div>
        <div id="lenderFaqs-container">
          <div id="lenderFaqs">
            <hr />
            <h1>Lender FAQs</h1>
            <Collapse>
              <Panel id="question" header='Can I set my own “terms of use"?'>
                <div className="answer">
                  <p>
                    Yes, you can determine price, how long to rent an item, and
                    how the item should be used. You will also determine the
                    lending / return process (whether at your house, Target
                    parking lot, etc.).{' '}
                  </p>
                </div>
              </Panel>

              <Panel id="question" header="What if someone breaks my stuff?">
                <div className="answer">
                  <p>
                    Gear2Go will guarantee you a full-refund of the value of
                    your item should the renter damage or break your equipment.
                    (The founders ask that you do not take advantage by claiming
                    items to be broken if they were not, the company has yet to
                    raise a round of funding!)
                  </p>
                </div>
              </Panel>

              <Panel
                id="question"
                header="Will I be held liable if someone becomes injured using my equipment?"
              >
                <div className="answer">
                  <p>
                    No, you will not be held liable if someone injures
                    themselves using your equipment. By agreeing to the terms of
                    service, renters agree to participate at their own risk.
                  </p>
                </div>
              </Panel>
              <Panel
                id="question"
                header="What if I break the lender’s equipment?"
              >
                <div className="answer">
                  <p>
                    If the item breaks while you have it, please proactively
                    contact the Gear2Go team. Our team will review situations on
                    a case-by-case basis to determine liability and payment. If
                    we determine you broke the item due to negligence, we will
                    request you pay the replacement amount. Our community is
                    based on trust; please let us know if you break something!
                  </p>
                  <p>
                    If the item broke due to normal wear and tear or was broke
                    prior to rental, don't worry, you won't be held responsible
                    for repairs.
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <section id="creationForm" className="register-box">
        <LenderCreateStripeAccount currentMember={member} url={url} />
      </section>
    );
  }
};

export default Sell;
