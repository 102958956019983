import React, { useState } from 'react';
import ImageElement from './ImageElement.js';
import './ProductImage.css';

//https://www.w3schools.com/howto/howto_js_slideshow.asp
export default function ProductSlideshow(props) {
  const { productImages, productDetail, routePath } = props;

  let [currentSlide, setCurrentSlide] = useState(0);
  const handleChangeImage = (event, key) => {
    event.stopPropagation();
    setCurrentSlide(key);
  };

  if (productImages) {
    return (
      <div className="slideshowContainer" onClick={routePath}>
        {productImages.map((productImage, key) => {
          return (
            <div
              className={`mySlides fade ${
                currentSlide == key ? 'show' : 'hide'
              }`}
              key={key}
            >
              <ImageElement
                imageSrc={productImage}
                isProductDetail={productDetail}
              />
            </div>
          );
        })}

        <div
          className={`slideIndicator ${
            productImages.length > 1 ? 'show' : 'hide'
          }`}
        >
          {productImages.map((productImage, key) => {
            return (
              <span
                className={`dot ${productDetail ? 'dot-detail' : ''} ${
                  currentSlide == key ? 'active' : ''
                }`}
                key={key}
                onClick={(e) => handleChangeImage(e, key)}
              ></span>
            );
          })}
        </div>
      </div>
    );
  }
  return <div></div>;
}
