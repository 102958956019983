import React from 'react';
import { Input, Switch } from 'antd';
import './searchNav.css';

const ProductNavbar = ({ changeSearchTerm, routerProps }) => {
  //   const { routerProps } = Input;

  function handleSearch(e) {
    if (window.location.pathname != '/rent') {
      changeSearchTerm(e.target.value);
      routerProps.history.push('/rent', changeSearchTerm(e.target.value));
    } else {
      changeSearchTerm(e.target.value);
      document.getElementById('productSearchBar').value = '';
    }
  }

  return (
    <div className="flexRow ">
      <input
        id="productSearchBar"
        className="productSearchBar"
        type="text"
        placeholder="search gear"
        onKeyUp={(e) => (e.keyCode == 13 ? handleSearch(e) : null)}
      />
    </div>
  );
};

export default ProductNavbar;
