import { useHistory } from 'react-router-dom';
import { Routes } from '../components/BodyRouter';

const useRouteOtherMember = () => {
  const history = useHistory();

  const routeOtherMember = (id) => {
    history.push(`/${Routes.OtherMember}/${id}`);
  };

  return { routeOtherMember };
};

export default useRouteOtherMember;
