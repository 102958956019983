import React from 'react';
import ImageGallery from 'react-image-gallery';

export const ProductDetailImages = ({ imagePaths }) => {
  const isMobileView = () => {
    if (window.innerWidth < 420) {
      return true;
    } else {
      return false;
    }
  };

  const prepImagesForGallery = () => {
    if (imagePaths) {
      const images = [];
      imagePaths.map((imagePath) => {
        const imageConfig = {
          original: imagePath,
          thumbnail: imagePath,
        };
        images.push(imageConfig);
      });
      return images;
    }
  };

  const setupImageGallery = () => {
    if (isMobileView() && imagePaths.length > 1) {
      return (
        <ImageGallery
          items={prepImagesForGallery()}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={false}
          showBullets={true}
          showNav={false}
        />
      );
    } else if (isMobileView()) {
      return (
        <ImageGallery
          items={prepImagesForGallery()}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={false}
          showBullets={false}
          showNav={false}
        />
      );
    } else if (imagePaths.length > 1) {
      return (
        <ImageGallery
          items={prepImagesForGallery()}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={true}
        />
      );
    } else {
      return (
        <ImageGallery
          items={prepImagesForGallery()}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={false}
        />
      );
    }
  };

  return (
    <div className="productDetails-imageComponent">{setupImageGallery()}</div>
  );
};
