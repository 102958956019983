import React, { useState, useEffect } from 'react';
import ReactMapGL, {
  NavigationControl,
  FullscreenControl,
  Marker,
  Source,
  Layer,
} from 'react-map-gl';
import GearBagPNG from '../../images/gearbag.png';
import geocodingFunctions from '../../modules/maps/geocoding';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass =
//   require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function DetailsMap({ zip }) {
  const [latLong, setLatLong] = useState({
    longitude: -122.431297,
    latitude: 37.773972,
  });
  const [loaded, setLoaded] = useState(false);

  const [viewport, setViewport] = useState({
    latitude: latLong['latitude'],
    longitude: latLong['longitude'],
    width: '25vw',
    height: '50vh',
    zoom: 10,
    margin: '30px auto',
  });

  const geojson = {
    type: 'FeatureCollection',
    geometry: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            [latLong[0], latLong[1]],
            [latLong[0], latLong[1] - 0.1],
            [latLong[0] - 0.1, latLong[1] - 0.1],
            [latLong[0] - 0.1, latLong[1]],
          ],
        },
      },
    ],
  };

  const layerStyle = {
    id: 'point',
    type: 'fill',
    paint: {
      'fill-color': 'rgba (17, 173, 17, 0.75)',
    },
  };

  useEffect(() => {
    if (zip) {
      geocodingFunctions.zipCodeToCoordinates(zip).then((coordObj) => {
        setLatLong(coordObj);
        setViewport({
          latitude: coordObj['latitude'],
          longitude: coordObj['longitude'],
          width: '50vw',
          height: '50vh',
          zoom: 10,
          margin: '30px auto',
        });
      });
    }
  }, [zip]);

  return (
    <ReactMapGL
      id="details-map"
      {...viewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAP_KEY}
      onViewportChange={(viewport) => {
        setViewport(viewport);
      }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
    >
      <div style={{ position: 'absolute', right: '10px', bottom: '25px' }}>
        <NavigationControl />
      </div>
      <div style={{ position: 'absolute', right: 0 }}>
        <FullscreenControl container={document.querySelector('#details-map')} />
      </div>
      <Marker latitude={latLong['latitude']} longitude={latLong['longitude']}>
        <div
          style={{
            backgroundColor: 'green',
            width: '80px',
            height: '80px',
            borderRadius: '100%',
            opacity: '75%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={GearBagPNG} style={{ width: '50px' }} />
        </div>
      </Marker>
      {/* <Source id="my-data" type="geojson" data={geojson}>
                <Layer {...layerStyle} />
            </Source> */}
    </ReactMapGL>
  );
}

export default DetailsMap;
