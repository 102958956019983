import { urlConditional } from './apiInfo';
import { getToken } from '../constants/localStorage';

const baseURL = urlConditional();

const EventAPI = {
  checkForAndClearEvent(request: { event_names: string[] }) {
    return fetch(`${baseURL}/clear-event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${getToken()}`,
      },
      body: JSON.stringify(request),
    }).then((response) => response.json());
  },
  setEvent(request: { event_name: string; email?: string }) {
    return fetch(`${baseURL}/set-event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${getToken()}`,
      },
      body: JSON.stringify(request),
    }).then((response) => response.json());
  },
};

export default EventAPI;
