import { useLocation } from 'react-router';
import parseUrlParams from '../helpers/parseUrlParams';

const useSearchParams = (defaults) => {
  const { search } = useLocation();

  return parseUrlParams(search, defaults);
};

export default useSearchParams;
