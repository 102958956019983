import React from 'react';
import './PricePerDuration.css';

export const Amount = ({ price }) => {
  return (
    <>
      <span className="dollar">$</span>
      <span className="price">{price}</span>
    </>
  );
};

const PricePerDuration = ({ price, duration }) => {
  return (
    <span className="money-flex">
      <Amount price={price} />
      <span className="slash"> / </span>
      <span className="duration">{duration || 'day'}</span>
    </span>
  );
};

export default PricePerDuration;
