import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import {
  MailOutlined,
  WarningOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import './Messages.css';
import Pubnub from 'pubnub';
import messagingAPI from '../../modules/messagingModules/messaging';
import ChannelsMGContainer from './ChannelsMGContainer';
import EmailLenderPopup from './EmailLenderPopup';
import ReserveGearButton from '../payments/ReserveGearButton';
import filterWords from '../../modules/filters/bad-words';
import ReportModal from '../safety/ReportModal';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import GearLoader from '../GearLoader';
import useRouteOtherMember from '../../helpers/routeOtherMember';
import InstructionsModal from './InstructionsModal';

const { TextArea } = Input;

function MessageMain({ routerProps }) {
  const historyPropMemberInfo = routerProps.history.location.state;
  const [cardClickRelObj, setCardClickRelObj] = useState(historyPropMemberInfo);
  // If hitsoryProp exists, this will be set in the channelCreate function
  // Otherwise the selected channel will be the one modified most recently
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [otherMember, setOtherMember] = useState(null);
  const [otherMemberId, setOtherMemberId] = useState(null);
  const [currentMember, setCurrentMember] = useState(null);
  const [channelsMemberGroups, setChannelsMemberGroups] = useState(null);
  const [messages, setMessages] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [productSelected, setProductSelected] = useState(null);
  const [messageSent, setMessageSent] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(null);
  const { memberId } = useLoggedInMemberInfo();

  function createChannelName() {
    if (cardClickRelObj) {
      const sortedIds = [
        parseInt(cardClickRelObj.memberId),
        cardClickRelObj.lenderId,
      ].sort(function (a, b) {
        return a - b;
      });
      const channelName = `channel-${sortedIds[0]}-${sortedIds[1]}`;
      setSelectedChannel(channelName);
      return channelName;
    }
  }

  const pubnub = new Pubnub({
    publishKey: 'pub-c-9b89ee9b-b537-47e1-963e-e5e4fb837cf1',
    subscribeKey: 'sub-c-ed820248-53c8-11eb-9ec5-221b84410db5',
    uuid: memberId,
  });

  const messagingAPICalls = {
    getMessageGroups() {
      if (cardClickRelObj) {
        const channelName = createChannelName();
        messagingAPI
          .createMessageGroup({
            channel: channelName,
            product_id: historyPropMemberInfo.product_id,
          })
          .then((resp) => {
            setCardClickRelObj(null);
            return messagingAPI.getMessageGroups().then((resp) => {
              return setChannelsMemberGroups(resp);
            });
          });
      } else {
        messagingAPI.getMessageGroups().then((resp) => {
          if (resp[0]) {
            setSelectedChannel(resp[0].name);
            setChannelsMemberGroups(resp);
          } else {
            setSelectedChannel('no-groups');
            setChannelsMemberGroups('no-groups');
            setChannelsMemberGroups('no-groups');
          }
        });
      }
    },
    getMessages() {
      messagingAPI.getMessages(selectedChannel).then((resp) => {
        if (resp.length > 0) {
          setMessages(resp);
        } else {
          setMessages('no-messages');
        }
      });
    },
    saveAndSendMessage(messageToSend) {
      const newMessage = messageToSend;

      if (newMessage.split(' ').join('').length > 1) {
        messagingAPI
          .createMessage({ channel: selectedChannel, message: messageToSend })
          .then((resp) => {
            // console.log('HERE IS RESPONSE FROM BACKEND MESSAGE:\n\n', resp);
            if (typeof resp == 'string') {
              resp = JSON.parse(resp);
              if (resp.blocked) {
                setBlockedStatus(resp.status);
              }
            } else if (resp.id) {
              // setMessageSent(true)
              pubnub.publish(
                {
                  channel: selectedChannel,
                  message: {
                    text: newMessage,
                    description: {
                      first_name: currentMember.user.first_name,
                      member_id: memberId,
                      message_id: resp.id,
                    },
                  },
                },
                function (status, response) {
                  // Handle error here
                }
              );
            }
          });
      }
    },
  };

  const { routeOtherMember } = useRouteOtherMember();
  function createMessageCards() {
    if (messages === 'change-channel') {
      return null;
    } else if (messages && messages !== 'no-messages') {
      return messages.map((message) => {
        const messageClass =
          memberId == message.member_id ? 'my-message' : 'other-message';

        if (message.id.toString().includes('pub') && newMessage) {
          return (
            <p key={message.id} className={`${messageClass} indv-message`}>
              <strong
                className={`${messageClass}-name`}
                onClick={() =>
                  messageClass !== 'my-message'
                    ? routeOtherMember(otherMemberId)
                    : routerProps.history.push('/myprofile', memberId)
                }
              >
                {messageClass !== 'my-message' ? message.first_name : 'Me'}:
              </strong>{' '}
              {filterWords.cleanHacked(message.message)}
            </p>
          );
        } else {
          return (
            <p key={message.id} className={`${messageClass} indv-message`}>
              <strong
                className={`${messageClass}-name`}
                onClick={() =>
                  messageClass !== 'my-message'
                    ? routeOtherMember(otherMemberId)
                    : routerProps.history.push('/myprofile', memberId)
                }
              >
                {messageClass !== 'my-message'
                  ? message.member
                    ? message.member.user.first_name
                    : message.first_name
                  : 'Me'}
                :
              </strong>{' '}
              {filterWords.cleanHacked(message.message)}
            </p>
          );
        }
      });
    } else if (messages == 'no-messages' && historyPropMemberInfo) {
      return (
        <div
          style={{
            width: '50%',
            minWidth: '280px',
            margin: 'auto',
            textAlign: 'left',
          }}
        >
          <h3 style={{ fontWeight: 'bold' }}>
            <strong>You’re almost there!</strong> Message the lender to request
            to rent their gear. Include the below information to start your
            conversation:
            <br />
            <br />
            <strong>• Name of the item you want to rent</strong>
            <br />
            <strong>• The dates of your requested rental </strong>
            <br />
            <strong>• Preferred pick up/drop off times and location </strong>
            <br />
            <br />
            Remember, do not proceed to checkout until the lender has responded
            to confirm they can accommodate your request!
          </h3>
        </div>
      );
    } else if (messages == 'no-messages') {
      return (
        <p style={{ textAlign: 'center' }}>
          No messages yet. Reach out and send one!
        </p>
      );
    } else {
      return <GearLoader />;
    }
  }

  function messageContainerStyler() {
    if (blockedStatus) {
      return { backgroundColor: 'black', opacity: 0.5 };
    } else {
      return {};
    }
  }

  useEffect(() => {
    messagingAPICalls.getMessageGroups();

    // checking to see if came in through direct message
    const params = new URLSearchParams(window.location.search);
  }, []);
  useEffect(() => {
    if (newMessage) {
      if (newMessage.member_id != memberId) {
        messagingAPI.markMessageRead(newMessage['message_id']);
      }
      // console.log('Here is a new message!', newMessage);
      if (messages === 'no-messages') {
        const newMessageCopy = newMessage;
        setMessages([newMessageCopy]);
        setNewMessage(null);
      } else if (messages) {
        const newMessageCopy = newMessage;
        const messageCopy = [newMessageCopy, ...messages];
        setMessages(messageCopy);
        setNewMessage(null);
      }
    }
  }, [newMessage]);

  useEffect(() => {
    if (selectedChannel && selectedChannel !== 'no-groups') {
      messagingAPICalls.getMessages(selectedChannel);
    } else {
      setMessages('no-messages');
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (messages) {
      createMessageCards();
    }
  }, [messages]);

  useEffect(() => {
    if (channelsMemberGroups && channelsMemberGroups !== 'no-groups') {
      const otherMember =
        channelsMemberGroups[0].member1_id == memberId
          ? channelsMemberGroups[0].member2
          : channelsMemberGroups[0].member1;
      const otherMemberId =
        channelsMemberGroups[0].member1_id == memberId
          ? channelsMemberGroups[0].member2_id
          : channelsMemberGroups[0].member1_id;
      const currentMember =
        channelsMemberGroups[0].member1_id == memberId
          ? channelsMemberGroups[0].member1
          : channelsMemberGroups[0].member2;
      otherMember.id = otherMemberId;
      setOtherMember(otherMember);
      setOtherMemberId(otherMemberId);
      setCurrentMember(currentMember);

      const channelNamesArray = channelsMemberGroups.map(
        (channel) => channel.name
      );

      pubnub.subscribe({
        channels: channelNamesArray,
      });
      pubnub.addListener({
        message: function (event) {
          setNewMessage({
            first_name: event.message.description.first_name,
            member_group: selectedChannel,
            message: event.message.text,
            message_id: event.message.description.message_id,
            id: `pub-${event.timetoken}`,
            member_id: event.message.description.member_id,
          });
        },
      });
    }
  }, [channelsMemberGroups]);

  // useEffect(()=>{
  //     // pubnub.addListener({
  //     //     message: function(event) {
  //     //         console.log('listener running', event);
  //     //         let pElement = document.createElement('p');
  //     //         pElement.appendChild(document.createTextNode(event.message.text));
  //     //         document.getElementById('message-container').appendChild(pElement);
  //     //     }
  //     // });

  // },[])

  function slideChannelsContainer() {
    var channelsContainer = document.getElementById('channels-mg-container');
    const slideStatus =
      channelsContainer.classList.contains('channels-slide-in');

    channelsContainer.classList.remove(
      slideStatus ? 'channels-slide-in' : 'channels-slide-out'
    );
    channelsContainer.classList.add(
      slideStatus ? 'channels-slide-out' : 'channels-slide-in'
    );
  }

  return (
    <>
      <section id="main-messages-container">
        <ChannelsMGContainer
          channelsMemberGroups={channelsMemberGroups}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          setOtherMember={setOtherMember}
          setOtherMemberId={setOtherMemberId}
          setCurrentMember={setCurrentMember}
          setProductSelected={setProductSelected}
          setBlockedStatus={setBlockedStatus}
          slideChannelsContainer={slideChannelsContainer}
        />
        <div id="message-right-container">
          <article className="showing-on-mobile message-back-button-container">
            <div
              className="message-back-button"
              onClick={slideChannelsContainer}
            >
              <ArrowLeftOutlined /> Messages
            </div>
          </article>
          <article id="messages-container" style={messageContainerStyler()}>
            {createMessageCards()}
          </article>
          <MessageInput
            productSelected={productSelected}
            messagingAPICalls={messagingAPICalls}
            otherMember={otherMember}
            currentMember={currentMember}
            selectedChannel={selectedChannel}
            blockedStatus={blockedStatus}
            setBlockedStatus={setBlockedStatus}
            newMessage={newMessage}
            routerProps={routerProps}
            historyPropMemberInfo={historyPropMemberInfo}
          />
        </div>
      </section>
    </>
  );
}

function MessageInput({
  messagingAPICalls,
  otherMember,
  currentMember,
  selectedChannel,
  blockedStatus,
  setBlockedStatus,
  newMessage,
  routerProps,
  historyPropMemberInfo,
}) {
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [messageInput, setMessageInput] = useState('');

  const handleInputFieldChange = (evt) => {
    const value = evt.target.value;
    setMessageInput(value);
  };

  function toggleReportModal() {
    setIsReportModalVisible(!isReportModalVisible);
  }

  function toggleEmailModal() {
    setIsEmailModalVisible(!isEmailModalVisible);
  }

  function emailOptionFunction() {
    if (otherMember) {
      return (
        <>
          <Button
            onClick={toggleEmailModal}
            style={{ position: 'relative', margin: 0 }}
          >
            <MailOutlined />
          </Button>
          <EmailLenderPopup
            toggleEmailModal={toggleEmailModal}
            isEmailModalVisible={isEmailModalVisible}
            otherMember={otherMember}
          />
        </>
      );
    }
  }

  function blockedRelationshipConditional() {
    const blockedStatement = () => {
      if (blockedStatus == 'both' || blockedStatus == 'blocking') {
        return `You have blocked ${otherMember.user.first_name}.`;
      } else if (blockedStatus == 'blocked') {
        return `You have been blocked by ${otherMember.user.first_name}`;
      }
    };

    if (blockedStatus) {
      return <p>{blockedStatement()}</p>;
    } else if (blockedStatus !== null) {
      return (
        <>
          {currentMember ? (
            <ReserveGearButton
              currentMember={currentMember}
              otherMember={otherMember}
              channelName={selectedChannel}
              routerProps={routerProps}
            />
          ) : (
            <div></div>
          )}
          {/* {emailOptionFunction()} */}
        </>
      );
    }
  }

  function disabledConditional() {
    if (!otherMember || blockedStatus || otherMember.user.is_active == false) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    emailOptionFunction();
  }, [otherMember]);

  useEffect(() => {
    if (
      otherMember?.id &&
      historyPropMemberInfo?.lenderId &&
      otherMember?.id !== historyPropMemberInfo?.lenderId
    )
      setMessageInput('');
  }, [historyPropMemberInfo, otherMember, selectedChannel]);

  useEffect(() => {
    setMessageInput('');
  }, [selectedChannel]);

  return (
    <div id="message-input-container">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {blockedRelationshipConditional()}
        <InstructionsModal />
        <Button
          style={
            blockedStatus === null
              ? { display: 'none', margin: 0 }
              : { margin: 0 }
          }
          onClick={toggleReportModal}
          danger
        >
          <WarningOutlined />
        </Button>

        <ReportModal
          toggleReportModal={toggleReportModal}
          isReportModalVisible={isReportModalVisible}
          setIsReportModalVisible={setIsReportModalVisible}
          otherMember={otherMember}
          currentMember={currentMember}
          blockedStatus={blockedStatus}
          setBlockedStatus={setBlockedStatus}
          newMessage={newMessage}
        />
      </div>
      <div
        className="message-input-and-send"
        // style={{minWidth: '320px', margin: '10px 0px'}}
      >
        <TextArea
          id="message-input"
          disabled={disabledConditional()}
          onChange={handleInputFieldChange}
          onKeyUp={(e) => {
            if (e.key === 'Enter' && messageInput) {
              messagingAPICalls.saveAndSendMessage(messageInput);
              setMessageInput('');
            }
          }}
          value={messageInput}
          placeholder="Hey nice gear!"
        />
        <Button
          id="submit"
          disabled={disabledConditional()}
          onClick={() => {
            if (messageInput) {
              messagingAPICalls.saveAndSendMessage(messageInput);
              setMessageInput('');
            }
          }}
        >
          Send
        </Button>
      </div>
    </div>
  );
}

export default MessageMain;
