import React from 'react';
import CompanyEmail from '../CompanyEmail';

export default function TOS() {
  return (
    <div
      style={{
        width: '80%',
        minWidth: '280px',
        maxWidth: '800px',
        margin: '20px auto',
      }}
    >
      <h1 style={{ textAlign: 'center' }}>GEAR2GO, INC.</h1>
      <h2 style={{ textAlign: 'center' }}>TERMS AND CONDITIONS</h2>
      <h4 style={{ textAlign: 'center' }}>Last Updated: February 12, 2022</h4>
      <h3 style={{ margin: '25px auto' }}>
        IT IS IMPORTANT THAT YOU READ CAREFULLY AND UNDERSTAND THESE TERMS AND
        CONDITIONS (THIS “AGREEMENT”). YOU MUST CLICK THE “I ACCEPT” BUTTON
        BELOW IN ORDER TO CREATE AN ACCOUNT TO BE ABLE TO USE THE SOFTWARE AND
        SERVICES PROVIDED BY GEAR2GO, INC. BY CLICKING THE “I ACCEPT” BUTTON
        BELOW, YOU ARE INDICATING THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND
        THE TERMS AND CONDITIONS OF THIS AGREEMENT, AND AGREE TO BE BOUND BY
        THIS AGREEMENT. IF YOU DO NOT AGREE WITH ALL THE TERMS AND CONDITIONS OF
        THIS AGREEMENT AND DO NOT AGREE TO BE BOUND BY THEM, PLEASE CLICK THE
        “CANCEL” BUTTON. IF YOU DO NOT ACCEPT THIS AGREEMENT, YOU ARE NOT
        PERMITTED TO USE THE SOFTWARE AND SERVICES PROVIDED THROUGH THIS
        WEBSITE.
      </h3>
      <p>
        <strong>1. Binding Agreement.</strong> These terms and conditions (this
        "Agreement") is a legally binding agreement between you and Gear2Go,
        Inc., a Delaware corporation, its affiliates, and its and their
        respective agents and representatives (individually and collectively,
        "Company," "we," "our" or "us"), and applies to your use of the URL,
        http://www.gear2go.us, and all other websites, social media pages and
        accounts owned by the Company (individually and collectively, the
        "Websites"), and all mobile applications owned by the Company (together
        with the Websites, collectively, the "Company Sites"). Through the use
        of the Company Sites, the Company provides users the opportunity to take
        advantage of the various services offered by the Company, including,
        without limitation, the opportunity to borrow, lease or rent tools,
        equipment and other items (collectively, the "Services").
      </p>
      <p>
        <strong>2. Renters and Owners.</strong> The Company’s role in the
        transactions contemplated by this Agreement is that of a service
        provider that enables a user (the “Renter”) to borrow, lease or rent
        tools, equipment and other items (collectively, “Gear’) from another
        user (the "Owner") and to participate in the services and/or any other
        product offers offered on the Company Sites. All Gear that is borrowed,
        leased or rented through the Services is owned by the Owner, and not the
        Company. As a Renter, you acknowledge that you are borrowing, leasing or
        renting Gear directly from the Owner, and not the Company. As the Owner,
        you acknowledge that you lending, leasing or renting Gear directly to
        the Renter, and not the Company. Any Gear that a Renter borrows, leases
        or rents through the use of the Company Sites from an Owner must be for
        personal use only and not for re-borrowing, re-leasing or re-renting to
        a third party. The Company Sites and the Services may only be used to
        borrow, lease or rent Gear in the United States. Any request or offer to
        borrow, lease or rent Gear through the Company Sites and the Services is
        void where prohibited by applicable law.
      </p>
      <p>
        <strong>3. Eligibility.</strong>
      </p>
      <p>
        <strong>a. Registering.</strong> You must be at least eighteen (18)
        years of age to use the Company Sites and Services. To verify that you
        are at least eighteen (18) years of age, you may be required to enter
        your birth date prior to registering to use the Company Sites and
        Services. You represent and warrant that (i) you are at least eighteen
        (18) years of age. Your borrowing, lending, leasing or renting of Gear
        through use of the Company Sites and Services will not be private, and
        may be may accessible to other users of the Company Sites and Services.
        As required by the Communications Decency Act of 1996, the Company
        hereby notifies you that parental control protections (e.g., computer
        software, filtering services, etc.) are commercially available and may
        be used to assist with limiting access to the Company Sites to minors.
        Among the many companies that provide Internet blocking and screening
        software are CyberPatrol, NetNanny, SurfWatch and GuardOne. We do not
        sponsor or endorse any of these companies or their services. We may
        refuse to register any person or service any order at any time, at our
        sole discretion.
      </p>
      <p>
        <strong>4. Registration; Account.</strong>
        To use the Company Sites, you must obtain a personal account with the
        Company (an "Account"), follow the instructions provided by Company, and
        provide the requested personally identifiable information (which may
        include name, phone number, email address, date of birth, and, to be
        able to pay fees associated with use of the Services, method of payment
        information) (collectively, the "PII"). You represent and warrant that
        all PII you provide in connection with registering your Account is
        complete and accurate and that you are authorized to use the method of
        payment you provide in connection with your use of the Services. We
        reserve the right to contact the service provider of your method of
        payment to conduct periodic security checks. You acknowledge and agree
        that you may never use another customer’s Account. You also agree to
        review and update your Account as and when necessary to maintain the
        most current PII. You may use your Account to track your history of
        usage on the Company Sites.
      </p>
      <p>
        <strong>5. Borrowing, Lending, Leasing or Renting Gear.</strong>
      </p>
      <p>
        <strong>a. Requests to Borrow, Lease or Rent.</strong> ALL EQUIPMENT
        DISPLAYED ON THE COMPANY SITES ARE LOANED, LEASED OR RENTED BY OWNERS,
        AND NOT THE COMPANY. The Company only provides a platform that enables
        Owners and Renters to borrow and lend items, but does not borrow or lend
        items itself. The listing of items of Gear on the Company Sites is
        merely a solicitation of offers from Renters to borrow, lease or rent
        such Gear, and such listing alone does not constitute any offer from any
        Owner to lend, lease or rent such Gear. As such, each request to borrow,
        lease or rent Gear submitted through the Company Sites is subject to
        acceptance by the applicable Owner. The Owner of an item of Gear has the
        right to refuse to lend, lease or rent Gear for any reason. The Company
        never takes possession to any Gear listed through the Services, and
        thus, possession of all Gear borrowed, leased or rented through the
        Services transfers directly from the applicable Owner to the applicable
        Renter. All borrowing, leasing or renting of Gear through the Company
        Sites are subject to this Agreement. The Company does not guarantee the
        availability of any Gear displayed on the Company Sites and also does
        not regularly monitor the quality of the items made available for rent
        by Owners through the Company Sites. In the event that a particular item
        of Gear that a Renter requests to borrow, lease or rent is unavailable
        for borrowing, leasing or renting by the desired date, or in the event
        that a Renter does not take possession of a particular item of Gear upon
        the desired date, the transaction will not be fulfilled and the Renter’s
        method of payment will not be charged. The Company shall not be liable
        for any description or depiction of Gear, the condition of Gear, the
        fitness of Gear for any intended purpose, the loss of or damage to any
        Gear, or the acts or inaction of any Owner or Renter.
      </p>
      <p>
        <strong>b. Payment and Delivery Information.</strong> When you submit a
        request to borrow, lease or rent Gear through the Company Sites, you
        will be required to provide your PII (including your method of payment)
        together with your address and desired dates to take possession of the
        Gear and return the Gear to the Owner. The Company will not take
        possession of any Gear or otherwise facilitate the direct transfer of
        possession of Gear between an Owner and Renter. You expressly authorize
        us to use all information that you provide to us to communicate with
        Owners and Renters to facilitate the pairing of requests from Renters
        with applicable Owners.
      </p>
      <p>
        <strong>c. Platform Fee.</strong> You may be charged a fee for using the
        Company Sites and Services, to borrow, lease or rent Gear as a Renter
        and/or to lend, lease or rent Gear as an Owner. Any such fee is for use
        of the Company’s technology platform and access to the Content, and does
        not include any portion of the lease or rent payment of an item of Gear,
        even if the amount of such fee is based upon or determined by reference
        to the lease or rent payment of an item of Gear.
      </p>
      <p>
        <strong>6. Contact Information.</strong> If you have any requests,
        questions or comments about your use of the Services, your Account, your
        PII, the Company Sites, or any other questions or comments relating to
        our products and services, please contact us at:
      </p>
      <p>
        <strong>Email:</strong>
        <br />
        <CompanyEmail />
        <br />
        <br />
        <strong>Mail:</strong>
        <br />
        Gear2Go, Inc.
        <br />
        Attn: Customer Service
        <br />
        2326 Kay Dr.
        <br />
        Santa Clara, CA 95050
        <br />
        <br />
        When you send an email to us, you agree that we may electronically
        communicate with you. You acknowledge that communications by email may
        not be confidential.
      </p>
      <p>
        <strong>7. Transferring Possession of Gear.</strong> Owners and Renters
        assume full responsibility and liability for transferring possession of
        Gear between themselves (including sharing contact information, Gear
        pickup and return location information, and sharing any information
        regarding the proper care and use of Gear). An Owner reserves the right
        to reject any offer to borrow, lease or rent Gear up to the time when
        such Owner transfers possession of the Gear to a Renter. A Renter
        reserves the right to rescind any offer to borrow, lease or rent Gear up
        to the time when such Renter takes possession of the Gear from an Owner.
        Unless an Owner and a Renter make other arrangements between themselves,
        Renters assume the full risk of loss or damage to Gear that they borrow,
        lease or rent from Owners from and after the time the Renter takes
        possession of the Gear and until the time the Renter returns possession
        of the Gear to the Owner. The Renter will be responsible for any items
        the Renter rents through the Company Platform, including compensating
        the Owner for any loss or damage to those items. The Renter will also be
        responsible for any damage the Renter causes to other people or property
        when the Renter uses and is in possession of the rented items. The
        Renter shall return the rented items in the same condition as when the
        Renters collected the items from the Owner (any reasonable wear and tear
        excepted). If an Owner suffers harm from the loss of or damage to Gear
        while in the possession of a Renter, the Owner shall first seek
        compensation directly from the Renter for the amount of harm, giving the
        Renter not less than twenty-four (24) hours to respond. If, after such
        twenty-four (24) hour period such claim is not resolved to the Owner’s
        satisfaction, the Owner may escalate the claim to the Company by
        providing the Company with evidence of such loss or damage, and the
        Company shall provide the Renter with an opportunity to respond of not
        less than twenty-four (24) hours. If the Renter agrees to pay the amount
        of the Owner’s claim, or the Company determines in its sole discretion
        that the Renter is responsible for the amount of the Owner’s claim, the
        Company may collect the amount of such claim from the Renter, including
        by way of using any method of payment that the Renter has previously
        provided to the Company in connection with use of the Company Sites. The
        Renter agrees that the Company may recover from the Renter under any
        insurance policies that the Renter maintains and the Company may also
        pursue against the Renter any remedies it may have under applicable law.
        Each Renter and each Owner agrees to cooperate in good faith, provide
        any information that the Company requests, execute documents and take
        further reasonable actions in connection with Owner claims, claims under
        insurance policies or other claims related to the Renter’s or the
        Owner’s use of the Company Sites. The Company agrees to pay to the
        Owner, within seventy-two (72) hours of the Company’s receipt, any
        amounts that the Company collects in connection with the Owner’s claim
        for loss or damage to Gear. Notwithstanding any of the foregoing, the
        Company shall not be responsible for any loss or damage to Gear.
      </p>
      <p>
        <strong>8. Use of Equipment.</strong> The Renter agrees that:
      </p>
      <ul>
        <li>
          The Renter is legally allowed to use any item that the Renter borrows
          through the Company Platform.{' '}
        </li>
        <li>
          The Renter will comply with all applicable law when using the rented
          item.
        </li>
        <li>
          The Renter will use the equipment in a good and careful manner and
          will comply with (i) the Owner’s reasonable instructions provided in
          order to use the item safely, (ii) all of the manufacturer’s
          requirements and recommendations respecting the equipment and (iii)
          with any applicable law whether local state or federal, respecting the
          use of the equipment including but not limited to environmental and
          copyright law.
        </li>
        <li>
          The Renter will use the equipment for the purpose for which it was
          designed and note for any other purpose.
        </li>
        <li>
          Unless the Renter obtains the prior written consent of the Owner, the
          Renter will not alter, modify, or attach anything to the equipment
          unless the alteration, modification or attachment is easily removable
          without damaging the functional capabilities or economic value of the
          rented equipment.
        </li>
      </ul>
      <p>
        <strong>9. Promotional Offers.</strong> By creating an Account, you may
        be eligible to receive various benefits provided from time to time by
        the Company or its commercial partners, including, without limitation,
        receiving discounts, vouchers and promotional offers. You will be
        notified of these special offers through various channels of
        communication, including, without limitation, email and/or U.S. mail.
        Each such offer will apply only to those specific products or services
        advertised, and will be governed by this Agreement, as amended from time
        to time, and the applicable terms of such promotions as communicated to
        you. You will be required to input the applicable reference code
        included in the advertisement of the promotional offer prior to enjoying
        any benefits. A particular reference code may only be used once and may
        not be used in conjunction with any other discount, voucher or
        promotional offer unless the applicable advertisement provides
        otherwise. The value of any such offer is not refundable. All offers are
        subject to availability and the Company reserves the right to change
        discounts, vouchers or offers at any time. Some states do not permit us
        to honor certain discounts, vouchers or offers, and the Company extends
        such discounts, vouchers and offers only if they are not prohibited by
        law.
      </p>
      <p>
        <strong>10. Privacy Policy.</strong> Your use of the Company Sites is
        subject to our Privacy Policy that is accessible at the Company Sites,
        which we may amend from time to time, and you hereby agree to its terms.
      </p>
      <p>
        <strong>11. Customer Conduct.</strong> You agree not to engage in any of
        the following activities: (i) interfere with or inhibit the use of the
        Company Sites by other users; (ii) use the Company Sites for any
        commercial purposes (e.g., re-lending, re-leasing or re-renting Gear to
        others) or any purpose other than to lend, lease or rent Gear for
        yourself; (iii) create an account using an alter-ego or by impersonating
        a third party, or providing any other false information; (iv) create an
        account if you are under eighteen (18) years of age; (v) copy, reverse
        engineer, or attempt to derive the source code of any part of the
        Company Sites; (vi) post any comment or product review on the Company
        Sites that is private, obscene, vulgar, violent, harassing,
        discriminatory or defamatory; or (vii) use the Company Sites in a manner
        that would violate this Agreement or any applicable laws. We may
        investigate any reported violation of these restrictions or other
        provisions in this Agreement. If we determine that you have violated
        these restrictions, or other provisions of this Agreement, we may take
        any action that we deem appropriate and you potentially may be subject
        to liability. Such action may include, without limitation, issuing
        warnings, immediately terminating your Account, unilaterally removing
        posted content and/or reporting any activity that we suspect violates
        any law or regulation to appropriate law enforcement officials or
        regulators and, in doing so, we may disclose any relevant information to
        such officials or regulators, including, without limitation, your PII,
        IP address(es) and your Company Sites usage history.
      </p>
      <p>
        <strong>12. Intellectual Property.</strong> a. Access to Company Sites.
      </p>
      <p>
        <strong>a. Access to Company Sites.</strong> The Company grants you the
        limited right to access and make use of the Company Sites as a user. The
        Company Sites, including, without limitation, all text, graphics,
        images, logos, software, trademarks, service marks and trade names
        incorporated in the Company Sites (the "Content") are owned by or
        licensed to the Company. Unless authorized in writing and in advance by
        the Company or the applicable rights holder, you may not sell,
        reproduce, copy, distribute, modify, display, publicly perform or
        otherwise exploit the Content for any public or commercial purpose. No
        provisions of this Agreement shall be construed to convey any property
        right or license to the Content unless expressly provided.
      </p>
      <p>
        <strong>b. DMCA.</strong> We respect the intellectual property rights of
        others and require that our users do the same. If you believe your work
        has been copied in a manner that constitutes copyright infringement, or
        you believe your rights are otherwise infringed or violated by anything
        on the Company Sites, you must notify us and give us an opportunity to
        correct the problem before taking any other action by sending an email
        to the following address: customerservice@gear2go.us. The notification
        must include all of the following: (i) a physical or electronic
        signature of the owner of the right claimed to be infringed or the
        person authorized to act on the owner’s behalf; (ii) a description of
        the copyrighted work or other right you claim has been infringed or
        violated; (iii) information reasonably sufficient to locate the material
        in question on the Company Site; (iv) your name, address, telephone
        number and e-mail address; (v) a statement by you that you have a good
        faith belief that the disputed use is not authorized by the rightful
        owner, its agent or the law; and (vi) a statement by you, made under
        penalty of perjury, that the information in your notice is accurate and
        that you are the owner of the right claimed to be infringed or violated
        or are authorized to act on behalf of the owner.
      </p>
      <p>
        <strong>13. Third Party Servicers; Release.</strong> You may have
        contact or other interaction with third parties (e.g. Renters, Owners or
        other third parties) (“Third Parties”) in connection with your use of
        the Company Sites and you may be directed to Third Party websites from
        the Company Sites. You hereby acknowledge and agree that you are
        proceeding at your own risk and any such Third Parties are not under the
        control of the Company. The Company shall be in no way responsible or
        liable for any acts, omissions, breaches, representations or warranties
        made by any such Third Party, or for any losses, injuries, or other
        damages you incur in connection with your contact or other interaction
        with such Third Parties ("Third Party Claims"). YOU HEREBY RELEASE THE
        COMPANY, OUR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND STOCKHOLDERS
        FROM ANY AND ALL SUCH THIRD PARTY CLAIMS NOT ATTRIBUTABLE TO THE
        COMPANY’S GROSSLY NEGLIGENT ACTS.
      </p>
      <p>
        <strong>14. Disclaimer; Notice of Violations.</strong>
      </p>
      <p>
        <strong>a. "As Is."</strong> THE COMPANY SITES AND THE CONTENT ARE
        PROVIDED "AS IS", "AS AVAILABLE" AND WITHOUT WARRANTY OF ANY KIND,
        EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
        WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
        PARTICULAR PURPOSE AND ALL WARRANTIES THAT MAY BE IMPLIED BY ANY COURSE
        OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE HEREBY EXPRESSLY
        DISCLAIMED. YOUR USE OF THE COMPANY SITES IS SOLELY AT YOUR OWN RISK.
        THERE IS NO, AND NEVER HAS BEEN ANY, GUARANTEE THAT THE SERVICES OR ANY
        EQUIPMENT WILL MEET YOUR REQUIREMENTS, ARE DEFECT-FREE OR ERROR-FREE,
        RELIABLE, WITHOUT INTERRUPTION OR AVAILABLE AT ALL TIMES. THE COMPANY
        DOES NOT GUARANTEE THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
        THE SERVICES WILL BE EFFECTIVE, RELIABLE, ACCURATE OR MEET YOUR
        REQUIREMENTS. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY A
        COMPANY REPRESENTATIVE SHALL CREATE A WARRANTY. The Company Sites are
        subject to constant change. You will not be eligible for any
        compensation because you cannot use any part of the Company Sites or
        because of a failure, suspension or withdrawal of all or part of the
        Company Sites. The Company strives to provide complete, accurate,
        up-to-date information on the Company Sites. Unfortunately, despite
        those efforts, human or technological errors may occur. For example, the
        Company Sites may contain typographical mistakes, inaccuracies or
        omissions,some of which may relate to pricing and availability, and some
        information may not be complete or current. We reserve the right to
        correct any errors, inaccuracies or omissions, including after a request
        to borrow, lease or rent has been submitted, and to change or update
        information at any time without prior notice. In addition, you
        acknowledge that the particular technical specifications and settings of
        your computer and its display could affect the accuracy of its display
        of the colors and look of Gear listed on the Company Sites.
      </p>
      <p>
        <strong>b. Good Faith Complaince.</strong> The Company Sites may be
        accessed from many locations. You acknowledge and agree that consumer
        protection laws vary by location and that the Company has made a good
        faith attempt to comply with all such applicable laws. You agree that
        you will notify the Company if you believe that it is not in compliance
        with any laws and provide the Company with an opportunity to cure any
        alleged violations before invoking the Dispute Resolution mechanisms set
        forth in Section 22 (“Dispute Resolution”).
      </p>
      <p>
        <strong>15. Termination.</strong> The Company reserves the right to
        terminate your Account at any time, with or without cause or notice, and
        which shall be effective immediately. If you wish to terminate your
        Account, you may do so by contacting us using the contact information
        provided in Section 6 ("Contact Information") above. You acknowledge and
        agree that any platform fees or lease or rent payments that have become
        due and payable before such termination are non-refundable. Sections 1 –
        7 and 10 – 24 of this Agreement shall survive any such termination.
      </p>
      <p>
        <strong>16. Modification.</strong> The Company reserves the right to
        modify or augment any of the terms and conditions of this Agreement, at
        any time, at our sole discretion. You should periodically review this
        Agreement, accessible from the Company Sites, for any modifications,
        each time that you request to borrow, lend, lease or rent Gear. All
        modifications will become effective upon their posting on the Company
        Sites, but no modification will affect any loan, lease or rental of Gear
        made prior to the modifications becoming effective. Your continued use
        of the Company Sites or your Account following any modification will be
        confirmation of your acceptance of such changes.
      </p>
      <p>
        <strong>17. Indemnification of the Company.</strong> You agree to
        indemnify, defend and hold the Company and its employees, officers,
        directors, agents and stockholders harmless to the fullest extent
        permitted by law from and against any losses, costs, liabilities,
        settlements, fines, payments or damages (including reasonable attorneys’
        fees and costs) resulting from any claim, suit or proceeding, threatened
        or otherwise, at law or in equity made or brought against any of them,
        and caused by, arising out of, resulting from or attributable to: (i)
        your misuse of the Company Sites; (ii) anything you post to the Company
        Sites; (iii) a Renter’s borrowing, leasing or renting of Gear through
        the Services, (iv) an Owner’s lending, leasing or renting of Gear
        through the Services, (v) any loss of or damage to Gear loaned, leased
        or rented through the Services or any injury or damage to person or
        property as a result of Gear loaned, leased or rented through the
        Services, (vi) your violation of this Agreement; (vii) your violation of
        the rights of any third parties (including Third Parties); or (viii) use
        of the Company Sites by any other person accessing the Company Sites
        using a computer or Internet access account that you provided to such
        person.
      </p>
      <p>
        <strong>18. Indemnification of the Owner.</strong> Renters agree that it
        will indemnify and hold harmless the Owner against any and all claims,
        actions, suits, proceedings, damages and liabilities including
        attorneys’ fees and costs arising out of or related to the Renters use
        of the equipment.
      </p>
      <p>
        <strong>19. Limitation of Liability.</strong> In no event shall the
        Company’s liability, or the liability of our affiliates, officers,
        directors, stockholders, agents, vendors, or suppliers, for any and all
        claims relating to the use of the Services exceed the total amount of
        money that you paid us during the previous twelve (12) month period.
        UNDER NO CIRCUMSTANCES WILL THE COMPANY BE RESPONSIBLE FOR ANY DAMAGE,
        LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED
        ACCESS OR USE OF THE COMPANY PLATFORM OR YOUR ACCOUNT OR THE INFORMATION
        CONTAINED THEREIN. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
        THE COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS,
        MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE
        OF OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
        SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY
        INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE FAT LLAMA
        SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
        TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI) ANY
        ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS
        A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
        OTHERWISE MADE AVAILABLE THROUGH THE COMPANY WEBSITE OR COMPANY
        PLATFORM; AND/OR (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR
        ILLEGAL CONDUCT OF ANY THIRD PARTY. TO THE FULLEST EXTENT PERMITTED BY
        LAW, THE COMPANY, TOGETHER WITH ITS AFFILIATES, OFFICERS, DIRECTORS,
        STOCKHOLDERS, AGENTS, VENDORS AND SUPPLIERS, SHALL NOT BE LIABLE,
        WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY,
        STRICT LIABILITY OR ANY OTHER BASIS FOR ANY SPECIAL, INDIRECT,
        INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT
        NOT LIMITED TO, DAMAGES FOR LOST PROFITS, LOST DATA, GOODWILL USE, OR
        OTHER INTANGIBLE LOSSES OR ANY OTHER COMMERCIAL DAMAGES, OR LOSSES, EVEN
        IF THE COMPANY HAS KNOWLEDGE OF THE POSSIBILITY OF SUCH DAMAGES. If any
        jurisdiction does not allow the exclusion or the limitation of liability
        for consequential or incidental damages, in such jurisdiction, our
        liability, and the liability of our affiliates, officers, managers,
        agents, vendors, and merchants, shall be limited to the maximum extent
        permitted by law. FOR ANY RELEASES CONTAINED IN THESE TERMS, IF YOU ARE
        A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH
        SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR
        RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT
        THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER,
        WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR
        RELEASED PARTY."
      </p>
      <p>
        <strong>20. Governing Law.</strong> This Agreement and all issues
        collateral thereto shall be governed and construed in accordance with
        the laws of the State of California pertaining to contracts made and to
        be performed entirely therein and without regard to its conflict of laws
        principles.
      </p>
      <p>
        <strong>21. Notice for California Users.</strong> Under California Civil
        Code Section 1789.3, residents of California who use the Company Sites
        are entitled to know that they may file grievances and complaints with:
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs, in writing at 400 R
        Street, Suite 1080, Sacramento, California 95814, or by telephone at
        (916) 445-1254 or (800) 952-5210, or by email at dca@dca.ca.gov.
        <br />
      </p>
      <p>
        <strong>b.</strong> IF YOU AND WE ARE UNABLE TO RESOLVE OUR DISPUTE BY
        TALKING TO EACH OTHER, YOU AND WE EACH AGREE THAT WE WILL SUBMIT THE
        DISPUTE TO BINDING ARBITRATION, SUBJECT TO SECTION 22 BELOW. To the
        fullest extent permitted by law, any controversy or claim arising out of
        or relating to our relationship, this Agreement (including any dispute
        as to their breach, termination, enforcement, interpretation or
        validity), our Privacy Policy (including any dispute as to its breach,
        termination, enforcement, interpretation or validity), the Company
        Sites, any promotional offers or any product or service provided under
        or in connection with our relationship will be resolved exclusively by
        binding arbitration, subject only to Section 22 below. The arbitration
        shall be conducted before JAMS using a single arbitrator under the JAMS
        Comprehensive Arbitration Rules and Procedures (or their replacement)
        that are in effect at the time the arbitration is initiated and under
        the terms set forth in this Agreement. The arbitrator shall be chosen by
        mutual agreement from a slate of five (5) potential arbitrators proposed
        by either you or us and, if you and we are unable to agree, the
        arbitrator shall be chosen by the JAMS administrator. In the event of a
        conflict between the JAMS rules and this Agreement, this Agreement shall
        govern. Arbitration is not a court proceeding. The rules of arbitration
        differ from the rules of court and there is no judge or jury in an
        arbitration proceeding. YOU ARE HEREBY WAIVING YOUR RIGHT TO HAVE A JURY
        TRIAL OR TO GO TO COURT, other than as provided in Section 22 below. You
        may, in arbitration, seek any and all remedies otherwise available to
        you where you reside, other than as waived in this Agreement. You have a
        right to hire an attorney to represent you in arbitration. The
        arbitration shall permit the discovery of relevant information that is
        not privileged or protected.
      </p>
      <p>
        <strong>
          22. Dispute Resolution, including Arbitration and Class Action Waiver.
        </strong>
      </p>
      <p>
        <strong>a.</strong> You and we agree that in the event of any dispute
        between us, you and we will first try to resolve the dispute by talking
        with each other. Accordingly, neither you nor we may start a formal
        proceeding for at least sixty (60) days after one of us notifies the
        other in writing of a dispute. You will send your notice to us at the
        address provided in Section 6 ("Contact Information") above, and we will
        send our notice to you using the PII that you have provided to us. The
        notice must be sent by U.S. mail or private courier, with confirmation
        of delivery requested.
      </p>
      <p>
        <strong>b.</strong> The arbitration will be conducted at the JAMS office
        in or nearest to San Francisco, California, unless you and we mutually
        agree to a different location or to a telephonic arbitration. We shall
        evenly share the costs of the arbitration proceeding, including the
        arbitrator’s fees and case management fees. We will not, however, pay
        any portion of your attorney’s fees. To start an arbitration, you or we
        must do the following three things:
      </p>
      <ol style={{ listStyleType: 'lower-roman' }}>
        <li>
          Write a Demand for Arbitration. The demand must include a description
          of the dispute and the amount of damages or other relief sought. You
          can find a copy of a Demand for Arbitration at www.jamsadr.com.
        </li>
        <li>
          Send two (2) copies of the Demand for Arbitration, plus the
          appropriate case management fee and reference to this Agreement to:
          JAMS, Two Embarcadero Center, Suite 1500, San Francisco, CA
        </li>
        <li>
          Send one (1) copy of the Demand for Arbitration to the other party.
        </li>
      </ol>
      <p>
        <strong>c.</strong> The arbitrator’s decision shall be final and binding
        and may be enforced by any state or federal court that has jurisdiction;
        provided, however, that the arbitrator shall not have authority to make
        errors of law and any arbitration award may be challenged if the
        arbitrator does so. The arbitrator shall give a written statement of the
        disposition of each claim and the award shall provide a concise written
        statement of the essential findings and conclusions upon which it is
        based. You and we agree that the Federal Arbitration Act shall govern
        this agreement to arbitrate.
      </p>
      <p>
        <strong>d.</strong> You and we agree not to join or consolidate claims
        in arbitration by or against the other. You and we also agree that you
        and we will not be entitled to bring any claim as a representative
        member of a class or in a private attorney general capacity against the
        other, or against our service partners, Licensed Retailers, retailers,
        licensors, officers, directors, agents, employees, affiliates or
        predecessors. Accordingly, you and we agree that the JAMS Class Action
        Procedures (or their replacement) will not apply in any arbitration
        between us. YOU HEREBY WAIVE ANY RIGHT TO BRING A CLASS ACTION OR
        PRIVATE ATTORNEY GENERAL CLAIM. You and we retain our respective rights
        to make any complaint to regulatory agencies or governmental
        investigators. A court may sever any portion of this Section 22 that it
        may find to be unenforceable, except for the prohibition on class,
        representative or private attorney general proceedings. You understand
        and agree that the waivers of rights in this Section 22, including the
        waivers of the rights to bring a lawsuit in court and to assert a class
        action, are knowing and voluntary.
      </p>
      <p>
        <strong>e.</strong> In lieu of initiating an arbitration, you may
        instead choose in your sole discretion to file an individual action in a
        small claims court. If you do so, we do not agree to pay any of your
        fees or costs. The provisions of Section 22(c) above remain effective,
        even if you elect to file in a small claims court.
      </p>
      <p>
        <strong>23. Waiver of Statutory Claims.</strong> By agreeing to this
        Agreement, you are giving up statutory claims or rights that you may
        have under your state’s laws, including claims for certain forms of
        relief or for attorneys’ fees. You agree that your waiver of any such
        laws does not violate your state’s laws.
      </p>
      <p>
        <strong>24. Miscellaneous.</strong>
      </p>
      <p>
        <strong>a. Severability.</strong> In the event that any provision of
        this Agreement (except as otherwise provided in Section 22(c)) is
        declared void or unenforceable, such provision shall be deemed severed
        from this Agreement, and this Agreement shall otherwise remain in full
        force and effect unless its purpose cannot be effected absent the
        severed provision.
      </p>
      <p>
        <strong>b. Entire Agreement.</strong> This Agreement, our Privacy Policy
        and the terms and conditions that may be posted on the Company Sites or
        of any promotional offers we make and you accept ("Company Sites
        Agreements") constitute the entire agreement between you and us with
        respect to the subject matter hereof, and supersede all prior
        agreements, whether written or oral. You confirm that you have not
        relied on any representation except insofar as the same has expressly
        been made a representation in these Company Sites Agreements.
      </p>
      <p>
        <strong>c. Notices.</strong> Unless otherwise specified to the contrary
        herein, all notices due under this Agreement shall be in writing and
        shall be deemed to have been made or given when any such notice is
        received, if personally delivered or sent by certified or registered
        mail; when receipt is electronically confirmed, if sent by facsimile or
        email; or the day after it is sent, if sent for next day delivery by a
        recognized overnight courier service. Electronic notices to us shall be
        sent to the email address provided in Section 6 (Contact Information)
        above.
      </p>
      <p>
        <strong>d. Assignment.</strong> This Agreement is personal to you and
        your Account. You may not assign this Agreement without the prior
        written consent of the Company. The Company may assign any of its rights
        and delegate any of its duties hereunder at any time, without your
        consent, at its sole discretion. This Agreement will inure to the
        benefit of the Company’s successors, assigns and licensors.
      </p>
      <p>
        <strong>e. Relationship of the Parties.</strong> Nothing contained
        herein will be construed as creating any relationship of
        employer/employee, partnership, agency, joint venture, or otherwise
        between you and the Company, nor will this Agreement be construed as
        conferring on any party any express or implied right, power, or
        authority to enter into any agreement or commitment, express or implied,
        or to incur any obligation or liability on behalf of the other party.
      </p>
      <p>
        <strong>f. Force Majeure.</strong> The Company shall not be liable for
        performance of its obligations under this Agreement if it is prevented
        or delayed from performing such obligations as a result of (i) any
        provision of any present or future law or regulation of any state or of
        the United States, (ii) any act of God, war, civil unrest or emergency
        condition (including pandemic or material worsening of a pandemic), or
        (iii) any other circumstances beyond the reasonable control of the
        Company.
      </p>
      <p>
        <strong>g. Waiver.</strong> Any waiver of either party’s rights
        hereunder must be in writing. No waiver by either party of any breach or
        default of any of the provisions contained in this Agreement and
        required to be performed by the other party shall be construed as a
        waiver of any succeeding breach of the same or of any other provision.
      </p>
      <h3>
        <strong>PLEASE PRINT A COPY OF THIS AGREEMENT FOR YOUR RECORDS.</strong>{' '}
        If you are unable to print from your device, please contact the Company
        at <CompanyEmail /> to request a hard copy of this Agreement.
      </h3>
    </div>
  );
}
