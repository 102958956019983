import React from 'react';
import productManager from '../../modules/productManager';
import { Card, Tooltip } from 'antd';
import {
  UnorderedListOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import ProductSlideshow from '../productImage/ProductSlideshow';
import PricePerDuration from '../PricePerDuration';

const ProfileLenderProductCard = ({ product, setSelectedProductId }) => {
  const deleteGear = () => {
    if (window.confirm(`${product.title} will be deleted from your postings`)) {
      productManager.deleteGear(product.id).then(() => {
        window.location.reload();
      });
    }
  };

  function routePath() {
    window.location = `/rent/${product.id}`;
  }

  function handleEditProduct(productId) {
    setSelectedProductId(productId);
  }
  const handleEdit = (event, id) => {
    event.stopPropagation();
    handleEditProduct(id);
  };
  return (
    <Card
      className="productCard"
      onClick={routePath}
      style={{ cursor: 'pointer' }}
      actions={[
        <Tooltip placement="bottom" title="Edit" key="edit">
          <EditOutlined
            className="clickable"
            onClick={(event) => handleEdit(event, product.id)}
          />
        </Tooltip>,
        <Tooltip placement="bottom" title="Details" key="details">
          <UnorderedListOutlined className="clickable" onClick={routePath} />
        </Tooltip>,
        <Tooltip
          key="remove"
          onClick={deleteGear}
          placement="bottom"
          title="Delete Gear Posting"
        >
          <DeleteOutlined className="clickable" />
        </Tooltip>,
      ]}
    >
      <ProductSlideshow productImages={product.image_paths} />
      <ul style={{ textAlign: 'left', listStyleType: 'none' }}>
        <li>{product.title}</li>
        <li>
          <strong>Quality: </strong>
          {product.quality}
        </li>
        <li>
          <PricePerDuration price={product.price} />
        </li>
      </ul>
    </Card>
  );
};

export default ProfileLenderProductCard;
