const apiKey = 'd2bf08c0-60f5-11eb-a62a-f359ab4863b0';

const geocodingFunctions = {
  async zipCodeToCoordinates(zip) {
    const url = `https://app.geocodeapi.io/api/v1/search?apikey=${apiKey}&text=${zip}`;

    return await fetch(url)
      .then((resp) => resp.json())
      // Returns an array with lat and long
      .then((resp) => {
        if (resp.features.length > 1) {
          for (let result in resp.features) {
            // console.log(result, 'results*')
            // console.log('longitude', resp.features[result].geometry.coordinates[0])
            if (
              resp.features[result].geometry.coordinates[0] < -55 &&
              resp.features[result].geometry.coordinates[0] - 126
            ) {
              const coordinates = resp.features[result].geometry.coordinates;
              const coordinatesObj = {
                latitude: coordinates[1],
                longitude: coordinates[0],
              };
              return coordinatesObj;
            } else {
              continue;
            }
          }
        } else {
          const coordinates = resp.features[0].geometry.coordinates;
          const coordinatesObj = {
            latitude: coordinates[1],
            longitude: coordinates[0],
          };
          return coordinatesObj;
        }
      });
  },
};

export default geocodingFunctions;
