import React from 'react';

const ProductQuickInfo = ({ product, handleOnClick }) => {
  const { image_paths, title } = product;
  const productImage = image_paths[0] || '#';

  return (
    <div onClick={handleOnClick} className="hover-button tooltip">
      <span className="tooltiptext">Go to product page</span>
      <div className="flex-row">
        <div className="review-image">
          <img src={productImage} alt={title || 'No description available'} />
        </div>
        <div className="header" style={{ paddingLeft: 12 }}>
          {title}
        </div>
      </div>
    </div>
  );
};

export default ProductQuickInfo;
