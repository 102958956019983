import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { Button, Input, InputAdornment, TextField } from '@mui/material';
import rentRequestManager from '../../modules/rentRequestManager';
import Recaptcha from 'react-recaptcha';
import './RentRequest.css';
import { Link } from 'react-router-dom';

const RentRequestFormNew = ({ routerProps }) => {
  const [buttonClicked, changeButtonClicked] = useState(false);
  const [emailAddress, setEmailAddress] = useState({ email_address: '' });
  const [isVerifiedNotRobot, setVerifiedNotRobot] = useState(false);
  const [itemList, setItemList] = useState([
    { item: '', size: '', gender: 'N/A', quantity: '1' },
    { item: '', size: '', gender: 'N/A', quantity: '1' },
    { item: '', size: '', gender: 'N/A', quantity: '1' },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const filledItems = itemList.filter((e) => e['item']);

    if (!isVerifiedNotRobot) {
      window.alert(
        'Please verify that you are human by completing the captcha.'
      );
    } else if (
      !emailAddress.email_address.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      window.alert('Please enter a valid email address.');
    } else if (filledItems.length < 1) {
      window.alert('Please enter at least one item.');
    } else {
      const filledItems = itemList.filter((e) => e['item']);
      const requestInfos = filledItems.map((e) => {
        return {
          item_request: e.item,
          size: e.size,
          gender: e.gender,
          quantity: e.quantity,
          email_address: emailAddress.email_address,
          verify_token: isVerifiedNotRobot,
        };
      });

      return rentRequestManager.createRentRequests(requestInfos).then(
        (parsedResponse) => {
          changeButtonClicked(true);
        },
        (reason) => {
          window.alert(
            'An error occurred when processing your request! Please try again later.'
          );
        }
      );
    }
  };

  const indexToPlaceholder = (index, field) => {
    const placeholders = [
      { item: 'Ski Pants', size: 'Large' },
      { item: 'Snow Chains', size: 'Tire Width 195-265mm' },
      { item: 'Sleeping Bag' },
    ];
    if (index > 2) {
      return placeholders[0][field];
    }
    return placeholders[index][field];
  };

  const handleItemChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...itemList];
    list[index][name] = value;
    setItemList(list);
  };

  const handleItemAdd = () => {
    setItemList([...itemList, { item: '' }]);
  };

  const handleItemRemove = (index) => {
    const list = [...itemList];
    list.splice(index, 1);
    setItemList(list);
  };

  const handleEmailChange = (evt) => {
    const stateToChange = { ...emailAddress };
    stateToChange[evt.target.id] = evt.target.value;
    setEmailAddress(stateToChange);
  };

  function verifyHuman(response) {
    if (response) {
      setVerifiedNotRobot(response);
    }
  }

  if (!buttonClicked) {
    return (
      <div className="rentRequestFormContainer">
        <h3>
          Submit a request for gear on President's Day weekend (February 17-20)!{' '}
          <br />
          We'll match you with options by February 14.
        </h3>
        <div className="rentRequestEmailContainer">
          <Input
            id="email_address"
            onChange={handleEmailChange}
            type="text"
            placeholder="Email Address"
            name=""
            value={emailAddress.email_address}
            fullWidth
          />
        </div>

        {itemList.map((singleItem, index) => (
          <fieldset className="singleRentRequest" key={index}>
            <label className="rentRequestLabel"> Item: </label>
            <Input
              id="item"
              onChange={(e) => handleItemChange(e, index)}
              type="text"
              placeholder={indexToPlaceholder(index, 'item')}
              name="item"
              value={singleItem.item}
            />
            <label className="rentRequestLabel"> Size: </label>
            <Input
              id="size"
              onChange={(e) => handleItemChange(e, index)}
              type="text"
              placeholder={indexToPlaceholder(index, 'size')}
              name="size"
              value={singleItem.size}
            />
            <label className="rentRequestLabel"> Gender: </label>
            <select
              onChange={(e) => handleItemChange(e, index)}
              type="text"
              id="number"
              name="gender"
              value={singleItem.gender}
            >
              <option value="N/A">N/A</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
              <option value="Unisex">Unisex</option>
            </select>
            <label className="rentRequestLabel"> Quantity: </label>
            <select
              onChange={(e) => handleItemChange(e, index)}
              type="text"
              id="number"
              name="quantity"
              value={singleItem.quantity}
              required
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            {itemList.length - 1 === index && (
              <Button
                id="rentRequestAddButton"
                onClick={handleItemAdd}
                variant="contained"
              >
                Add More Items
              </Button>
            )}
            {itemList.length > 1 && (
              <Button
                id="rentRequestRemoveButton"
                onClick={() => handleItemRemove(index)}
                variant="contained"
              >
                Remove Item
              </Button>
            )}
          </fieldset>
        ))}
        <div className="rentRequestRobotContainer">
          <Recaptcha
            sitekey="6LerR4AaAAAAAP69VAyWuxgBxpgDBMZW7C2-Kdj6"
            render="explicit"
            verifyCallback={verifyHuman}
            onloadCallback={() => console.log('recaptcha loaded')}
          />
        </div>
        <div className="requestButtonContainer">
          <Button onClick={handleSubmit} variant="contained">
            Request Gear
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="rentRequestSubmittedContainer">
        <h3>
          Thank you for your request! Your response has been recorded. Gear2Go
          is working hard to find your gear on the Stanford campus. We will send
          you a list of items and lenders on February 14!
          <br />
          <Link to="/">Click here to go to the Gear2Go home page.</Link>
        </h3>
      </div>
    );
  }
};

export default RentRequestFormNew;
