import React, { useEffect, useState } from 'react';
import noImageGears from '../../images/noImageGears.png';

export default function ImageElement({ imageSrc, isProductDetail }) {
  const [imageLandscapeOrPortrait, setImageLandscapeOrPortraint] =
    useState(null);

  function testImageDimensions() {
    const img = new Image();
    img.src = imageSrc;
    img.onload = function () {
      let orientation = 'portrait';
      if (this.width > this.height) {
        orientation = 'landscape';
      }
      setImageLandscapeOrPortraint(orientation);
    };
  }

  useEffect(() => {
    testImageDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let mediaUrl = noImageGears;
  if (imageSrc) {
    mediaUrl = imageSrc;
  }

  return (
    <img
      className={
        isProductDetail
          ? 'clickable media-large'
          : `clickable media ${imageLandscapeOrPortrait}`
      }
      src={mediaUrl}
    />
  );
}
