import React from 'react';
import { useHistory } from 'react-router-dom';
import useLoggedInMemberInfo from '../../../hooks/useLoggedInMemberInfo';

const helperMessage = 'Please log in or create an account to leave a review';

const ReviewButton = ({ path }) => {
  const { isLoggedIn } = useLoggedInMemberInfo();
  const history = useHistory();

  const goToReview = () => {
    history.push(path);
  };

  if (!isLoggedIn) {
    return <div>{helperMessage}</div>;
  }

  return (
    <div>
      <button onClick={goToReview}>Write a Review</button>
    </div>
  );
};

export default ReviewButton;
