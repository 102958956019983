import React, { useState } from 'react';
import { Modal, Spin } from 'antd';

function LenderCreateStripeAccount({ currentMember, url }) {
  const [visible, setVisible] = useState(false);
  const [paymentButtonClicked, setPaymentButtonClicked] = useState(false);

  function getStripeCreateAccountLink() {
    setVisible(true);
    setPaymentButtonClicked(true);
    fetch(`${url}/stripe-connect-express/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        member_id:
          currentMember.url.split('/')[currentMember.url.split('/').length - 1],
        url: window.location.href.includes('localhost')
          ? 'http://localhost:3000'
          : 'https://www.gear2go.us',
      }),
    })
      .then((resp) => resp.json())
      // .then(resp=>console.log(resp))
      .then((resp) => (window.location.href = resp.url));
  }

  return (
    <div
      style={{
        padding: 16,
        backgroundColor: 'white',
        borderRadius: 4,
      }}
    >
      <h1>Please Connect With Stripe</h1>
      <h3>
        We partner with Stripe to handle secure payments and payouts. In order
        to lend gear and receive payment from a renter, you must create an
        account.
      </h3>
      <h3>
        Click on the button below to get started. Once you're done, you are free
        to post your gear!
      </h3>
      <a
        href="#"
        onClick={getStripeCreateAccountLink}
        disabled={paymentButtonClicked}
        className="stripe-connect white"
      >
        <span>Connect with</span>
      </a>
      <Modal
        className="redirect-modal"
        mask={true}
        footer={false}
        visible={visible}
        // onOk={handleOk}
      >
        <h3>Hang tight {currentMember.user.first_name}!</h3>
        <h4>
          We are redirecting you to create your secure stripe account. This will
          allow you to make and recieve payments from other members.
        </h4>
        <Spin />
      </Modal>
    </div>
  );
}

export default LenderCreateStripeAccount;
