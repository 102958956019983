import React, { useState, useEffect } from 'react';
import productManager from '../../modules/productManager';
import ProfileLenderProductCard from './ProfileLenderProductCard';
import EditProductModal from '../buy/EditProductModal';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';

import './Profile.css';

type Props = {};

const MyGear = (props: Props) => {
  const [lenderProducts, setLenderProducts] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isProductEditModalVisible, setIsProductEditModalVisible] =
    useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductDetails, setSelecteProductDetails] = useState();
  const { memberId } = useLoggedInMemberInfo();

  const getLenderGear = (lender_id: number) => {
    productManager.getLenderGear(lender_id).then((resp) => {
      setLenderProducts(resp);
    });
  };

  const getProductDetail = async (productId: number) => {
    await productManager
      .getProductDetail(productId)
      .then(setSelecteProductDetails);
  };

  useEffect(() => {
    if (selectedProductId) {
      getProductDetail(selectedProductId);
      setIsProductEditModalVisible(true);
    }
  }, [selectedProductId]);

  useEffect(() => {
    if (!selectedProductDetails) {
      setSelectedProductId(null);
    }
  }, [selectedProductDetails]);

  useEffect(() => {
    getLenderGear(memberId);
  }, [isProductEditModalVisible]);

  return (
    <div>
      <h1
        className="headline-font"
        style={{
          marginTop: '80px',
          marginLeft: '10%',
          fontSize: '3em',
          alignSelf: 'left',
        }}
      >
        Your Gear
      </h1>
      <div className="lenderGearContainer">
        {selectedProductDetails ? (
          <EditProductModal
            productInfo={selectedProductDetails}
            setProductInfo={setSelecteProductDetails}
            isEditModalVisible={isProductEditModalVisible}
            setIsEditModalVisible={setIsProductEditModalVisible}
            setSelectedProductId={setSelectedProductId}
          />
        ) : null}
        {lenderProducts?.length
          ? lenderProducts.map((productObj, i) => {
              return (
                <ProfileLenderProductCard
                  key={i}
                  product={productObj}
                  setSelectedProductId={setSelectedProductId}
                />
              );
            })
          : "You haven't added any products yet."}
      </div>
    </div>
  );
};

export default MyGear;
