import React from 'react';
import ProductReviewsAPI from '../../../modules/ProductReviewManager';
import ProductReview from './ProductReview';
import './ProductReview.css';
import ReviewButton from './ReviewButton';

const ProductReviewContainer = ({ productId }) => {
  const [reviews, setReviews] = React.useState([]);

  const getProductReviews = async () => {
    const res = await ProductReviewsAPI.getProductReviews({ productId });
    setReviews(res);
  };

  React.useEffect(() => {
    getProductReviews();
  }, [productId]);

  return (
    <div className="review-center review-container">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div id="product-reviews" className="header">
          Renter Reviews
        </div>
        <ReviewButton path={`/review/product/create?id=${productId}`} />
      </div>
      {reviews.length ? (
        <div>
          {reviews.map((item) => (
            <ProductReview key={item.id} review={item} />
          ))}
        </div>
      ) : (
        <div className="review-center no-reviews-yet">
          No reviews yet. Be the first to leave one!
        </div>
      )}
    </div>
  );
};

export default ProductReviewContainer;
