import { urlConditional } from './apiInfo';
// determining url
const baseUrl = urlConditional();

const authManager = {
  async loginUser(user) {
    const resp = await fetch(`${baseUrl}/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(user),
    });
    return await resp.json();
  },
  async registerUser(user) {
    return await fetch(`${baseUrl}/register/`, {
      method: 'POST',
      headers: {
        Accept: 'multipart/form-data',
      },
      body: user,
    }).then((resp) => resp.json());
  },
};

export default authManager;
