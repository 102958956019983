import React from 'react';
import ReactDOM from 'react-dom';
import Gear2GoApp from './Gear2GoApp';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import './index.css';
import './Main.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import 'path-browserify';
import * as ReactDOMClient from 'react-dom/client';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

// Initial render: Render an element to the root.
root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <Gear2GoApp />
    </ThemeProvider>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
