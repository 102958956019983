import React from 'react';
import './Instructions.css';
import { Collapse } from 'antd';

const Instructions = (routerProps) => {
  const props = routerProps.routerProps;
  const { Panel } = Collapse;

  return (
    <div id="faqs-container">
      <h3 id="info-sub-title">Instructions</h3>
      <Collapse>
        <Panel id="question" className="title" header="Lending Steps" key="1">
          <ol>
            <li>
              Identify which gear you’d want to make money renting out (we
              suggest bulky + expensive sporting goods / hardware)
            </li>
            <li>Take a high-quality photo of your gear</li>
            <li>
              Write up a description for your gear. We suggest looking at the
              manufacturer’s website or Amazon for inspiration
            </li>
            <li>
              Determine rental rules (i.e., cost, pick up/ drop off location,
              usage rules, condition upon return, etc. These rules are
              completely up to you as the lender.)
            </li>
            <li>Answer email requests when they roll in!</li>
          </ol>
        </Panel>
        <Panel className="title" header="Rental Steps" key="2">
          <ol>
            <li>Find Awesome gear</li>
            <li>
              Contact lender (You can use our built in messaging system or
              email)
            </li>
            <li>
              Lender will respond to the message or the email using account you
              provided when you signed up
            </li>
            <li>Coordinate details</li>
            <li>Send payment through Stripe</li>
            <li>Enjoy gear</li>
            <li>Return gear</li>
          </ol>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Instructions;
