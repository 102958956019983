import React, { useState, useEffect } from 'react';
import { Input, Button, Spin } from 'antd';
import G2GLogo from '../../images/G2G_light_green_transparent_background-01-01.png';
import gearRequestManager from '../../modules/gearRequestManager';
import Recaptcha from 'react-recaptcha';
import './GearRequest.css';

const GearRequestForm = ({ routerProps }) => {
  const [buttonClicked, changeButtonClicked] = useState(false);
  const [gearRequest, setGearRequest] = useState({});
  const [isVerifiedNotRobot, setVerifiedNotRobot] = useState(false);

  const handleFieldChange = (evt) => {
    const stateToChange = { ...gearRequest };
    stateToChange[evt.target.id] = evt.target.value;
    setGearRequest(stateToChange);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const requestInfo = {
      product_requested: gearRequest.product_requested,
      offer_price: gearRequest.offer_price,
      duration: gearRequest.duration,
      verify_token: isVerifiedNotRobot,
    };
    changeButtonClicked(true);
    if (!isVerifiedNotRobot) {
      window.alert(
        'Please verify that you are human by completing the captcha.'
      );
      changeButtonClicked(false);
    } else if (
      !requestInfo.product_requested ||
      !requestInfo.offer_price ||
      !requestInfo.duration ||
      requestInfo.product_requested.split(' ').join('').length < 3
    ) {
      window.alert('Please complete all form items.');
      changeButtonClicked(false);
    } else {
      return gearRequestManager
        .createProductRequest(requestInfo)
        .then((parsedResponse) => {
          window.alert('Request succesfully submitted!');
          routerProps.history.push('/gear-requests');
        });
    }
  };

  function buttonOrLoader() {
    if (buttonClicked) {
      return <Spin size="small" />;
    } else {
      return (
        <input
          className="btn"
          type="button"
          name=""
          value="Submit"
          onClick={handleSubmit}
        />
      );
    }
  }
  function verifyHuman(response) {
    if (response) {
      setVerifiedNotRobot(response);
    }
  }

  useEffect(() => {
    buttonOrLoader();
  }, [buttonClicked]);

  return (
    <div className="request-box">
      <img className="main-logo" src={G2GLogo} style={{ width: '150px' }} />

      <Input
        id="product_requested"
        onChange={handleFieldChange}
        type="text"
        placeholder="Gear Request"
        name=""
        value={gearRequest.product_requested}
      />

      <fieldset style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          onChange={handleFieldChange}
          type="number"
          id="offer_price"
          placeholder="Offer Price"
          min="0"
          step="1"
          value={gearRequest.offer_price}
          required
          addonBefore="$"
        />
        <label style={{ padding: '0 4px' }}> Per </label>
        <select
          onChange={handleFieldChange}
          type="text"
          id="duration"
          placeholder="Rental Period"
          value={gearRequest.duration}
          required
        >
          <option disabled selected>
            Select
          </option>
          <option value="Hour">Hour</option>
          <option value="Day">Day</option>
          <option value="Week">Week</option>
          <option value="Month">Month</option>
        </select>
      </fieldset>
      <Recaptcha
        sitekey="6LerR4AaAAAAAP69VAyWuxgBxpgDBMZW7C2-Kdj6"
        render="explicit"
        verifyCallback={verifyHuman}
        onloadCallback={() => console.log('recaptcha loaded')}
        theme="dark"
      />
      {buttonOrLoader()}
    </div>
  );
};

export default GearRequestForm;
