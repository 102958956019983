import React from 'react';
import CompanyEmail from '../components/CompanyEmail';
const termsOfService = (
  <p>
    <strong>
      IT IS IMPORTANT THAT YOU READ CAREFULLY AND UNDERSTAND THESE TERMS AND
      CONDITIONS (THIS “AGREEMENT”). YOU MUST CLICK THE “I ACCEPT” BUTTON BELOW
      IN ORDER TO CREATE AN ACCOUNT TO BE ABLE TO USE THE SOFTWARE AND SERVICES
      PROVIDED BY GEAR2GO, INC. BY CLICKING THE “I ACCEPT” BUTTON BELOW, YOU ARE
      INDICATING THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND THE TERMS AND
      CONDITIONS OF THIS AGREEMENT, AND AGREE TO BE BOUND BY THIS AGREEMENT. IF
      YOU DO NOT AGREE WITH ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT AND
      DO NOT AGREE TO BE BOUND BY THEM, PLEASE CLICK THE “CANCEL” BUTTON. IF YOU
      DO NOT ACCEPT THIS AGREEMENT, YOU ARE NOT PERMITTED TO USE THE SOFTWARE
      AND SERVICES PROVIDED THROUGH THIS WEBSITE.
    </strong>
    <br />
    <br />
    <strong>1. Binding Agreement.</strong> These terms and conditions (this
    "Agreement") is a legally binding agreement between you and Gear2Go, Inc., a
    Delaware corporation, its affiliates, and its and their respective agents
    and representatives (individually and collectively, "Company," "we," "our"
    or "us"), and applies to your use of the URL, http://www.gear2go.us, and all
    other websites, social media pages and accounts owned by the Company
    (individually and collectively, the "Websites"), and all mobile applications
    owned by the Company (together with the Websites, collectively, the "Company
    Sites"). Through the use of the Company Sites, the Company provides users
    the opportunity to take advantage of the various services offered by the
    Company, including, without limitation, the opportunity to borrow, lease or
    rent tools, equipment and other items (collectively, the "Services").
    <br />
    <br />
    <strong>2. Renters and Owners.</strong> The Company’s role in the
    transactions contemplated by this Agreement is that of a service provider
    that enables a user (the “Renter”) to borrow, lease or rent tools, equipment
    and other items (collectively, “Gear’) from another user (the "Owner") and
    to participate in the services and/or any other product offers offered on
    the Company Sites. All Gear that is borrowed, leased or rented through the
    Services is owned by the Owner, and not the Company. As a Renter, you
    acknowledge that you are borrowing, leasing or renting Gear directly from
    the Owner, and not the Company. As the Owner, you acknowledge that you
    lending, leasing or renting Gear directly to the Renter, and not the
    Company. Any Gear that a Renter borrows, leases or rents through the use of
    the Company Sites from an Owner must be for personal use only and not for
    re-borrowing, re-leasing or re-renting to a third party. The Company Sites
    and the Services may only be used to borrow, lease or rent Gear in the
    United States. Any request or offer to borrow, lease or rent Gear through
    the Company Sites and the Services is void where prohibited by applicable
    law.
    <br />
    <br />
    <strong>3. Eligibility.</strong>
    <br />
    <strong>a. Registering.</strong> You must be at least eighteen (18) years of
    age to use the Company Sites and Services. To verify that you are at least
    eighteen (18) years of age, you may be required to enter your birth date
    prior to registering to use the Company Sites and Services. You represent
    and warrant that you are at least eighteen (18) years of age. Your
    borrowing, lending, leasing or renting of Gear through use of the Company
    Sites and Services will not be private, and may be may accessible to other
    users of the Company Sites and Services. As required by the Communications
    Decency Act of 1996, the Company hereby notifies you that parental control
    protections (e.g., computer software, filtering services, etc.) are
    commercially available and may be used to assist with limiting access to the
    Company Sites to minors. Among the many companies that provide Internet
    blocking and screening software are CyberPatrol, NetNanny, SurfWatch and
    GuardOne. We do not sponsor or endorse any of these companies or their
    services. We may refuse to register any person or service any order at any
    time, at our sole discretion.
    <br />
    <br />
    <strong>4. Registration; Account.</strong> To use the Company Sites, you
    must obtain a personal account with the Company (an "Account"), follow the
    instructions provided by Company, and provide the requested personally
    identifiable information (which may include name, phone number, email
    address, date of birth, and, to be able to pay fees associated with use of
    the Services, method of payment information) (collectively, the "PII"). You
    represent and warrant that all PII you provide in connection with
    registering your Account is complete and accurate, and that you are
    authorized to use the method of payment you provide in connection with your
    use of the Services. We reserve the right to contact the service provider of
    your method of payment to conduct periodic security checks. You acknowledge
    and agree that you may never use another customer’s Account. You also agree
    to review and update your Account as and when necessary to maintain the most
    current PII. You may use your Account to track your history of usage on the
    Company Sites.
    <br />
    <br />
    <strong>5. Borrowing, Lending, Leasing or Renting Gear.</strong>
    <br />
    <strong>
      a. Requests to Borrow, Lease or Rent. ALL EQUIPMENT DISPLAYED ON THE
      COMPANY SITES ARE LOANED, LEASED OR RENTED BY OWNERS, AND NOT THE COMPANY.
    </strong>{' '}
    The listing of items of Gear on the Company Sites is merely a solicitation
    of offers from Renters to borrow, lease or rent such Gear, and such listing
    alone does not constitute any offer from any Owner to lend, lease or rent
    such Gear. As such, each request to borrow, lease or rent Gear submitted
    through the Company Sites is subject to acceptance by the applicable Owner.
    The Owner of an item of Gear has the right to refuse to lend, lease or rent
    Gear for any reason. The Company never takes possession to any Gear listed
    through the Services, and thus, possession of all Gear borrowed, leased or
    rented through the Services transfers directly from the applicable Owner to
    the applicable Renter. All borrowing, leasing or renting of Gear through the
    Company Sites are subject to this Agreement. The Company does not guarantee
    the availability of any Gear displayed on the Company Sites. In the event
    that a particular item of Gear that a Renter requests to borrow, lease or
    rent is unavailable for borrowing, leasing or renting by the desired date,
    or in the event that a Renter does not take possession of a particular item
    of Gear upon the desired date, the transaction will not be fulfilled and the
    Renter’s method of payment will not be charged. The Company shall not be
    liable for any description or depiction of Gear, the condition of Gear, the
    fitness of Gear for any intended purpose, the loss of or damage to any Gear,
    or the acts or inaction of any Owner or Renter.
    <br />
    <br />
    <strong>b. Payment and Delivery Information.</strong> When you submit a
    request to borrow, lease or rent Gear through the Company Sites, you will be
    required to provide your PII (including your method of payment) together
    you’rewith your address and desired dates to take possession of the Gear and
    return the Gear to the Owner. The Company will not take possession of any
    Gear or otherwise facilitate the direct transfer of possession of Gear
    between an Owner and Renter. You expressly authorize us to use all
    information that you provide to us to communicate with Owners and Renters to
    facilitate the pairing of requests from Renters with applicable Owners.
    <br />
    <br />
    <strong>c. Platform Fee.</strong> You may be charged a fee for using the
    Company Sites and Services, to borrow, lease or rent Gear as a Renter and/or
    to lend, lease or rent Gear as an Owner. Any such fee is for use of the
    Company’s technology platform and access to the Content, and does not
    include any portion of the lease or rent payment of an item of Gear, even if
    the amount of such fee is based upon or determined by reference to the lease
    or rent payment of an item of Gear.
    <br />
    <br />
    <strong>6. Contact Information.</strong> If you have any requests, questions
    or comments about your use of the Services, your Account, your PII, the
    Company Sites, or any other questions or comments relating to our products
    and services, please contact us at:
    <br />
    <br />
    <strong>Email:</strong>
    <br />
    <CompanyEmail />
    <br />
    <br />
    <strong>Mail:</strong>
    <br />
    Gear2Go, Inc.
    <br />
    Attn: Customer Service
    <br />
    405 Red Oak Avenue
    <br />
    Apt 203
    <br />
    Albany, CA 94706
    <br />
    <br />
    When you send an email to us, you agree that we may electronically
    communicate with you. You acknowledge that communications by email may not
    be confidential.
    <br />
    <br />
    <strong>7. Transferring Possession of Gear.</strong> Owners and Renters
    assume full responsibility and liability for transferring possession of Gear
    between themselves (including sharing contact information, Gear pickup and
    return location information, and sharing any information regarding the
    proper care and use of Gear). An Owner reserves the right to reject any
    offer to borrow, lease or rent Gear up to the time when such Owner transfers
    possession of the Gear to a Renter. A Renter reserves the right to rescind
    any offer to borrow, lease or rent Gear up to the time when such Renter
    takes possession of the Gear from an Owner. Unless an Owner and a Renter
    make other arrangements between themselves, Renters assume the full risk of
    loss or damage to Gear that they borrow, lease or rent from Owners from and
    after the time the Renter takes possession of the Gear and until the time
    the Renter returns possession of the Gear to the Owner. If an Owner suffers
    harm from the loss of or damage to Gear while in the possession of a Renter,
    the Owner shall first seek compensation directly from the Renter for the
    amount of harm, giving the Renter not less than twenty-four (24) hours to
    respond. If, after such twenty-four (24) hour period such claim is not
    resolved to the Owner’s satisfaction, the Owner may escalate the claim to
    the Company by providing the Company with evidence of such loss or damage,
    and the Company shall provide the Renter with an opportunity to respond of
    not less than twenty-four (24) hours. If the Renter agrees to pay the amount
    of the Owner’s claim, or the Company determines in its sole discretion that
    the Renter is responsible for the amount of the Owner’s claim, the Company
    may collect the amount of such claim from the Renter, including by way of
    using any method of payment that the Renter has previously provided to the
    Company in connection with use of the Company Sites. The Renter agrees that
    the Company may recover from the Renter under any insurance policies that
    the Renter maintains and the Company may also pursue against the Renter any
    remedies it may have under applicable law. Each Renter and each Owner agrees
    to cooperate in good faith, provide any information that the Company
    requests, execute documents and take further reasonable actions in
    connection with Owner claims, claims under insurance policies or other
    claims related to the Renter’s or the Owner’s use of the Company Sites. The
    Company agrees to pay to the Owner, within seventy-two (72) hours of the
    Company’s receipt, any amounts that the Company collects in connection with
    the Owner’s claim for loss or damage to Gear. Notwithstanding any of the
    foregoing, the Company shall not be responsible for any loss or damage to
    Gear.
    <br />
    <br />
    <strong>8. Promotional Offers.</strong> By creating an Account, you may be
    eligible to receive various benefits provided from time to time by the
    Company or its commercial partners, including, without limitation, receiving
    discounts, vouchers and promotional offers. You will be notified of these
    special offers through various channels of communication, including, without
    limitation, email and/or U.S. mail. Each such offer will apply only to those
    specific products or services advertised, and will be governed by this
    Agreement, as amended from time to time, and the applicable terms of such
    promotions as communicated to you. You will be required to input the
    applicable reference code included in the advertisement of the promotional
    offer prior to enjoying any benefits. A particular reference code may only
    be used once and may not be used in conjunction with any other discount,
    voucher or promotional offer unless the applicable advertisement provides
    otherwise. The value of any such offer is not refundable. All offers are
    subject to availability and the Company reserves the right to change
    discounts, vouchers or offers at any time. Some states do not permit us to
    honor certain discounts, vouchers or offers, and the Company extends such
    discounts, vouchers and offers only if they are not prohibited by law.
    <br />
    <br />
    <strong>9. Privacy Policy.</strong> Your use of the Company Sites is subject
    to our Privacy Policy that is accessible at the Company
    <br />
    Sites, which we may amend from time to time, and you hereby agree to its
    terms.
    <br />
    <br />
    <strong>10. Customer Conduct.</strong> You agree not to engage in any of the
    following activities:
    <br />
    <br />
    <strong>(i)</strong> interfere with or inhibit the use of the Company Sites
    by other users;
    <br />
    <br />
    <strong>(ii)</strong> use the Company Sites for any commercial purposes
    (e.g., re-lending, re-leasing or re-renting Gear to others) or any purpose
    other than to lend, lease or rent Gear for yourself;
    <br />
    <br />
    <strong>(iii)</strong> create an account using an alter-ego or by
    impersonating a third party, or providing any other false information;
    <br />
    <br />
    <strong>(iv)</strong> create an account if you are under eighteen (18) years
    of age;
    <br />
    <br />
    <strong>(v)</strong> copy, reverse engineer, or attempt to derive the source
    code of any part of the Company Sites;
    <br />
    <br />
    <strong>(vi)</strong> post any comment or product review on the Company
    Sites that is private, obscene, vulgar, violent, harassing, discriminatory
    or defamatory; or
    <br />
    <br />
    <strong>(vii)</strong> use the Company Sites in a manner that would violate
    this Agreement or any applicable laws. We may investigate any reported
    violation of these restrictions or other provisions in this Agreement. If we
    determine that you have violated these restrictions, or other provisions of
    this Agreement, we may take any action that we deem appropriate and you
    potentially may be subject to liability. Such action may include, without
    limitation, issuing warnings, immediately terminating your Account,
    unilaterally removing posted content and/or reporting any activity that we
    suspect violates any law or regulation to appropriate law enforcement
    officials or regulators and, in doing so, we may disclose any relevant
    information to such officials or regulators, including, without limitation,
    your PII, IP address(es) and your Company Sites usage history.
    <br />
    <br />
    <strong>11. Intellectual Property.</strong>
    <br />
    <br />
    <strong>a. Access to Company Sites.</strong> The Company grants you the
    limited right to access and make use of the Company Sites as a user. The
    Company Sites, including, without limitation, all text, graphics, images,
    logos, software, trademarks, service marks and trade names incorporated in
    the Company Sites (the "Content") are owned by or licensed to the Company.
    Unless authorized in writing and in advance by the Company or the applicable
    rights holder, you may not sell, reproduce, copy, distribute, modify,
    display, publicly perform or otherwise exploit the Content for any public or
    commercial purpose. No provisions of this Agreement shall be construed to
    convey any property right or license to the Content unless expressly
    provided.
    <br />
    <br />
    <strong>b. DMCA.</strong> We respect the intellectual property rights of
    others and require that our users do the same. If you believe your work has
    been copied in a manner that constitutes copyright infringement, or you
    believe your rights are otherwise infringed or violated by anything on the
    Company Sites, you must notify us and give us an opportunity to correct the
    problem before taking any other action by sending an email to the following
    address: customerservice@gear2go.us. The notification must include all of
    the following:
    <br />
    <br />
    <strong>(i)</strong> a physical or electronic signature of the owner of the
    right claimed to be infringed or the person authorized to act on the owner’s
    behalf;
    <br />
    <br />
    <strong>(ii)</strong> a description of the copyrighted work or other right
    you claim has been infringed or violated;
    <br />
    <br />
    <strong>(iii)</strong> information reasonably sufficient to locate the
    material in question on the Company Site;
    <br />
    <br />
    <strong>(iv)</strong> your name, address, telephone number and e-mail
    address;
    <br />
    <br />
    <strong>(v)</strong> a statement by you that you have a good faith belief
    that the disputed use is not authorized by the rightful owner, its agent or
    the law; and
    <br />
    <br />
    <strong>(vi)</strong> a statement by you, made under penalty of perjury,
    that the information in your notice is accurate and that you are the owner
    of the right claimed to be infringed or violated or are authorized to act on
    behalf of the owner.
    <br />
    <br />
    <strong>12. Third Party Servicers; Release.</strong> You may have contact or
    other interaction with third parties (e.g. Renters, Owners or other third
    parties) (“Third Parties”) in connection with your use of the Company Sites
    and you may be directed to Third Party websites from the Company Sites. You
    hereby acknowledge and agree that you are proceeding at your own risk and
    any such Third Parties are not under the control of the Company. The Company
    shall be in no way responsible or liable for any acts, omissions, breaches,
    representations or warranties made by any such Third Party, or for any
    losses, injuries, or other damages you incur in connection with your contact
    or other interaction with such Third Parties ("Third Party Claims").{' '}
    <strong>
      YOU HEREBY RELEASE THE COMPANY, OUR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS
      AND STOCKHOLDERS FROM ANY AND ALL SUCH THIRD PARTY CLAIMS NOT ATTRIBUTABLE
      TO THE COMPANY’S GROSSLY NEGLIGENT ACTS.
    </strong>
    <br />
    <br />
    <strong>13. Disclaimer; Notice of Violations.</strong>
    <br />
    <br />
    <strong>
      a. "As Is." THE COMPANY SITES AND THE CONTENT ARE PROVIDED "AS IS", "AS
      AVAILABLE" AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
      INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE,
      NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND
      ALL WARRANTIES THAT MAY BE IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE
      OF TRADE, ALL OF WHICH ARE HEREBY EXPRESSLY DISCLAIMED. YOUR USE OF THE
      COMPANY SITES IS SOLELY AT YOUR OWN RISK. THERE IS NO, AND NEVER HAS BEEN
      ANY, GUARANTEE THAT THE SERVICES OR ANY EQUIPMENT WILL MEET YOUR
      REQUIREMENTS, ARE DEFECT-FREE OR ERROR-FREE, RELIABLE, WITHOUT
      INTERRUPTION OR AVAILABLE AT ALL TIMES. THE COMPANY DOES NOT GUARANTEE
      THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE
      EFFECTIVE, RELIABLE, ACCURATE OR MEET YOUR REQUIREMENTS. NO ORAL OR
      WRITTEN INFORMATION OR ADVICE GIVEN BY A COMPANY REPRESENTATIVE SHALL
      CREATE A WARRANTY.
    </strong>{' '}
    The Company Sites are subject to constant change. You will not be eligible
    for any compensation because you cannot use any part of the Company Sites or
    because of a failure, suspension or withdrawal of all or part of the Company
    Sites. The Company strives to provide complete, accurate, up-to-date
    information on the Company Sites. Unfortunately, despite those efforts,
    human or technological errors may occur. For example, the Company Sites may
    contain typographical mistakes, inaccuracies or omissions,some of which may
    relate to pricing and availability, and some information may not be complete
    or current. We reserve the right to correct any errors, inaccuracies or
    omissions, including after a request to borrow, lease or rent has been
    submitted, and to change or update information at any time without prior
    notice. In addition, you acknowledge that the particular technical
    specifications and settings of your computer and its display could affect
    the accuracy of its display of the colors and look of Gear listed on the
    Company Sites.
    <br />
    <br />
    <strong>b. Good Faith Compliance.</strong> The Company Sites may be accessed
    from many locations. You acknowledge and agree that consumer protection laws
    vary by location and that the Company has made a good faith attempt to
    comply with all such applicable laws. You agree that you will notify the
    Company if you believe that it is not in compliance with any laws and
    provide the Company with an opportunity to cure any alleged violations
    before invoking the Dispute Resolution mechanisms set forth in Section 20
    (“Dispute Resolution”).
    <br />
    <br />
    <strong>14. Termination.</strong> The Company reserves the right to
    terminate your Account at any time, with or without cause or notice, and
    which shall be effective immediately. If you wish to terminate your Account,
    you may do so by contacting us using the contact information provided in
    Section 6 ("Contact Information") above. You acknowledge and agree that any
    platform fees or lease or rent payments that have become due and payable
    before such termination are non-refundable. Sections 1 – 7 and 9 – 22 of
    this Agreement shall survive any such termination.
    <br />
    <br />
    <strong>15. Modification.</strong> The Company reserves the right to modify
    or augment any of the terms and conditions of this Agreement, at any time,
    at our sole discretion. You should periodically review this Agreement,
    accessible from the Company Sites, for any modifications, each time that you
    request to borrow, lend, lease or rent Gear. All modifications will become
    effective upon their posting on the Company Sites, but no modification will
    affect any loan, lease or rental of Gear made prior to the modifications
    becoming effective. Your continued use of the Company Sites or your Account
    following any modification will be confirmation of your acceptance of such
    changes.
    <br />
    <br />
    <strong>16. Indemnification.</strong> You agree to indemnify, defend and
    hold the Company and its employees, officers, directors, agents and
    stockholders harmless to the fullest extent permitted by law from and
    against any losses, costs, liabilities, settlements, fines, payments or
    damages (including reasonable attorneys’ fees and costs) resulting from any
    claim, suit or proceeding, threatened or otherwise, at law or in equity made
    or brought against any of them, and caused by, arising out of, resulting
    from or attributable to:
    <br />
    <br />
    <strong>(i)</strong> your misuse of the Company Sites;
    <br />
    <br />
    <strong>(ii)</strong> anything you post to the Company Sites;
    <br />
    <br />
    <strong>(iii)</strong> a Renter’s borrowing, leasing or renting of Gear
    through the Services,
    <br />
    <br />
    <strong>(iv)</strong> an Owner’s lending, leasing or renting of Gear through
    the Services,
    <br />
    <br />
    <strong>(v)</strong> any loss of or damage to Gear loaned, leased or rented
    through the Services or any injury or damage to person or property as a
    result of Gear loaned, leased or rented through the Services,
    <br />
    <br />
    <strong>(vi)</strong> your violation of this Agreement;
    <br />
    <br />
    <strong>(vii)</strong> your violation of the rights of any third parties
    (including Third Parties); or
    <br />
    <br />
    <strong>(viii)</strong> use of the Company Sites by any other person
    accessing the Company Sites using a computer or Internet access account that
    you provided to such person.
    <br />
    <br />
    <strong>17. Limitation of Liability.</strong> In no event shall the
    Company’s liability, or the liability of our affiliates, officers,
    directors, stockholders, agents, vendors, or suppliers, for any and all
    claims relating to the use of the Services exceed the total amount of money
    that you paid us during the previous twelve (12) month period. TO THE
    FULLEST EXTENT PERMITTED BY LAW, THE COMPANY, TOGETHER WITH ITS AFFILIATES,
    OFFICERS, DIRECTORS, STOCKHOLDERS, AGENTS, VENDORS AND SUPPLIERS, SHALL NOT
    BE LIABLE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
    LIABILITY, STRICT LIABILITY OR OTHERWISE FOR ANY SPECIAL, INDIRECT,
    INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT
    LIMITED TO, LOST PROFITS, LOST DATA, OR ANY OTHER COMMERCIAL DAMAGES, OR
    LOSSES, EVEN IF THE COMPANY HAS KNOWLEDGE OF THE POSSIBILITY OF SUCH
    DAMAGES. If any jurisdiction does not allow the exclusion or the limitation
    of liability for consequential or incidental damages, in such jurisdiction,
    our liability, and the liability of our affiliates, officers, managers,
    agents, vendors, and merchants, shall be limited to the maximum extent
    permitted by law.
    <br />
    <br />
    <strong>18. Governing Law.</strong> This Agreement and all issues collateral
    thereto shall be governed and construed in accordance with the laws of the
    State of California pertaining to contracts made and to be performed
    entirely therein and without regard to its conflict of laws principles.
    <br />
    <br />
    <strong>19. Notice for California Users.</strong> Under California Civil
    Code Section 1789.3, residents of California who use the Company Sites are
    entitled to know that they may file grievances and complaints with: the
    Complaint Assistance Unit of the Division of Consumer Services of the
    California Department of Consumer Affairs, in writing at 400 R Street, Suite
    1080, Sacramento, California 95814, or by telephone at (916) 445-1254 or
    (800) 952-5210, or by email at dca@dca.ca.gov.
    <br />
    <br />
    <strong>
      a. IF YOU AND WE ARE UNABLE TO RESOLVE OUR DISPUTE BY TALKING TO EACH
      OTHER, YOU AND WE EACH AGREE THAT WE WILL SUBMIT THE DISPUTE TO BINDING
      ARBITRATION, SUBJECT TO SECTION 20(F) BELOW.
    </strong>{' '}
    To the fullest extent permitted by law, any controversy or claim arising out
    of or relating to our relationship, this Agreement (including any dispute as
    to their breach, termination, enforcement, interpretation or validity), our
    Privacy Policy (including any dispute as to its breach, termination,
    enforcement, interpretation or validity), the Company Sites, any promotional
    offers or any product or service provided under or in connection with our
    relationship will be resolved exclusively by binding arbitration, subject
    only to Section 20(f) below. The arbitration shall be conducted before JAMS
    using a single arbitrator under the JAMS Comprehensive Arbitration Rules and
    Procedures (or their replacement) that are in effect at the time the
    arbitration is initiated and under the terms set forth in this Agreement.
    The arbitrator shall be chosen by mutual agreement from a slate of five (5)
    potential arbitrators proposed by either you or us and, if you and we are
    unable to agree, the arbitrator shall be chosen by the JAMS administrator.
    In the event of a conflict between the JAMS rules and this Agreement, this
    Agreement shall govern. Arbitration is not a court proceeding. The rules of
    arbitration differ from the rules of court and there is no judge or jury in
    an arbitration proceeding.{' '}
    <strong>
      YOU ARE HEREBY WAIVING YOUR RIGHT TO HAVE A JURY TRIAL OR TO GO TO COURT,
    </strong>{' '}
    other than as provided in Section 20(f) below. You may, in arbitration, seek
    any and all remedies otherwise available to you where you reside, other than
    as waived in this Agreement. You have a right to hire an attorney to
    represent you in arbitration. The arbitration shall permit the discovery of
    relevant information that is not privileged or protected.
    <br />
    <br />
    <strong>20. Dispute Resolution,</strong> including Arbitration and Class
    Action Waiver.
    <br />
    <br />
    <strong>a.</strong> You and we agree that in the event of any dispute
    between us, you and we will first try to resolve the dispute by talking with
    each other. Accordingly, neither you nor we may start a formal proceeding
    for at least sixty (60) days after one of us notifies the other in writing
    of a dispute. You will send your notice to us at the address provided in
    Section 6 ("Contact Information") above, and we will send our notice to you
    using the PII that you have provided to us. The notice must be sent by U.S.
    mail or private courier, with confirmation of delivery requested.
    <br />
    <br />
    <strong>b.</strong> The arbitration will be conducted at the JAMS office in
    or nearest to San Francisco, California, unless you and we mutually agree to
    a different location or to a telephonic arbitration. We shall evenly share
    the costs of the arbitration proceeding, including the arbitrator’s fees and
    case management fees. We will not, however, pay any portion of your
    attorney’s fees. To start an arbitration, you or we must do the following
    three things:
    <br />
    <br />
    <strong>(i)</strong> Write a Demand for Arbitration. The demand must include
    a description of the dispute and the amount of damages or other relief
    sought. You can find a copy of a Demand for Arbitration at www.jamsadr.com.
    <br />
    <br />
    <strong>(ii)</strong> Send two (2) copies of the Demand for Arbitration,
    plus the appropriate case management fee and reference to this Agreement to:
    JAMS, Two Embarcadero Center, Suite 1500, San Francisco, CA
    <br />
    <br />
    <strong>(iii)</strong> Send one (1) copy of the Demand for Arbitration to
    the other party.
    <br />
    <br />
    <strong>d.</strong> The arbitrator’s decision shall be final and binding and
    may be enforced by any state or federal court that has jurisdiction;
    provided, however, that the arbitrator shall not have authority to make
    errors of law and any arbitration award may be challenged if the arbitrator
    does so. The arbitrator shall give a written statement of the disposition of
    each claim and the award shall provide a concise written statement of the
    essential findings and conclusions upon which it is based. You and we agree
    that the Federal Arbitration Act shall govern this agreement to arbitrate.
    <br />
    <br />
    <strong>e.</strong> You and we agree not to join or consolidate claims in
    arbitration by or against the other. You and we also agree that you and we
    will not be entitled to bring any claim as a representative member of a
    class or in a private attorney general capacity against the other, or
    against our service partners, Licensed Retailers, retailers, licensors,
    officers, directors, agents, employees, affiliates or predecessors.
    Accordingly, you and we agree that the JAMS Class Action Procedures (or
    their replacement) will not apply in any arbitration between us. YOU HEREBY
    WAIVE ANY RIGHT TO BRING A CLASS ACTION OR PRIVATE ATTORNEY GENERAL CLAIM.
    You and we retain our respective rights to make any complaint to regulatory
    agencies or governmental investigators. A court may sever any portion of
    this Section 20 that it may find to be unenforceable, except for the
    prohibition on class, representative or private attorney general
    proceedings. You understand and agree that the waivers of rights in this
    Section 20, including the waivers of the rights to bring a lawsuit in court
    and to assert a class action, are knowing and voluntary.
    <br />
    <br />
    <strong>f.</strong> In lieu of initiating an arbitration, you may instead
    choose in your sole discretion to file an individual action in a small
    claims court. If you do so, we do not agree to pay any of your fees or
    costs. The provisions of Section 20(e) above remain effective, even if you
    elect to file in a small claims court.
    <br />
    <br />
    <strong>21. Waiver of Statutory Claims.</strong> By agreeing to this
    Agreement, you are giving up statutory claims or rights that you may have
    under your state’s laws, including claims for certain forms of relief or for
    attorneys’ fees. You agree that your waiver of any such laws does not
    violate your state’s laws.
    <br />
    <br />
    <strong>22. Miscellaneous.</strong>
    <br />
    <br />
    <strong>a. Severability.</strong> In the event that any provision of this
    Agreement (except as otherwise provided in Section 20(e)) is declared void
    or unenforceable, such provision shall be deemed severed from this
    Agreement, and this Agreement shall otherwise remain in full force and
    effect unless its purpose cannot be effected absent the severed provision.
    <br />
    <br />
    <strong>b. Entire Agreement.</strong> This Agreement, our Privacy Policy and
    the terms and conditions that may be posted on the Company Sites or of any
    promotional offers we make and you accept ("Company Sites Agreements")
    constitute the entire agreement between you and us with respect to the
    subject matter hereof, and supersede all prior agreements, whether written
    or oral. You confirm that you have not relied on any representation except
    insofar as the same has expressly been made a representation in these
    Company Sites Agreements.
    <br />
    <br />
    <strong>c. Notices.</strong> Unless otherwise specified to the contrary
    herein, all notices due under this Agreement shall be in writing and shall
    be deemed to have been made or given when any such notice is received, if
    personally delivered or sent by certified or registered mail; when receipt
    is electronically confirmed, if sent by facsimile or email; or the day after
    it is sent, if sent for next day delivery by a recognized overnight courier
    service. Electronic notices to us shall be sent to the email address
    provided in Section 6 (Contact Information) above.
    <br />
    <br />
    <strong>d. Assignment.</strong> This Agreement is personal to you and your
    Account. You may not assign this Agreement without the prior written consent
    of the Company. The Company may assign any of its rights and delegate any of
    its duties hereunder at any time, without your consent, at its sole
    discretion. This Agreement will inure to the benefit of the Company’s
    successors, assigns and licensors.
    <br />
    <br />
    <strong>e. Relationship of the Parties.</strong> Nothing contained herein
    will be construed as creating any relationship of employer/employee,
    partnership, agency, joint venture, or otherwise between you and the
    Company, nor will this Agreement be construed as conferring on any party any
    express or implied right, power, or authority to enter into any agreement or
    commitment, express or implied, or to incur any obligation or liability on
    behalf of the other party.
    <br />
    <br />
    <strong>f. Force Majeure.</strong> The Company shall not be liable for
    performance of its obligations under this Agreement if it is prevented or
    delayed from performing such obligations as a result of
    <br />
    <br />
    <strong>(i)</strong> any provision of any present or future law or
    regulation of any state or of the United States,
    <br />
    <br />
    <strong>(ii)</strong> any act of God, war, civil unrest or emergency
    condition (including pandemic or material worsening of a pandemic), or
    <br />
    <br />
    <strong>(iii)</strong> any other circumstances beyond the reasonable control
    of the Company.
    <br />
    <br />
    <strong>g. Waiver.</strong> Any waiver of either party’s rights hereunder
    must be in writing. No waiver by either party of any breach or default of
    any of the provisions contained in this Agreement and required to be
    performed by the other party shall be construed as a waiver of any
    succeeding breach of the same or of any other provision.
    <br />
    <br />
    <strong>PLEASE PRINT A COPY OF THIS AGREEMENT FOR YOUR RECORDS.</strong> If
    you are unable to print from your device, please contact the Company at
    <CompanyEmail /> to request a hard copy of this Agreement.
  </p>
);
const privacyPolicy = (
  <p>
    <strong>
      This Privacy Policy is a legally binding agreement between you and
      Gear2Go, Inc., a Delaware corporation, its affiliates, and its and their
      respective agents and representatives (individually and collectively,
      "Company," "we," "our" or "us"), and applies to your use of the URL,
      http://www.gear2go.us, and all other websites, social media pages and
      accounts owned by the Company (individually and collectively, the
      "Websites"), and all mobile applications owned by the Company (together
      with the Websites, collectively, the "Company Sites"). Through the use of
      the Company Sites, the Company provides users the opportunity to take
      advantage of the various services offered by the Company, including,
      without limitation, the opportunity to borrow, lease or rent tools,
      equipment and other items (collectively, the "Services"). Certain
      capitalized terms used in this Privacy Policy are defined in our Terms and
      Conditions, which is accessible at http://www.gear2go.us.
    </strong>
    <br />
    <br />
    <strong>This Privacy Policy notifies users of the Services of:</strong>
    <br />
    <strong>1.</strong> What personally identifiable information the Company
    collects.
    <br />
    <strong>2.</strong> What personally identifiable information third parties
    collect through the Company Sites.
    <br />
    <strong>3.</strong> What organization collects the information.
    <br />
    <strong>4.</strong> How the Company uses the information.
    <br />
    <strong>5.</strong> With whom the Company may share user information.
    <br />
    <strong>6.</strong> What choices are available to users regarding
    collection, use and distribution of the information.
    <br />
    <strong>7.</strong> What measures the Company takes to protect the
    information under its control.
    <br />
    <strong>8.</strong> How users can correct any inaccuracies in the
    information. If you feel that the Company is not abiding by this Privacy
    Policy, as it may be amended from time to time, please contact the Company
    by telephone at 510) 982-6146, by email at <CompanyEmail /> or by mail at
    Gear2Go, Inc., 405 Red Oak Ave, Albany, CA 94706.
    <br />
    <br />
    <strong>1. Information collection and use.</strong> The Company is the sole
    owner of the information collected through the Company Sites. We will not
    sell, share, or rent this information to others in any way that varies from
    what is disclosed in this Privacy Policy. The Company collects information
    from users at several different areas on the Company Sites. This information
    may include your name, date of birth (to verify your age), address,
    telephone number, email address, IP address (we may also derive your
    approximate location from your IP address), payment method information
    including payment card data (including your credit card number, cardholder
    name, billing address, expiration date and security code), and Equipment
    that you request or make available to borrow, lend, lease or rent through
    the Services.
    <br />
    <br />
    You may receive information regarding new features, services and special
    offers that we think you may find valuable. We may also share information
    with carefully selected business partners. These businesses may use the
    information we share to provide valuable opportunities and offers. If you'd
    like to opt out of future mailings from the Company, send us an email at{' '}
    <CompanyEmail />. When we present you with the opportunity to receive
    marketing opportunities from our business partners we will not automatically
    subscribe you for these promotions, rather we will provide you with the
    opportunity to opt-in to these third party marketing programs. You may also
    access the personally identifiable information you have submitted to us by
    selecting “My Profile” and editing the information as it exists in our
    system currently. You are also able to access your information in order to
    update or delete it by sending us an email at <CompanyEmail />. We will
    respond to your request within a reasonable time frame.
    <br />
    <br />
    We will retain your information for as long as your Account is active or as
    needed to provide you access to the Services. We will retain and use your
    information as necessary to comply with our legal obligations, resolve
    disputes, and enforce our agreements.
    <br />
    <br />
    <strong>2. Registration.</strong> You must create an Account prior to using
    the Services. To submit request or accept requests to borrow, lend, lease or
    rent Equipment through the Services, users are required to provide various
    items of personally identifiable information (i.e., name, phone number,
    email address, date of birth, and method of payment information) and select
    a password. This information is necessary in order for us to verify the
    identity and eligibility of users to access the Services, to contact users
    about the Services and to pair requests from Renters with applicable Owners.
    Your borrowing, lending, leasing or renting of Equipment through use of the
    Company Sites and Services will not be private, and may be may accessible to
    other users of the Company Sites and Services.
    <br />
    <br />
    <strong>3. Referrals.</strong> If you choose to use our referral service to
    tell a friend about the Services or the Company Sites, we will ask you for
    your friend’s name and email address. We will automatically send your friend
    a one-time email inviting him or her to visit the Company Sites. Your friend
    may contact us at <CompanyEmail /> to request that we remove this
    information from our database.
    <br />
    <br />
    <strong>4. Ordering.</strong> Forms to request to borrow, lend, lease or
    rent Equipment through the Services may gather the following data from
    users: contact information such as name and address; payment method such as
    credit card number, expiration date, billing address, and billing phone
    number. This information is used to bill users for certain fees to use the
    Services, and to pair requests from Renters with applicable Owners. If we
    encounter difficulty when processing a request to borrow, lend, lease or
    rent Equipment through the Services, this contact information is used to
    contact the user. Payment method information is stored in a secure area on
    our systems or the systems of a third party payment processor and encrypted
    for protection. Other than with a third party payment processor, it is not
    sold or shared with anyone else under any circumstances.
    <br />
    <br />
    <strong>5. Tracking Technologies.</strong> We and our partners use cookies
    or similar technologies to analyze trends, administer the Company Sites,
    track users’ movements around the Company Sites and to gather demographic
    information about our user base as a whole. We may receive reports based on
    the use of these technologies on an individual as well as aggregated basis.
    We use cookies to remember users’ settings and certain preferences and for
    authentication. Users can control the use of cookies at the individual
    browser level. If you reject cookies, you may still use the Company Sites,
    but your ability to use some features or areas of the Company Sites may be
    limited.
    <br />
    <br />
    <strong>6. Behavioral Advertising.</strong> We may partner with one or more
    third parties to either display advertising on the Company Sites or to
    manage our advertising on other sites. Our third -party partners may use
    technologies such as cookies to gather information about your activities on
    the Company Sites and other sites in order to provide you advertising based
    upon your browsing activities and interests.
    <br />
    <br />
    <strong>7. Log Files.</strong> As is true of most web sites, we gather
    certain information automatically and store it in log files. This
    information may include internet protocol (IP) addresses, browser type,
    internet service provider (ISP), referring/exit pages, operating system,
    date/time stamp, and/or clickstream data. We may combine this automatically
    collected log information with other information we collect about you. We do
    this to improve services we offer you, to improve marketing, analytics, or
    site functionality.
    <br />
    <br />
    <strong>8. Sharing.</strong> We will not sell, share, or rent personally
    identifiable information about you to others in any way that varies from
    what is disclosed in this Privacy Policy.
    <br />
    <br />
    We will share aggregated demographic information with our business partners
    and advertisers. This is not linked to any personally identifiable
    information that can identify any individual person. We may be compensated
    for sharing this information.
    <br />
    <br />
    We may also share your physical address with carefully selected business
    partners. These businesses may use the information we share to mail you
    valuable opportunities and offers. We may be compensated for sharing this
    information. You can opt out of future postal mailings from our business
    partners by sending an email to <CompanyEmail />.
    <br />
    <br />
    We use credit card processing companies to bill users for fees associated
    with use of the Services. These companies do not retain, share, store or use
    personally identifiable information for any secondary purposes. We value
    your privacy, and will never release any Account information to anyone
    outside of the necessary core groups that help our business function unless
    our customers give us the authority to do so.
    <br />
    <br />
    <strong>9. Legal Disclosures.</strong> We reserve the right to disclose your
    personally identifiable information as required by law and when we believe
    that disclosure is necessary to protect our rights and/or to comply with a
    judicial or bankruptcy proceeding, court order, or legal process served on
    us, or when we believe in good faith that disclosure is necessary to protect
    our rights, protect your safety or the safety of others, investigate fraud,
    or respond to a government request, and to any other third party with your
    prior consent to do so. Although the Company assumes no responsibility for
    the borrowing, lending, leasing or renting of Equipment between Renters and
    Owners, the Company may disclose your personally identifiable information as
    a Renter or Owner to the Owner or Renter, respectively, to whom or from whom
    you desire to or have borrowed, loaned, leased or rented Equipment in order
    for such other party to recover possession of the subject Equipment or seek
    redress against you for any loss of or damage to the subject Equipment.
    <br />
    <br />
    <strong>10. Links.</strong> The Company Sites contain links to other
    websites. Please be aware that the Company is not responsible for the
    privacy practices of other sites. We encourage our users to be aware when
    they leave the Company Sites and to read the privacy statements of each
    website that collects personally identifiable information. This Privacy
    Policy applies solely to information collected by the Company Sites.
    <br />
    <br />
    <strong>11. Social Media Widgets.</strong> Certain of the Company Sites
    include social media features (e.g. the Facebook Like button or other
    widgets or interactive mini-programs that run on the Company Sites). These
    features may collect your IP address, which page you are visiting on a
    Company Site, and may set a cookie to enable the feature to function
    properly. Social media features and widgets are either hosted by a third
    party or hosted directly on the Company Sites. Your interactions with these
    features are governed by the privacy policy of the company providing it.
    <br />
    <br />
    <strong>12. Single Sign On.</strong> Certain of the Company Sites may allow
    you to log in using sign-in services such as Facebook Connect or an Open ID
    provider. These services will authenticate your identity and provide you the
    option to share certain personally identifiable information with us such as
    your name and email address to pre-populate certain forms. Services like
    Facebook Connect give you the option to post information about your
    activities on certain of the Company Sites to your profile page to share
    with others within your network.
    <br />
    <br />
    <strong>13. Emails.</strong> If a user wishes to subscribe to our emails, we
    ask for contact information such as name and email address. We may use your
    contact information for purposes other than sending you promotional
    materials. For example, we may use your information to complete transactions
    requested by you, or to send you administrative communications about any
    Account you may have with us or about future changes to this Privacy Policy.
    You may also unsubscribe from promotional emails by emailing us at{' '}
    <CompanyEmail />.
    <br />
    <br />
    <strong>14. Security.</strong> When the Company Sites ask users to enter
    sensitive information (such as credit card number), that information is
    encrypted with encryption software that is generally accepted in the
    industry. On a secure page, the lock icon on the browsers switches to
    locked, as opposed to unlocked mode, and back to open mode when you move to
    a non-secure page. We also do everything in our power to protect user data
    offline. Only employees who need the information to perform a specific job
    (for example, a customer service representative) are granted access to
    personally identifiable information, and are required to lock their
    workstation computer when they leave their workstation. All employees are
    kept up to date on our security and privacy practices. Periodically and
    whenever new policies are implemented, our employees are reminded about the
    importance of keeping customer data secure and safe at all times. We may
    store personally identifiable information with third party cloud services
    providers who employ industry standard security measures. If you have any
    questions about the security at our website, you can send an email to{' '}
    <CompanyEmail />.
    <br />
    <br />
    <strong>15. Additional Information Regarding California.</strong>
    <br />
    <br />
    Pursuant to the California Consumer Privacy Act of 2018 (“CCPA”), we are
    providing the following additional details regarding the categories of
    personal information about California residents that we have collected or
    disclosed within the preceding 12 months:
    <br />
    <br />
    <strong>(1)</strong> We collected the following categories of personal
    information:
    <br />
    <br />
    <strong>A.</strong> Identifiers, such as name, contact information and
    online identifiers;
    <br />
    <strong>B.</strong> Personal information, as defined in the California
    customer records law, such as name, contact information, signature and
    payment card number;
    <br />
    <strong>C.</strong> Commercial information, such as transaction information
    and purchase history;
    <br />
    <strong>D.</strong> Internet or network activity information, such as
    browsing history and interactions with the Company Sites;
    <br />
    <strong>E.</strong> Geolocation data, such as device location and IP
    location; and
    <br />
    <strong>F.</strong> Inferences drawn from any of the personal information
    listed above to create a profile about, for example, an individual’s
    preferences and characteristics.
    <br />
    <br />
    As described above, we collect this personal information from you and from
    other categories of sources, including publicly available databases and
    joint marketing partners, when they share the information with us. Also as
    described above, we may use this personal information to operate, manage,
    and maintain our business, to provide our products and services, and to
    accomplish our business purposes and objectives, including, for example,
    using personal information to develop, improve, repair, and maintain our
    products and services; personalize, advertise, and market our products and
    services; conduct research, analytics, and data analysis; maintain our
    facilities and infrastructure; undertake quality and safety assurance
    measures; conduct risk and security control and monitoring; detect and
    prevent fraud; perform identity verification; perform accounting, audit, and
    other internal functions, such as internal investigations; comply with law,
    legal process, and internal policies; maintain records; and exercise and
    defend legal claims.
    <br />
    <br />
    <strong>(2)</strong> We disclosed the following personal information to
    third parties for our operational business purposes:
    <br />
    <br />
    <strong>A.</strong> Identifiers, such as name, contact information and
    online identifiers;
    <br />
    <strong>B.</strong> Personal information, as defined in the California
    customer records law, such as name, contact information, signature and
    payment card number;
    <br />
    <strong>C.</strong> Commercial information, such as transaction information
    and purchase history;
    <br />
    <strong>D.</strong> Internet or network activity information, such as
    browsing history and interactions with the Company Sites;
    <br />
    <strong>E.</strong> Geolocation data, such as device location and IP
    location; and
    <br />
    <strong>F.</strong> Inferences drawn from any of the personal information
    listed above to create a profile about, for example, an individual’s
    preferences and characteristics. We share personal information with our
    business partners.
    <br />
    <br />
    <strong>(3)</strong> We have not “sold” any of the foregoing personal
    information for purposes of the CCPA. For purposes of this Privacy Policy,
    “sold” or “sale” means the disclosure of personal information for monetary
    or other valuable consideration but does not include, for example, the
    transfer of personal information as an asset that is part of a merger,
    bankruptcy, or other disposition of all or any portion of our business.
    <br />
    <br />
    <strong>If you are a California resident, you may request that we:</strong>
    <br />
    <br />
    <strong>
      (1) Disclose to you the following information covering the 12 months
      preceding your request:
    </strong>
    <br />
    <br />
    <strong>o</strong> The categories of personal information we collected about
    you and the categories of sources from which we collected such personal
    information;
    <br />
    <br />
    <strong>o</strong> The specific pieces of personal information we collected
    about you;
    <br />
    <br />
    <strong>o</strong> The business or commercial purpose for collecting
    personal information about you; and
    <br />
    <br />
    <strong>o</strong> The categories of personal information about you that we
    otherwise shared or disclosed, and the categories of third parties with whom
    we shared or to whom we disclosed such personal information.
    <br />
    <br />
    <strong>(2) Delete personal information we collected from you.</strong> To
    make a request for the disclosures or deletion described above, please
    contact us at <CompanyEmail /> or by phone at 510) 982-6146. We will respond
    to your request consistent with applicable law. You have the right to be
    free from unlawful discrimination for exercising your rights under the CCPA.
    <br />
    <br />
    <strong>16. Sale of Our Company.</strong> In the event that another company
    acquires us or a significant portion of our assets is sold to another
    company, all customer data, including financial records and consumer
    information collected on the Company Sites, may be transferred to the new
    company. You will be notified via of any such change in ownership or control
    of your personally identifiable information.
    <br />
    <br />
    <strong>17. Modification.</strong> The Company reserves the right to modify
    or augment any of the terms and conditions of this Privacy Policy, at any
    time, at our sole discretion. You should periodically review this Privacy
    Policy, accessible from the Company Sites, for any modifications. All
    modifications will become effective upon their posting on the Company Sites,
    but no modification will affect your privacy rights prior to the
    modifications becoming effective. Your continued use of the Company Sites or
    your Account following any notification will be confirmation of your
    acceptance of such changes.
    <br />
    <br />
    <strong>
      PLEASE PRINT A COPY OF THIS PRIVACY POLICY FOR YOUR RECORDS.
    </strong>{' '}
    If you are unable to print from your device, please contact the Company at{' '}
    <CompanyEmail /> to request a hard copy of this Privacy Policy.
  </p>
);
export { termsOfService, privacyPolicy };
