import { Button } from '@mui/material';
import { Typography } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  member: any;
  productId: number;
};

const SignUpLoginButtonCombo = ({ member, productId }: Props) => {
  const { pathname } = useLocation();
  const routerState = {
    from: pathname,
  };
  return (
    <div
      style={{
        border: '0.5px solid lightgrey',
        borderRadius: 4,
        justifyContent: 'center',
        padding: 12,
      }}
    >
      <Typography style={{ fontSize: 16 }}>
        Please{' '}
        <Link to={{ pathname: '/login', state: routerState }}>login</Link> or{' '}
        <Link to={{ pathname: '/register', state: routerState }}>
          create an account
        </Link>{' '}
        to rent from {member}
      </Typography>
    </div>
  );
};

export default SignUpLoginButtonCombo;
