import useLoggedInMemberInfo from '../hooks/useLoggedInMemberInfo';

export function formatLocationText(value) {
  switch (value) {
    case USER_LOCATIONS.BayArea:
      return 'Bay Area';
    case USER_LOCATIONS.Nashville:
      return 'Nashville';
    case USER_LOCATIONS.VetAdventureGroup:
      return `Veteran's Adventure Group`;
    case USER_LOCATIONS.Stanford:
      return `Stanford University`;
    default:
      return 'Selecting . . .';
  }
}
export const useFormattedLocation = () => {
  const { userLocation } = useLoggedInMemberInfo();

  return formatLocationText(userLocation);
};

const USER_LOCATIONS = {
  Nashville: 'nashville',
  BayArea: 'bay-area',
  Stanford: 'stanford',
  VetAdventureGroup: 'veterans-adventure-group',
};

export default USER_LOCATIONS;
