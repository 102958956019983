import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const orderManager = {
  getUserOrders() {
    return fetch(`${baseUrl}/orders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
  /**
   * create order request object format
   * {
   *  duration: 0,
   *  lender_id: 0
   * }
   */
  createOrder(request) {
    return fetch(`${baseUrl}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(request),
    }).then((resp) => resp.json());
  },
  updateOrder(updatedOrder) {
    return fetch(`${baseUrl}/orders/${updatedOrder.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(updatedOrder),
    });
  },
  deleteOrder(orderId) {
    return fetch(`${baseUrl}/orders/${orderId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  },
};

export default orderManager;
