import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ProductAPI from '../../modules/productManager';
import messagingAPI from '../../modules/messagingModules/messaging';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import snapToTopOfPage from '../../helpers/snapToTopOfPage';
import './Messages.css';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  TextareaAutosize,
} from '@mui/material';
import { Close, Forum, Send } from '@mui/icons-material';
import { Product } from '../../types/product.types';
import { useMediaQuery } from 'react-responsive';

interface Props {
  isQuickMessageVisible: boolean;
  closeQuickMessage: () => {};
  productId: number;
  productTitle: string;
}

export const QuickMessage = ({
  isQuickMessageVisible,
  closeQuickMessage,
  productId,
  productTitle,
}: Props) => {
  const [product, setProduct] = useState<Product>({
    id: 0,
    member_id: 0,
    price: 0,
    title: '',
    image_paths: [''],
    member: {
      user: {
        first_name: '',
      },
    },
  });
  const [message, setMessage] = useState('');

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const history = useHistory();

  const createChannelName = (
    renterId: number | string,
    lenderId: number | string
  ) => {
    const sortedIds = [Number(renterId), Number(lenderId)].sort();
    const channelName = `channel-${sortedIds[0]}-${sortedIds[1]}`;
    return channelName;
  };

  const handleSendMessage = () => {
    messagingAPI.getMessageGroups().then((messageGroups) => {
      const renterId = localStorage.getItem(LOCAL_STORAGE.memberId);
      const lenderId = product.member_id;

      // TypeScript Narrowing: https://www.typescriptlang.org/docs/handbook/2/narrowing.html
      if (!renterId || !lenderId) {
        throw new Error("No renter ID or lender ID. Can't create channel");
      }

      // if the user does not have any conversations
      if (messageGroups.length === 0)
        createMessageGroupAndSendMessage(
          parseInt(renterId),
          lenderId,
          product.id
        );

      // check if message group exists
      for (let i = 0; i < messageGroups.length; i++) {
        if (
          (messageGroups[i].member1_id == renterId &&
            messageGroups[i].member2_id == product.member_id) ||
          (messageGroups[i].member1_id == product.member_id &&
            messageGroups[i].member2_id == renterId)
        ) {
          // message group found
          const newMessage = {
            channel: messageGroups[i].name,
            message: message,
          };
          messagingAPI.createMessage(newMessage);
          break;
        } else {
          createMessageGroupAndSendMessage(
            parseInt(renterId),
            lenderId,
            product.id
          );
          break;
        }
      }
      setMessage('');
      closeQuickMessage();
    });
  };

  function createMessageGroupAndSendMessage(
    renterId: number,
    lenderId: number,
    productId: number
  ) {
    const channelName = createChannelName(renterId, lenderId);
    const newMessageGroup = {
      channel: channelName,
      product_id: productId,
    };
    messagingAPI.createMessageGroup(newMessageGroup).then((res) => {
      const newMessage = {
        channel: channelName,
        message: message,
      };
      messagingAPI.createMessage(newMessage);
    });
  }

  const setPredefinedMessage = (message: string) => {
    setMessage(message);
    if (textareaRef.current != null) {
      textareaRef.current.value = message;
    }
  };

  const goToMessages = () => {
    const renterId = localStorage.getItem(LOCAL_STORAGE.memberId);
    history.push(`/messages?direct=${true}`, {
      memberId: renterId,
      lenderId: product.member_id,
      product_id: product.id,
      product: product,
      messagePrompt: true,
    });
    snapToTopOfPage();
  };

  useEffect(() => {
    // get product data on initial component load
    if (isQuickMessageVisible) {
      ProductAPI.getProduct(productId).then((product) => {
        setProduct(product);
      });
    }
  }, [isQuickMessageVisible]);
  const handleClose = () => {
    setMessage('');
    closeQuickMessage();
  };

  const defaultMessage = `Hey ${product.member.user.first_name}, is ${
    productTitle ? `the ${productTitle}` : 'this item'
  } still available for rent?`;
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <Dialog
      open={isQuickMessageVisible}
      onClose={handleClose}
      PaperProps={{ sx: { width: 500 } }}
      fullScreen={isMobile}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Message {product.member.user.first_name}</div>
          <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="quick-message-product-display">
          <div className="quick-message-image-container">
            <img
              className="quick-message-product-image"
              src={product.image_paths[0]}
            ></img>
          </div>
          <div className="quick-message-product-details">
            <div>{product.title}</div>
            <div>${product.price}</div>
          </div>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Button
            variant="contained"
            sx={{ borderRadius: 20 }}
            color="inherit"
            onClick={() =>
              setPredefinedMessage(
                `${defaultMessage}`
                // `${product.title} $${product.price} - ${defaultMessage}`
              )
            }
          >
            {defaultMessage}
          </Button>
        </div>
        <TextareaAutosize
          className="quick-message-textarea"
          minRows={3}
          placeholder="Message the lender to rent this item..."
          onChange={(e) => setMessage(e.target.value)}
          ref={textareaRef}
        ></TextareaAutosize>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={goToMessages}
          sx={{ color: 'black' }}
          variant="outlined"
          startIcon={<Forum />}
        >
          messages
        </Button>
        <div>
          <Button onClick={handleClose} sx={{ color: 'black' }}>
            cancel
          </Button>
          <Button
            onClick={handleSendMessage}
            variant="contained"
            endIcon={<Send />}
            disabled={!message}
          >
            send
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
