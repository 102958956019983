import React from 'react';
import CompanyEmail from '../CompanyEmail';
import './ContactPage.css';

export default function ContactPage() {
  return (
    <div id="contact-us-container">
      <h1>
        Have any questions? <br /> Feel free to reach us here!
      </h1>
      <h3>
        <strong>Email:</strong> <CompanyEmail />
      </h3>
      <h3>
        <strong>Phone:</strong> (510) 982-6146
      </h3>
    </div>
  );
}
