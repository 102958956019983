import React from 'react';
import useLoggedInMemberInfo from '../hooks/useLoggedInMemberInfo';

const withNoAuth = (Component) => {
  const WithNoAuthWrapper = (props) => {
    const { isLoggedIn } = useLoggedInMemberInfo();
    if (!isLoggedIn) {
      return <Component {...props} />;
    } else return <div />;
  };

  WithNoAuthWrapper.displayName = `withNoAuth(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithNoAuthWrapper;
};

export default withNoAuth;
