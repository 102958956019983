import { LOCAL_STORAGE } from '../../constants/localStorage';

const isStanfordEmail = () => {
  const userEmail = localStorage.getItem(LOCAL_STORAGE.email);
  if (isWhiteList(userEmail)) {
    return true;
  }

  return false;
};

const isWhiteList = (email: string | null) => {
  const emailWhiteList = /(@stanford.edu|@gear2go.us)/g;
  const userWhiteList = [
    'chasef7031@gmail.com',
    'rdbishop06+prod01@gmail.com',
    'jeremyamattingly@gmail.com',
    'tanvi.g.nayak@gmail.com',
    'katielizwhite@gmail.com',
  ];

  if (!email) {
    return false;
  }
  if (email.match(emailWhiteList)) {
    return true;
  }
  if (userWhiteList.includes(email)) {
    return true;
  }
  return false;
};

export default isStanfordEmail;
