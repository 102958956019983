import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'antd';

import ProductReviewAPI from '../../../modules/ProductReviewManager';
import ProductManager from '../../../modules/productManager';
import useSearchParams from '../../../hooks/useSearchParams';
import StarRating from '../../StarRating/StarRating';
import useScrollToTop from '../../../hooks/useScrollToTop';
import ProductQuickInfo from './ProductQuickInfo';

import './ProductReview.css';

const CreateProductReview = () => {
  useScrollToTop();
  const history = useHistory();
  const { id: productId } = useSearchParams();
  const [productDetails, setProductDetails] = React.useState();
  const [rating, setRating] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const getProductDetails = async () => {
    const resp = await ProductManager.getProductDetail(productId);
    setProductDetails(resp);
  };

  React.useEffect(() => {
    getProductDetails();
  }, [productId]);

  const handleRatingChange = (newRating) => {
    setError(false);
    setRating(newRating);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!rating) {
      setError(true);
    }
    setLoading(true);
    const newReview = {
      product_id: productDetails.id,
      rating,
      title,
      description,
    };
    await ProductReviewAPI.postProductReview(newReview);
    setLoading(false);
    history.push(`/rent/${productDetails.id}`);
  };

  const goToProductPage = () => {
    if (window.confirm('You have unsaved changes. Proceed?')) {
      history.push(`/rent/${productDetails.id}`);
    }
  };
  if (!productDetails) {
    return <div />;
  }

  return (
    <div className="review-center">
      <div className="review-header">Create Review</div>
      <ProductQuickInfo
        product={productDetails}
        handleOnClick={goToProductPage}
      />
      <div style={{ marginTop: 16 }}>
        <form onSubmit={handleSubmit}>
          <div className="header">Rating</div>
          {error && (
            <div
              style={{ border: '2px solid red', borderRadius: 4, padding: 8 }}
            >
              Please rate this product
            </div>
          )}
          <StarRating
            editable
            style={{ fontSize: 36 }}
            rating={rating}
            setRating={handleRatingChange}
          />
          <div className="flex-column">
            <label htmlFor="title" className="header">
              Title
            </label>
            <input
              type="text"
              name="title"
              className="input-text"
              value={title}
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </div>
          <div className="flex-column">
            <label htmlFor="description" className="header">
              Description
            </label>
            <textarea
              name="description"
              className="input-textarea"
              rows={5}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </div>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              color: error ? 'grey' : 'black',
              backgroundColor: error ? 'lightgrey' : 'lightgreen',
              borderColor: error ? 'grey' : 'black',
              marginTop: 8,
              height: isMobile ? 48 : undefined,
              float: isMobile ? 'none' : 'right',
            }}
            disabled={loading || error}
            loading={loading}
            block={isMobile}
          >
            SUBMIT
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreateProductReview;
