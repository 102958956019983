import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import HomePage from './homePage/HomePage';
import Login from './auth/Login';
import Register from './auth/Register';
import ProductDetail from './buy/ProductDetail';
import ProductsMain from './buy/Products';
import Profile from './userProfile/Profile';
import Sell from './sell/Sell';
import Faq from './faq/Faq';
import Instructions from './instructions/Instructions';
import PaymentTypeForm from './userProfile/PaymentTypeForm';
import OrdersList from './orders/OrdersList';
import PaymentForm from './orders/PaymentForm';
import MessagesMain from './messages/MessagesMain';
import RerouteComponent from './RerouteMessage';
import OtherMemberProfile from './userProfile/OtherMemberProfile';
import EmailVerify from './auth/EmailVerify';
import VerifyAccountPage from './auth/VerifyAccountPage';
import PasswordResetRequest from './auth/PasswordResetRequest';
import Safety from './safety/SafetyPage';
import GearRequestForm from './gearRequest/GearRequestForm';
import GearRequest from './gearRequest/GearRequest';
import RentRequest from './rentRequest/RentRequest';
import StripeVerify from './payments/StripeVerify';
import PasswordReset from './auth/PasswordReset';
import CreateProductReview from './buy/ProductReviews/CreateReview';
import EditProductReview from './buy/ProductReviews/EditReview';
import CreateLenderReview from './userProfile/LenderReviews/CreateLenderReview';
import EditLenderReview from './userProfile/LenderReviews/EditReview';
import CreateRenterReview from './userProfile/RenterReviews/CreateRenterReview';
import EditRenterReview from './userProfile/RenterReviews/EditRenterReview';
import { privacyPolicy } from '../constants/termsOfService';
import ContactPage from './contact/ContactPage';
import navLogo from '../images/G2G_light_green_transparent_background-01-01.png';
import TOS from './faq/TermsConditions';
import { Checkout } from '../components/payments/Checkout';
import useLoggedInMemberInfo from '../hooks/useLoggedInMemberInfo';
import routes from './routes.enum';
import MyGear from './userProfile/MyGear';

export const Routes = {
  OtherMember: 'othermember',
};

const BodyRouter = ({
  props,
  setLocationBoolean,
  searchTerm,
  locationBoolean,
  changeSearchTerm,
  onesignalInitialized,
}) => {
  const history = useHistory();
  const { token } = useLoggedInMemberInfo();
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(routerProps) => {
          return (
            <HomePage
              routerProps={routerProps}
              setLocationBoolean={setLocationBoolean}
              searchTerm={searchTerm}
              locationBoolean={locationBoolean}
              changeSearchTerm={changeSearchTerm}
              onesignalInitialized={onesignalInitialized}
            />
          );
        }}
      />
      <Route
        exact
        path="/login"
        render={(routerProps) => {
          return (
            <Login
              routerProps={routerProps}
              onesignalInitialized={onesignalInitialized}
            />
          );
        }}
      />
      <Route
        exact
        path="/register"
        render={(routerProps) => {
          return <Register routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/rent/:productId(\d+)"
        render={(routerProps) => {
          return (
            <ProductDetail
              productId={parseInt(routerProps.match.params.productId)}
              routerProps={routerProps}
            />
          );
        }}
      />
      <Route
        exact
        path="/rent"
        render={(routerProps) => {
          return (
            <ProductsMain
              routerProps={routerProps}
              locationBoolean={locationBoolean}
              setLocationBoolean={setLocationBoolean}
              searchTerm={searchTerm}
              changeSearchTerm={changeSearchTerm}
            />
          );
        }}
      />
      <Route
        exact
        path="/lend"
        render={(routerProps) => {
          if (token) {
            return <Sell routerProps={routerProps} />;
          } else {
            return <Login routerProps={routerProps} />;
          }
        }}
      />
      <Route
        exact
        path="/myprofile"
        render={(routerProps) => {
          return <Profile routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/newpayment"
        render={(routerProps) => {
          return <PaymentTypeForm routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path={routes.myGear}
        render={(routerProps) => {
          return <MyGear routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/currentorder"
        render={(routerProps) => {
          return <OrdersList routerProps={routerProps} />;
        }}
      />

      <Route
        exact
        path="/orderpayment"
        render={(routerProps) => {
          return <PaymentForm routerProps={routerProps} />;
        }}
      />
      <Route
        path="/messages"
        render={(routerProps) => {
          return token ? (
            <MessagesMain routerProps={routerProps} />
          ) : (
            history.push('/login')
          );
        }}
      />
      <Route
        exact
        path="/faqs"
        render={(routerProps) => {
          return <Faq routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/tos"
        render={(routerProps) => {
          return <TOS routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/instructions"
        render={(routerProps) => {
          return <Instructions routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/safety"
        render={(routerProps) => {
          return <Safety routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path={`/${Routes.OtherMember}/:id(\\d+)`}
        render={(routerProps) => {
          if (token) {
            return <OtherMemberProfile routerProps={routerProps} />;
          } else {
            return <Login routerProps={routerProps} />;
          }
        }}
      />
      <Route
        exact
        path="/account-verify/:token"
        render={(routerProps) => {
          return <EmailVerify routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/account-verify"
        render={(routerProps) => {
          return <VerifyAccountPage routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/reset-request"
        render={(routerProps) => {
          return <PasswordResetRequest routerProps={routerProps} />;
        }}
      />
      <Route
        path="/password-reset/:uidb64/:token"
        render={(routerProps) => {
          return <PasswordReset routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/gear-request-form"
        render={(routerProps) => {
          return <GearRequestForm routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/gear-requests"
        render={(routerProps) => {
          if (token) {
            return <GearRequest routerProps={routerProps} />;
          } else {
            return <Login routerProps={routerProps} />;
          }
        }}
      />
      <Route
        exact
        path="/rent-form"
        render={(routerProps) => {
          return <RentRequest routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/return-from-stripe/:memberId"
        render={(routerProps) => {
          return <StripeVerify routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/checkout"
        render={(routerProps) => {
          return <Checkout routerProps={routerProps} />;
        }}
      />
      <Route
        exact
        path="/review/product/create"
        render={() => <CreateProductReview />}
      />
      <Route
        exact
        path="/review/product/edit"
        render={() => <EditProductReview />}
      />
      <Route
        exact
        path="/review/lender/create"
        render={() => <CreateLenderReview />}
      />
      <Route
        exact
        path="/review/lender/edit"
        render={() => <EditLenderReview />}
      />
      <Route
        exact
        path="/review/renter/create"
        render={() => <CreateRenterReview />}
      />
      <Route
        exact
        path="/review/renter/edit"
        render={() => <EditRenterReview />}
      />
      <Route exact path="/contact" render={() => <ContactPage />} />
      <Route exact path="/privacy" render={() => privacyPolicy} />
      <Route component={RerouteComponent} />
    </Switch>
  );
};

export default BodyRouter;
