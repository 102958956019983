import React from 'react';
import { Link } from 'react-router-dom';

const RerouteMessage = () => {
  return (
    <div
      className="reroute-container"
      style={{ textAlign: 'center', width: '75%', margin: '100px auto' }}
    >
      <h1>
        This page does not exist . . . go <a href="/">home</a>
      </h1>
    </div>
  );
};

export default RerouteMessage;
