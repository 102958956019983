import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

const orderProductManager = {
  getOrderProductsByOrder(orderId) {
    return fetch(`${baseUrl}/orderproducts?order_id=${orderId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
  // createOrderProduct request structure
  // {
  //   order_id: 0,
  //   products: [
  //     {
  //       id: 0,
  //       quantity: 0
  //     },
  //   ]
  // }
  createOrderProducts(request) {
    return fetch(`${baseUrl}/orderproducts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(request),
    }).then((resp) => resp.json());
  },
  deleteOrderProduct(orderProductId) {
    return fetch(`${baseUrl}/orderproducts/${orderProductId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  },
  // Using to get the gearbag for the member
  getMemberGearBag(memberId) {
    return fetch(`${baseUrl}/orderproducts/${memberId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
};

export default orderProductManager;
