import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Menu, Dropdown } from 'antd';
import {
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
  SmileOutlined,
  UnorderedListOutlined,
  DownOutlined,
  MailOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import './Navigation.css';
import GearBag from '../../images/gearbag.png';
import logo from '../../images/logo-horizontal/G2G_black_horizontal_transparent-01.png';
import smallLogo from '../../images/G2G_seal_black-01.png';
import messagingAPI from '../../modules/messagingModules/messaging';
import ProductNavbar from '../buy/ProductNavbar';
import profileIcon from '../../images/profileIcon.png';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import { useFormattedLocation } from '../../constants/locations';
import { LOCAL_STORAGE } from '../../constants/localStorage';

const { Header } = Layout;

const Navbar = ({
  locationBoolean,
  setLocationBoolean,
  changeSearchTerm,
  routerProps,
}) => {
  const [current, changeSelected] = useState();
  const [unreadMessageCount, setUnreadMessageCount] = useState(null);
  const [classNameForNavBarLinks, _setClassNameForNavBarLinks] =
    useState('navMenu');

  // ref required for getting current state in event listener - chase fite 4/20/23
  const classNameForNavBarLinksRef = useRef(classNameForNavBarLinks);
  function setClassNameForNavBarLinks(className) {
    classNameForNavBarLinksRef.current = className;
    _setClassNameForNavBarLinks(className);
  }

  const { memberId, token } = useLoggedInMemberInfo();
  const userLocation = useFormattedLocation();
  const history = useHistory();

  function checkUnreadMessages() {
    messagingAPI.checkUnreadMessages(memberId).then((resp) => {
      if (resp.status) {
        setUnreadMessageCount(resp.unread_count);
      } else {
        setUnreadMessageCount(null);
      }
    });
  }

  const handleClick = (e) => {
    changeSelected(e.key);
    if (e.key && e.key !== 'location') {
      routerProps.history.push(`/${e.key}`);
    }
  };

  const navBarConditional = () => {
    if (window.location.pathname === '/') {
      return null;
    } else {
      return (
        <ProductNavbar
          routerProps={routerProps}
          changeSearchTerm={changeSearchTerm}
        />
      );
    }
  };

  function logout() {
    localStorage.clear();
    setTimeout(() => {
      history.push('/');
    }, 500);
  }

  const loginConditional = () => {
    function login() {
      routerProps.history.push('/login');
    }

    if (token !== null) {
      return (
        <Menu
          selectedKeys={[current]}
          onClick={handleClick}
          inlineCollapsed={false}
        >
          <Menu.Item
            key="mygear"
            icon={<img src={GearBag} className="gear-bag-image-navbar" />}
            onClick={handleClick}
          >
            My Gear
          </Menu.Item>
          {/* <Menu.Item key="currentorder" icon={<AppstoreAddOutlined />} href='orders'>
            My Orders
          </Menu.Item> */}
          <Menu.Item
            key="myprofile"
            icon={<UserOutlined />}
            onClick={handleClick}
          >
            My Profile
          </Menu.Item>
          <Menu.Item
            key="messages"
            icon={<MailOutlined />}
            onClick={handleClick}
            className={unreadMessageCount ? 'unread-messages-menu-item' : null}
          >
            Messages {unreadMessageCount ? `(${unreadMessageCount})` : null}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="gear-requests" href="/request">
            Gear Requests
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            className="medium-menu-item-show"
            key="faqs"
            icon={<QuestionCircleOutlined />}
            onClick={handleClick}
          >
            FAQ
          </Menu.Item>
          <Menu.Item
            className="medium-menu-item-show"
            key="instructions"
            icon={<UnorderedListOutlined />}
            onClick={handleClick}
          >
            Instructions
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            className="medium-menu-item-show"
            key="location"
            icon={<GlobalOutlined />}
            onClick={(e) => {
              setLocationBoolean(false);
            }}
          >
            {userLocation}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={logout}>Logout</Menu.Item>
        </Menu>
      );
    } else {
      return (
        <Menu>
          <Menu.Item icon={<SmileOutlined />} onClick={login}>
            Login
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="faqs"
            icon={<QuestionCircleOutlined />}
            onClick={handleClick}
          >
            FAQ
          </Menu.Item>
          <Menu.Item
            key="instructions"
            icon={<UnorderedListOutlined />}
            onClick={handleClick}
          >
            Instructions
          </Menu.Item>
          <Menu.Divider />
        </Menu>
      );
    }
  };

  // function productSearchConditional(){
  //   const searchProduct = (
  //     <Menu>
  //       <Menu.Item>
  //     <ProductNavbar changeSearchTerm={changeSearchTerm} setLocationBoolean={setLocationBoolean} locationBoolean={locationBoolean} routerProps={routerProps}/>
  //     </Menu.Item>
  //     </Menu>
  //   );
  //
  //   if(window.innerWidth < 1050){
  //     return (
  //       <Dropdown overlay={searchProduct} placement="bottomCenter" trigger={['click']}>
  //         <img className="profileIcon" src={searchIcon}/>
  //       </Dropdown>
  //     )
  //   } else {
  //     return searchProduct
  //   }
  // }

  const smallMenu = (
    <Menu
      className="small-dropdown"
      onClick={handleClick}
      selectedKeys={[current]}
      mode="inline"
    >
      <Menu.Item key="rent" className="small-nav-item">
        Rent
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="lend" className="small-nav-item">
        Lend
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="gear-request-form" href="/request">
        Gear Requests
      </Menu.Item>
      <Menu.Divider />
      {memberId ? (
        <>
          <Menu.Item
            key="messages"
            icon={
              <MailOutlined
                style={{ color: unreadMessageCount ? '#ff4d4f' : 'black' }}
              />
            }
            href="/messages"
            className="small-nav-item"
          >
            Messages {unreadMessageCount ? `(${unreadMessageCount})` : null}
          </Menu.Item>
          <Menu.Divider />
        </>
      ) : null}
      <Menu.Item
        className="medium-menu-item-show"
        key="location"
        icon={<GlobalOutlined />}
        onClick={(e) => {
          setLocationBoolean(false);
        }}
      >
        {userLocation}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="faqs"
        icon={<QuestionCircleOutlined />}
        href="/faqs"
        className="small-nav-item"
      >
        FAQ
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="instructions"
        icon={<UnorderedListOutlined />}
        href="/instructions"
        className="small-nav-item"
      >
        Instructions
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setTimeout(function () {
      loginConditional();
      if (token !== null) {
        checkUnreadMessages();
      }
    }, 100);
  }, [localStorage]);
  useEffect(() => {
    setTimeout(function () {
      if (token !== null) {
        checkUnreadMessages();
      }
    }, 300);
  }, [current]);

  function displayNavBarLinks() {
    if (window.innerWidth < 650) {
      if (
        history.location.pathname !== '/' &&
        classNameForNavBarLinksRef.current === 'navMenu'
      )
        setClassNameForNavBarLinks('hidden');
      else if (
        history.location.pathname === '/' &&
        classNameForNavBarLinksRef.current === 'hidden'
      )
        setClassNameForNavBarLinks('navMenu');
    } else if (classNameForNavBarLinksRef.current === 'hidden')
      setClassNameForNavBarLinks('navMenu');
  }

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      displayNavBarLinks();
    });
  }, []);

  useEffect(() => {
    displayNavBarLinks();
  }, [history.location.pathname]);

  return (
    <>
      <Header id="navContainer">
        <img
          id="navLogo"
          className="logo"
          src={logo}
          alt="Gear2Go Logo"
          onClick={() => routerProps.history.push('/')}
        />
        <img
          id="smallLogo"
          className="logo hidden"
          src={smallLogo}
          alt="Gear2Go Logo"
          onClick={() => routerProps.history.push('/')}
        />
        {/* <ProductNavbar changeSearchTerm={changeSearchTerm} setLocationBoolean={setLocationBoolean} locationBoolean={locationBoolean} routerProps={routerProps} /> */}
        {navBarConditional()}
        <div id="rightSideNavElements">
          <Menu
            className={classNameForNavBarLinks}
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
            style={{ minWidth: '288px' }}
          >
            <Menu.Item key="rent" id="menu-text">
              Rent
            </Menu.Item>
            <Menu.Item key="lend" id="menu-text" className="navbar-lend-link">
              Lend
            </Menu.Item>
            <Menu.Item
              className="medium-menu-item-hidden"
              key="gear-requests"
              href="/request"
              id="menu-text"
            >
              Request
            </Menu.Item>
          </Menu>
          <div style={{ position: 'relative' }}>
            <Dropdown
              overlay={loginConditional}
              placement="bottomRight"
              trigger={['click']}
            >
              <img
                className="profileIcon"
                src={profileIcon}
                alt="profile icon"
              />
            </Dropdown>
            {/* {unreadMessageCount ? (
              <p
                className="message-count-nav"
                src={RedExclamation}
                alt="unread-message"
              >
                {unreadMessageCount}
              </p>
            ) : null} */}
          </div>
        </div>
      </Header>
      <div className="small-nav">
        <Dropdown overlay={smallMenu} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <DownOutlined /> Menu <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </>
  );
};
export default Navbar;
