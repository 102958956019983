import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { urlConditional } from '../../modules/apiInfo';
import { getToken } from '../../constants/localStorage';
import './ReferAFriend.css';

const baseURL = urlConditional();

interface Props {
  display: boolean;
  handleClose: () => void;
}

export const ReferAFriend = ({ display, handleClose }: Props) => {
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  function handleInput(event: any) {
    setStatusMessage('');
    setEmail(event.target.value);
  }

  function handleReferral() {
    if (
      !email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    )
      setStatusMessage('Please enter a valid email address');
    else {
      fetch(`${baseURL}/refer-a-friend`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${getToken()}`,
        },
        body: JSON.stringify({ email: email }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.isSuccessful)
            setStatusMessage(
              'There was an error while submitting the email. Please try again later'
            );
          else setStatusMessage('Your referral has been submitted. Thank you');
        });
    }
  }

  return (
    <Dialog open={display} PaperProps={{ sx: { width: 500 } }}>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Refer your friends to Gear2Go</div>
          <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="refer-a-friend-content">
          <TextField
            className="refer-a-friend-content-textfield"
            label="Email"
            variant="outlined"
            onChange={(event) => handleInput(event)}
          ></TextField>
          <Button
            endIcon={<Send />}
            className="refer-a-friend-content-invite-button"
            variant="contained"
            onClick={handleReferral}
          >
            Send Invite
          </Button>
          <div className="refer-a-friend-status-message">{statusMessage}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
