import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import authManager from '../../modules/AuthManager.js';
import { Spin, message } from 'antd';
import './Auth.css';
import G2GLogo from '../../images/G2G_light_green_transparent_background-01-01.png';
import { LOCAL_STORAGE } from '../../constants/localStorage.js';
import OneSignal from 'react-onesignal';

const Login = ({ routerProps, onesignalInitialized }) => {
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [buttonClicked, changeButtonClicked] = useState(false);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const history = useHistory();
  const location = useLocation();

  const handleFieldChange = (evt) => {
    const stateToChange = { ...credentials };
    stateToChange[evt.target.id] = evt.target.value;
    setCredentials(stateToChange);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    const customerCreds = {
      email: credentials.email.toLowerCase(),
      password: credentials.password,
    };
    changeButtonClicked(true);
    return authManager.loginUser(customerCreds).then((parsedResponse) => {
      if (
        'valid' in parsedResponse &&
        parsedResponse.valid &&
        'token' in parsedResponse
      ) {
        if (parsedResponse.verified) {
          localStorage.setItem(LOCAL_STORAGE.token, parsedResponse.token);
          localStorage.setItem(
            LOCAL_STORAGE.memberId,
            parsedResponse.memberId.toString()
          );
          localStorage.setItem(LOCAL_STORAGE.email, customerCreds.email);
          setIsLoggedIn(true);
          if (location?.state?.from) {
            routerProps.history.push({
              pathname: location.state.from,
              state: { openModal: true },
            });
          } else if (window.location.href.includes('lend')) {
            routerProps.history.push('/lend');
            // window.location = '/lend';
          } else {
            routerProps.history.push('/');
            // window.location = '/';
          }
        } else {
          routerProps.history.push('./account-verify');
        }
      } else {
        message.error('Invalid email or password, please try again.');
        changeButtonClicked(false);
      }
    });
  };

  function buttonOrLoader() {
    if (buttonClicked) {
      return <Spin size="small" />;
    } else {
      return (
        <input
          className="btn"
          type="button"
          name=""
          value="Sign In"
          onClick={handleLogin}
        />
      );
    }
  }

  useEffect(() => {
    buttonOrLoader();
  }, [buttonClicked]);

  useEffect(() => {
    // allow login on enter key
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' && !buttonClicked) {
        event.preventDefault();

        handleLogin(event);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  return (
    <div className="login-box-main login-box">
      <img className="main-logo" src={G2GLogo} />
      <div className="textbox">
        <i className="fa fa-user" aria-hidden="true"></i>
        <input
          id="email"
          onChange={handleFieldChange}
          type="text"
          placeholder="Email"
          name=""
          value={credentials.email}
        />
      </div>
      <div className="textbox">
        <i className="fa fa-lock" aria-hidden="true"></i>
        <input
          id="password"
          onChange={handleFieldChange}
          type="password"
          placeholder="Password"
          name=""
          value={credentials.password}
        />
      </div>
      {buttonOrLoader()}
      <p
        className="register-link"
        onClick={() => routerProps.history.push('/register')}
      >
        Create Account
      </p>
      <p
        className="register-link"
        onClick={() => routerProps.history.push('/reset-request')}
      >
        Forgot Your Password?
      </p>
    </div>
  );
};

export default Login;
