import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'antd';

import LenderReviewManager from '../../../modules/LenderReviewManager';
import MemberManager from '../../../modules/customerManager';
import useSearchParams from '../../../hooks/useSearchParams';
import StarRating from '../../StarRating/StarRating';
import useScrollToTop from '../../../hooks/useScrollToTop';
import MemberQuickInfo from './MemberQuickInfo';
import useRouteOtherMember from '../../../helpers/routeOtherMember';

const CreateLenderReview = () => {
  useScrollToTop();
  const history = useHistory();
  const { id: lenderId } = useSearchParams();
  const [lenderDetails, setLenderDetails] = React.useState();
  const [rating, setRating] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const getLenderDetails = async () => {
    const resp = await MemberManager.getCustomer(lenderId);
    setLenderDetails(resp);
    console.log(`resp`, resp);
  };

  React.useEffect(() => {
    getLenderDetails();
  }, [lenderId]);

  const handleRatingChange = (newRating) => {
    setError(false);
    setRating(newRating);
  };

  const { routeOtherMember } = useRouteOtherMember();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!rating) {
      setError(true);
    }
    setLoading(true);
    const newReview = {
      lender_id: lenderId,
      rating,
      title,
      description,
    };
    await LenderReviewManager.postLenderReview(newReview);
    setLoading(false);
    routeOtherMember(lenderId);
  };

  const goToMemberPage = () => {
    if (window.confirm('You have unsaved changes. Proceed?')) {
      routeOtherMember(lenderId);
    }
  };
  if (!lenderDetails) {
    return <div />;
  }

  return (
    <div className="center">
      <div className="review-header">Create Review</div>
      <MemberQuickInfo member={lenderDetails} handleOnClick={goToMemberPage} />
      <div style={{ marginTop: 16 }}>
        <form onSubmit={handleSubmit}>
          <div className="header">Rating</div>
          {error && (
            <div
              style={{ border: '2px solid red', borderRadius: 4, padding: 8 }}
            >
              Please rate this lender
            </div>
          )}
          <StarRating
            editable
            style={{ fontSize: 36 }}
            rating={rating}
            setRating={handleRatingChange}
          />
          <div className="flex-column">
            <label htmlFor="title" className="header">
              Title
            </label>
            <input
              type="text"
              name="title"
              className="input-text"
              value={title}
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </div>
          <div className="flex-column">
            <label htmlFor="description" className="header">
              Description
            </label>
            <textarea
              name="description"
              className="input-textarea"
              rows={5}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </div>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              color: error ? 'grey' : 'black',
              backgroundColor: error ? 'lightgrey' : 'lightgreen',
              borderColor: error ? 'grey' : 'black',
              marginTop: 8,
              height: isMobile ? 48 : undefined,
              float: isMobile ? 'none' : 'right',
            }}
            disabled={loading || error}
            loading={loading}
            block={isMobile}
          >
            SUBMIT
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreateLenderReview;
