import React from 'react';
import { Modal, Divider } from 'antd';
import {
  GlobalOutlined,
  InfoCircleOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import './LocationPopUp.css';
import USER_LOCATIONS from '../../constants/locations';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import isStanfordEmail from './stanfordFirewall';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import { useHistory } from 'react-router-dom';

function LocationPopUp({
  setLocationBoolean,
  locationModalVisible,
  setLocationModalVisible,
}) {
  const { token } = useLoggedInMemberInfo();
  const history = useHistory();
  function showModal() {
    setLocationModalVisible(true);
  }
  function handleOk() {
    console.log('okay!');
    // setLocationModalVisible(true);
  }
  function handleCancel() {
    console.log('cancel!');
    // setLocationModalVisible(true);
  }
  function selectLocation(e) {
    const option = e.currentTarget.getAttribute('value');
    localStorage.setItem(LOCAL_STORAGE.location, option);
    setLocationModalVisible(false);
    setLocationBoolean(true);
  }
  const handleSelectStanford = (e) => {
    if (token) {
      if (!isStanfordEmail()) {
        window.confirm(
          'Are you a Stanford student? If so, make sure to use your stanford.edu email for full access to this region'
        );
      }
    }
    localStorage.setItem(
      LOCAL_STORAGE.location,
      e.currentTarget.getAttribute('value')
    );
    setLocationModalVisible(false);
    setLocationBoolean(true);
  };

  const ShowStanfordMessage = () => {
    return !isStanfordEmail() ? (
      <div>
        <InfoCircleOutlined />
        <span style={{ fontStyle: 'italic', color: 'grey', margin: 5 }}>
          Requires valid Stanford University email
        </span>
      </div>
    ) : (
      <div />
    );
  };

  return (
    <>
      <div id="location-cover" onClick={showModal}></div>
      <Modal
        visible={locationModalVisible}
        title="Select your region!"
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={[]}
      >
        <Divider>Locations</Divider>
        <p
          onClick={selectLocation}
          value={USER_LOCATIONS.Nashville}
          className="location-selection"
        >
          <GlobalOutlined /> Nashville
        </p>
        <p
          onClick={selectLocation}
          value={USER_LOCATIONS.BayArea}
          className="location-selection"
        >
          <GlobalOutlined /> Bay Area
        </p>
        <div
          onClick={handleSelectStanford}
          value={USER_LOCATIONS.Stanford}
          className="location-selection"
        >
          <GlobalOutlined /> Stanford University
          <ShowStanfordMessage />
        </div>
        <Divider>Organizations</Divider>
        <p
          onClick={selectLocation}
          value={USER_LOCATIONS.VetAdventureGroup}
          className="location-selection"
        >
          <ShopOutlined /> Veteran's Adventure Group
        </p>
      </Modal>
    </>
  );
}

export default LocationPopUp;
