import queryString from 'query-string';

const parseUrlParams = (search, defaults) => {
  const { a, ...parsedSearch } = queryString.parse(search);
  const parsedB64 = typeof a === 'string' ? queryString.parse(atob(a)) : {};
  const mergedDefaults = { ...defaults, ...parsedB64, ...parsedSearch };

  return Object.fromEntries(
    Object.entries(mergedDefaults)
      .map(([k, v]) => (typeof v === 'string' ? [k, v] : [k, undefined]))
      .filter(([, v]) => typeof v === 'string')
  );
};

export default parseUrlParams;
