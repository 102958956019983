import { urlConditional } from './apiInfo';

const baseUrl = urlConditional();

export default {
  async getProductReviews({ productId }) {
    const resp = await fetch(
      `${baseUrl}/product-reviews?product=${productId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );
    return await resp.json();
  },
  async getProductReview({ reviewId }) {
    const resp = await fetch(`${baseUrl}/product-reviews?id=${reviewId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return await resp.json();
  },
  async postProductReview(review) {
    const resp = await fetch(`${baseUrl}/product-reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(review),
    });
    return await resp.json();
  },
  async updateProductReview({ reviewId, updatedReview }) {
    const resp = await fetch(`${baseUrl}/product-reviews/${reviewId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(updatedReview),
    });
    return resp;
  },
  async deleteProductReview({ productId, reviewId }) {
    const resp = await fetch(`${baseUrl}/product-reviews/${reviewId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(productId),
    });
    return await resp;
  },
};
