import React from 'react';
import '../buy/searchNav.css';

const HomePageSearchBar = ({ changeSearchTerm, routerProps }) => {
  function handleSearch(e) {
    if (e.key === 'Enter') {
      if (window.location.pathname != '/rent') {
        changeSearchTerm(e.target.value);
        routerProps.history.push('/rent', changeSearchTerm(e.target.value));
      } else {
        document.getElementById('mainSearchbar').value = '';
        changeSearchTerm(e.target.value);
      }
    }
  }

  return (
    <div className="flexRow searchBarContainer">
      <input
        id="mainSearchBar"
        className="searchBarMain"
        type="text"
        placeholder="Get Gear. Get Going."
        onKeyUp={handleSearch}
      />
    </div>
  );
};

export default HomePageSearchBar;
