import { urlConditional } from '../apiInfo';

const baseUrl = urlConditional();

const messagingAPI = {
  getMessageGroups() {
    return fetch(`${baseUrl}/messagegroups`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
  getMessages(messageChannel) {
    return fetch(`${baseUrl}/messages?channel=${messageChannel}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
  createMessageGroup(channelObj) {
    // Channel name is the terminology for pubnub. It is the name of our MemberGroup
    // Expecting channel name. Convert into object with key 'channel'.
    return fetch(`${baseUrl}/messagegroups`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        channel: channelObj.channel,
        product_id: channelObj.product_id,
      }),
    }).then((resp) => resp.json());
  },
  createMessage(messageInfo) {
    // Expecting object with keys 'channel' and 'message'.
    return fetch(`${baseUrl}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(messageInfo),
    }).then((resp) => resp.json());
  },
  checkUnreadMessages(memberId) {
    return fetch(`${baseUrl}/messages/${memberId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }).then((resp) => resp.json());
  },
  markMessageRead(messageId) {
    return fetch(`${baseUrl}/messages/${messageId}?mark_message_as_read=true`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ message_id: messageId, read: true }),
    }).then((resp) => resp.json());
  },
};

export default messagingAPI;
