import React from 'react';
import Icon, { StarOutlined, StarFilled } from '@ant-design/icons';

import PartialStar from './PartialStar';
import './PartialStar.css';

const NUM_STARS_DEFAULT = 5;

const StarRatingAvg = ({ avgRating, numStarsOverride }) => {
  const numStars = numStarsOverride || NUM_STARS_DEFAULT;
  const stars = [];
  for (let i = 0; i < numStars; i++) {
    const key = `product-rating--${i}`;
    if (i < Math.floor(avgRating)) {
      stars.push(<Icon component={StarFilled} key={key} />);
    } else if (i < avgRating && i + 1 > avgRating) {
      const decimalValue = avgRating % 1;
      stars.push(<PartialStar key={key} decimal={decimalValue} />);
    } else {
      stars.push(<Icon key={key} component={StarOutlined} />);
    }
  }
  return (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      {stars}
    </span>
  );
};

export default StarRatingAvg;
