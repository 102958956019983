import React from 'react';
import { PaymentForm } from './PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ArrowLeftOutlined } from '@ant-design/icons';

let stripePromise;
try {
  // stripePromise = loadStripe(
  //   'pk_test_51ISrqACqdob3IuhsqnMwwocBBqM6rUbK3NZSRIRgRK0TMHMO3IDD69NzQgoQKTonCnRmIOBjajQymTg7AaNMTVPq005mkbcsCF'
  // );

  stripePromise = loadStripe(
    'pk_live_51ISrqACqdob3IuhstCu9dG939VUJL2DKYbm3NfMm7utarVoNIUfWloUFiARTeFTie59qDb2xzlDnSUHAMDcOvlGW007O86hN0d'
  );
} catch (err) {
  stripePromise = 'Cannot Connect';
}

export const Payment = ({
  secret,
  handlePaymentSuccess,
  handleBackToGearSelect,
  orderProducts,
  order,
  cancelPayment,
  setConfirmButtonClicked,
  confirmButtonClicked,
  paymentResponse,
}) => {
  return (
    <div className="payment-component">
      <div className="productDetails-backButton-container payment-back-button-container">
        <ArrowLeftOutlined
          className="productDetails-backButton checkout-back-button"
          onClick={handleBackToGearSelect}
        />
      </div>
      <div className="payment-title">Payment</div>
      <div className="payment-hr-container">
        <hr className="payment-hr" />
      </div>
      <Elements stripe={stripePromise} options={{ clientSecret: secret }}>
        <PaymentForm
          secret={secret}
          handlePaymentSuccess={handlePaymentSuccess}
          handleBackToGearSelect={handleBackToGearSelect}
          orderProducts={orderProducts}
          order={order}
          cancelPayment={cancelPayment}
          setConfirmButtonClicked={setConfirmButtonClicked}
          confirmButtonClicked={confirmButtonClicked}
          paymentResponse={paymentResponse}
        />
      </Elements>
    </div>
  );
};
