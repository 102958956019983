import React, { useState, useEffect } from 'react';
import { Input, Switch } from 'antd';
import './GearRequest.css';

const SearchBar = ({ searchTerm, setSearchTerm, routerProps }) => {
  const { Search } = Input;
  const [input, setInput] = useState();

  function handleSearch(value) {
    setSearchTerm(value);
  }

  const handleFieldChange = (evt) => {
    const value = evt.target.value;
    setInput(value);
  };

  return (
    <div className="product-request-searchbar">
      <Search
        onChange={handleFieldChange}
        placeholder="Search requests . . ."
        onSearch={(value) => handleSearch(value)}
        enterButton
      />
    </div>
  );
};

export default SearchBar;
