import React, { useState, useEffect } from 'react';
import productManager from '../../modules/productManager';
import { Card, Tooltip } from 'antd';
import {
  UnorderedListOutlined,
  MailOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import EmailLenderPopup from '../buy/EmailLenderPopup';
import noImageGears from '../../images/noImageGears.png';
import { useHistory } from 'react-router-dom';
import ProductSlideshow from '../productImage/ProductSlideshow';
import StarRatingAvg from '../StarRatingAvg';

const OtherProfileProductCard = (props) => {
  const [imageLandscapeOrPortrait, setImageLandscapeOrPortraint] =
    useState(null);

  const {
    product: { avg_rating, count_ratings },
  } = props;
  const history = useHistory();
  function routePath() {
    history.push(`/rent/${props.product.id}`);
  }

  function setImageOrientation(orientation) {
    setImageLandscapeOrPortraint(orientation);
  }

  function testImageDimensions() {
    const img = new Image();
    img.src = props.product.image_path;
    img.onload = function () {
      if (this.width > this.height) {
        setImageLandscapeOrPortraint('landscape');
      } else if (this.height >= this.width) {
        setImageLandscapeOrPortraint('portrait');
      }
    };
  }

  function returnPortraitImage() {
    if (imageLandscapeOrPortrait === 'portrait') {
      return (
        <img
          className="clickable"
          onClick={routePath}
          alt="product"
          src={props.product.image_path}
        />
      );
    }
  }

  useEffect(() => {
    testImageDimensions();
    returnPortraitImage();
  }, []);
  return (
    <Card
      className={`productCard ${imageLandscapeOrPortrait} other-member-card`}
      actions={[
        <Tooltip key="tooltip" placement="left" title="Details">
          <UnorderedListOutlined
            className="clickable"
            key="details"
            onClick={routePath}
          />
        </Tooltip>,
      ]}
    >
      <div className="product-card-image-container">
        <img
          className="product-card-image"
          src={props.product.image_paths[0]}
          style={{ cursor: 'pointer' }}
          onClick={routePath}
        />
      </div>
      <ul style={{ textAlign: 'left', listStyleType: 'none' }}>
        <h3 onClick={routePath}>{props.product.title}</h3>
        <li>
          <StarRatingAvg avgRating={avg_rating} />
          <span> {count_ratings || 0}</span>
        </li>
        <li>Quality: {`${props.product.quality}`}</li>
        <li>
          Price: $<span className="price">{`${props.product.price}`}</span> /
          day
        </li>
      </ul>
    </Card>
  );
};

export default OtherProfileProductCard;
