import React, { useState, useEffect } from 'react';
import { Button, Spin, Dropdown, Menu } from 'antd';
import { MailOutlined, MoreOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import CustomerManager from '../../modules/customerManager';
import productManager from '../../modules/productManager';
import LenderReviewManager from '../../modules/LenderReviewManager';
import OtherProfileProductCard from './OtherProfileProductCard';
import ProfileIcon from '../../images/profileIcon.png';

import './Profile.css';
import LenderReview from './LenderReviews/LenderReview';
import StarRatingAvg from '../StarRatingAvg';
import ReviewButton from '../buy/ProductReviews/ReviewButton';
import RenterReviewManager from '../../modules/RenterReviewManager';
import RenterReview from './RenterReviews/RenterReview';
import snapToTopOfPage from '../../helpers/snapToTopOfPage';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';

const SubHeader = ({ children }) => {
  return (
    <h1
      className="subheadline-font"
      style={{
        marginTop: '80px',
        marginLeft: '10%',
        fontSize: '3em',
        alignSelf: 'left',
      }}
    >
      {children}
    </h1>
  );
};

const OtherMemberProfile = ({ routerProps }) => {
  const { id: otherMemberId } = useParams();
  const [memberInfo, setMemberInfo] = useState(null);
  const [lenderProducts, setLenderProducts] = useState(null);
  const [lenderReviews, setLenderReviews] = useState([]);
  const [renterReviews, setRenterReviews] = useState([]);
  const { isLoggedIn, memberId: memberIdLocal } = useLoggedInMemberInfo();

  const getCurrentMember = (member_id) => {
    CustomerManager.getCustomer(member_id).then((resp) => {
      setMemberInfo(resp);
    });
  };
  const getLenderGear = (lender_id) => {
    productManager.getLenderGear(lender_id).then((resp) => {
      setLenderProducts(resp);
    });
  };
  const getLenderReviews = async (lenderId) => {
    const resp = await LenderReviewManager.getLenderReviews({ lenderId });
    setLenderReviews(resp);
  };
  const getRenterReviews = async (renterId) => {
    const resp = await RenterReviewManager.getRenterReviews({ renterId });
    setRenterReviews(resp);
  };
  const {
    id: memberId,
    user,
    image_path,
    address,
    hobbies,
    avg_rating,
    count_ratings,
    avg_renter_rating,
    count_renter_ratings,
  } = memberInfo || {};
  const { first_name, last_name, username } = user || {};

  function goToMessages() {
    if (!isLoggedIn) {
      window.alert('Please log in or create an account to email lender');
    } else {
      // setIsEmailModalVisible(!isEmailModalVisible)
      routerProps.history.push(`/messages?direct=${true}`, {
        memberId: memberIdLocal,
        lenderId: memberId,
        product_id: null,
        messagePrompt: true,
      });
      snapToTopOfPage();
    }
  }

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="0">Block Member</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setTimeout(() => {
      getCurrentMember(otherMemberId);
      getLenderGear(otherMemberId);
      getLenderReviews(otherMemberId);
      getRenterReviews(otherMemberId);
    }, 500);
  }, []);

  if (memberInfo && lenderProducts) {
    return (
      <div className="">
        <div id="profile-section-container">
          <div className="flex-column">
            <div className="profile-image-container">
              {image_path ? (
                <img
                  className="profileImage"
                  style={{ boxShadow: 'none' }}
                  src={image_path}
                  alt="profile"
                />
              ) : (
                <img
                  className="detailsImage"
                  style={{ boxShadow: 'none' }}
                  src={ProfileIcon}
                  alt="profile"
                />
              )}
            </div>
          </div>
          <div id="profile-details-container">
            <h1 className="headline-font" style={{ whiteSpace: 'nowrap' }}>
              {first_name} {last_name}
              <Dropdown overlay={dropdownMenu} trigger={['click']}>
                <MoreOutlined onClick={(e) => e.preventDefault()} />
              </Dropdown>
            </h1>
            <div style={{ fontSize: 18 }}>
              <div>
                <div>Lender</div>
                <StarRatingAvg avgRating={avg_rating} />
                <span style={{ paddingLeft: 8, verticalAlign: 'middle' }}>
                  {count_ratings ? (
                    <a
                      href="#lender-reviews"
                      title="Go to reviews"
                    >{`${count_ratings} ratings`}</a>
                  ) : null}
                </span>
              </div>
              <div>
                <div>Renter</div>
                <StarRatingAvg avgRating={avg_renter_rating} />
                <span style={{ paddingLeft: 8, verticalAlign: 'middle' }}>
                  {count_renter_ratings ? (
                    <a
                      href="#lender-reviews"
                      title="Go to reviews"
                    >{`${count_renter_ratings} ratings`}</a>
                  ) : null}
                </span>
              </div>
            </div>
            <h3>
              <div className="body-font">Username: </div>
              <div className="subHeadline-font">{username}</div>
            </h3>
            {/* <h3 className="body-font">{memberInfo.phone_number}</h3> */}

            <h3 className="body-font">{address}</h3>
            <h3 className="body-font">
              Hobbies:
              <div className="subHeadline-font">{hobbies}</div>
            </h3>
            <Button
              onClick={goToMessages}
              // style={{ position: 'absolute', right: '10px', top: '10px' }}
            >
              <MailOutlined />
              message {first_name}
            </Button>
          </div>
        </div>

        <div className="profileContainer ">
          {lenderProducts.length > 0 ? (
            <>
              <SubHeader>{first_name}'s Shop</SubHeader>
              <div className="lenderGearContainer yourShopContainer">
                {lenderProducts.map((productObj, i) => {
                  return (
                    <OtherProfileProductCard key={i} product={productObj} />
                  );
                })}
              </div>
            </>
          ) : null}
          <div id="lender-reviews">
            <SubHeader>Lender Reviews</SubHeader>
            <ReviewButton path={`/review/lender/create?id=${memberId}`} />
            {lenderReviews.map((review) => (
              <LenderReview key={review.id} review={review} />
            ))}
          </div>
          <div id="renter-reviews">
            <SubHeader>Renter Reviews</SubHeader>
            <ReviewButton path={`/review/renter/create?id=${memberId}`} />
            {renterReviews.map((review) => (
              <RenterReview key={review.id} review={review} />
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <h1 style={{ marginLeft: '40%', marginTop: '20%' }}>
        Finding Member <Spin size="large" />
      </h1>
    );
  }
};

export default OtherMemberProfile;
