import { urlConditional } from '../apiInfo';

const baseUrl = urlConditional();

export default function sendLenderEmail(message) {
  return fetch(`${baseUrl}/sendlenderemail`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
    body: message,
  }).then((resp) => resp.json());
}
