import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Spin } from 'antd';
import memberManager from '../../modules/customerManager';
import compressImage from '../../helpers/compressImage';
import ProfileIcon from '../../images/profileIcon.png';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import MemberManager from '../../modules/customerManager';
import { useHistory } from 'react-router-dom';

const EditProfileModal = ({
  memberInfo,
  setMemberInfo,
  isEditModalVisible,
  setIsEditModalVisible,
}) => {
  const [buttonClicked, changeButtonClicked] = useState(false);
  const [image, setImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(memberInfo.imagePath);
  const [credentials, setCredentials] = useState({
    username: memberInfo.user.email,
    first_name: memberInfo.user.first_name,
    last_name: memberInfo.user.last_name,
    email: memberInfo.user.email,
    address: memberInfo.address,
    phone_number: memberInfo.phone_number,
    hobbies: memberInfo.hobbies,
    referred_by: memberInfo.referred_by,
  });
  const { memberId } = useLoggedInMemberInfo();

  const history = useHistory();

  const handleFieldChange = (evt) => {
    const stateToChange = { ...credentials };
    stateToChange[evt.target.id] = evt.target.value;
    setCredentials(stateToChange);
  };

  function handleFileUpload(e) {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;
    const file = e.target.files[0];
    if (file) {
      if (!allowedExtensions.exec(e.target.value)) {
        window.alert(
          'Invalid file type. Please use jpg, jpeg, png, gif, or webp'
        );
      } else {
        let file;
        let reader = new FileReader();
        file = e.target.files[0];
        if (file) {
          if (file.size < 600000) {
            //
            reader.onload = function (e) {
              let imgLink = e.target.result;
              setImage({
                imageFile: file,
                imagePath: file.name,
                imagePreview: imgLink,
              });
            };
            reader.readAsDataURL(file);
          } else {
            // compress files that are too large
            compressImage(file).then((compressedImage) => {
              reader.onload = function (e) {
                let imgLink = e.srcElement.result;
                setImage({
                  imageFile: compressedImage,
                  imagePath: file.name,
                  imagePreview: imgLink,
                });
              };
              reader.readAsDataURL(compressedImage);
            });
          }
        }
      }
    }
  }

  const handleProfileEdit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('username', credentials.email);
    formData.append('first_name', credentials.first_name);
    formData.append('last_name', credentials.last_name);
    formData.append('email', credentials.email.toLowerCase());
    formData.append('phone_number', credentials.phone_number);
    formData.append('hobbies', credentials.hobbies);
    {
      image
        ? formData.append('image_path', image.imageFile, image.imagePath)
        : formData.append('image_path', false);
    }

    if (
      credentials.username &&
      credentials.first_name &&
      credentials.last_name &&
      credentials.email &&
      credentials.phone_number &&
      credentials.hobbies
    ) {
      changeButtonClicked(true);
      return memberManager
        .updateMember(memberId, formData)
        .then((parsedResponse) => {
          if ('id' in parsedResponse) {
            setMemberInfo(parsedResponse);
            setTimeout(() => {
              setIsEditModalVisible(false);
              changeButtonClicked(false);
            }, 500);
          } else {
            window.alert('Please fix your credentials and try again.');
            changeButtonClicked(false);
          }
        });
    } else {
      window.alert('Please fill out all fields');
      changeButtonClicked(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('memberId');
    localStorage.removeItem('token');
    setTimeout(() => {
      history.push('/');
    }, 500);
  };

  const handleDeleteAccount = () => {
    if (
      window.confirm(
        'Are you sure you want to delete your account? It cannot be recovered after deletion.'
      )
    ) {
      const memberId = localStorage.getItem('memberId');
      MemberManager.deleteAccount(memberId);
      logout();
    }
  };

  function buttonOrLoading() {
    return buttonClicked ? (
      <Button type="primary">
        <Spin style={{ marginLeft: '5px' }} />
      </Button>
    ) : (
      <>
        <Button className="delete-account-button" onClick={handleDeleteAccount}>
          Delete Account
        </Button>
        <Button key="submit" type="primary" onClick={handleProfileEdit}>
          Save
        </Button>
      </>
    );
  }

  const handleCancel = () => {
    setIsEditModalVisible(false);
  };

  function imageThumbnailConditional() {
    if (!memberInfo) {
      return (
        <img
          className="detailsImage"
          style={{ boxShadow: 'none', minWidth: '100px', width: '100px' }}
          src={ProfileIcon}
          alt="profile"
        />
      );
    } else if (image) {
      return (
        <img
          className="detailsImage"
          style={{ boxShadow: 'none', width: '200px' }}
          src={image.imagePreview}
          alt="profile"
        />
      );
    } else if (memberInfo.image_path) {
      return (
        <img
          className="detailsImage"
          style={{ boxShadow: 'none', width: '200px' }}
          src={memberInfo.image_path}
          alt="profile"
        />
      );
    }
  }

  useEffect(() => {
    buttonOrLoading();
  }, [buttonClicked]);

  useEffect(() => {
    imageThumbnailConditional();
  }, [memberInfo.imagePath]);

  return (
    <Modal
      title="Edit My Profile"
      visible={isEditModalVisible}
      onOk={handleProfileEdit}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        buttonOrLoading(),
      ]}
    >
      <section id="creationForm">
        {imageThumbnailConditional()}
        <fieldset>
          <Input onChange={handleFileUpload} type="file" id="image_path" />
        </fieldset>
        <form onSubmit={handleProfileEdit}>
          <fieldset>
            <Input
              onChange={handleFieldChange}
              type="text"
              id="first_name"
              placeholder="First Name"
              value={credentials.first_name}
            />
          </fieldset>
          <fieldset>
            <Input
              onChange={handleFieldChange}
              type="text"
              id="last_name"
              placeholder="Last Name"
              value={credentials.last_name}
            />
          </fieldset>
          <fieldset>
            <Input
              onChange={handleFieldChange}
              type="email"
              id="email"
              placeholder="Email"
              value={credentials.email}
            />
          </fieldset>
          <fieldset>
            <Input
              onChange={handleFieldChange}
              type="text"
              id="phone_number"
              placeholder="Phone Number"
              value={credentials.phone_number}
            />
          </fieldset>
          <fieldset>
            <Input
              onChange={handleFieldChange}
              type="text"
              id="hobbies"
              placeholder="Hobbies"
              value={credentials.hobbies}
            />
          </fieldset>
        </form>
      </section>
    </Modal>
  );
};

export default EditProfileModal;
