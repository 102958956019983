const location = process.env.REACT_APP_BASE_URL;

export const urlConditional = () => {
  if (
    window.location.href.includes('app') ||
    window.location.href.includes('gear2go.us')
  ) {
    return `${location}`;
  } else {
    return 'http://localhost:8000';
  }
};

export const linkConditional = () => {
  if (
    window.location.href.includes('app') ||
    window.location.href.includes('gear2go.us')
  ) {
    return `https://gear2go.us`;
  } else {
    return 'http://localhost:3000';
  }
};
