import React, { useEffect } from 'react';
import OtherMembemberProductCard from '../userProfile/OtherProfileProductCard';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import GearBag from '../../images/gearbag.png';
import { useFormattedLocation } from '../../constants/locations';

export default function HomeGearSection({ gearSection, title }) {
  const history = useHistory();
  const userLocation = useFormattedLocation();

  function createProductCards() {
    if (gearSection !== null && gearSection !== undefined) {
      return (
        <>
          {gearSection.map((productObj, i) => {
            if (i < 5) {
              return <OtherMembemberProductCard key={i} product={productObj} />;
            }
          })}
        </>
      );
    } else if (gearSection == null) {
      return (
        <h1 style={{ marginLeft: '15%', marginTop: '100px' }}>
          Finding Gear <Spin />
        </h1>
      );
    }
  }

  useEffect(() => {
    createProductCards();
  }, [gearSection]);

  return (
    <>
      <h2 className="browse-title">
        {title} in {userLocation}
      </h2>
      <article className="recent-gear-container">
        {createProductCards()}
        <img
          className="go-to-rent-icon"
          src={GearBag}
          onClick={() => history.push('/rent')}
        ></img>
      </article>
    </>
  );
}
