import React, { useEffect, useState } from 'react';
import { Card, Modal, Tooltip, message } from 'antd';
import {
  UnorderedListOutlined,
  MailOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { QuickMessage } from '../messages/QuickMessageMui';
import GearBag from '../../images/gearbag.png';
import StarRatingAvg from '../StarRatingAvg';
import filterWords from '../../modules/filters/bad-words';
import snapToTopOfPage from '../../helpers/snapToTopOfPage';
import favoritesProductManager from '../../modules/favoritesProductManager';
import useLoggedInMemberInfo from '../../hooks/useLoggedInMemberInfo';
import PricePerDuration from '../PricePerDuration';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import { Button } from '@mui/material';

const ProductCard = ({
  productObj,
  routerProps,
  isGearBag,
  favoritesProductId,
}) => {
  const { isLoggedIn, memberId } = useLoggedInMemberInfo();
  const { avg_rating: avgRating, count_ratings: numRatings } = productObj;

  const [isQuickMessageVisible, setIsQuickMessageVisible] = useState(
    () => false
  );
  const [scrollYPosition, setScrollYPosition] = useState();

  function goToMessages() {
    if (!isLoggedIn) {
      message.error('Please log in or create an account to email lender');
    } else if (memberId == productObj.member_id) {
      window.alert(
        'This is your own product! Other folks have also posted awesome gear, check some of theirs out!'
      );
    } else {
      routerProps.history.push(`/messages?direct=${true}`, {
        memberId: memberId,
        lenderId: productObj.member_id,
        product_id: productObj.id,
        product: productObj,
        messagePrompt: true,
      });
      snapToTopOfPage();
    }
  }

  const addToCart = () => {
    if (memberId == productObj.member_id) {
      window.alert(
        'This is your own product! Other folks have also posted awesome gear, check some of theirs out!'
      );
    } else {
      favoritesProductManager
        .createFavoritesProduct(productObj.id)
        .then((resp) => {
          if (resp.valid == false) {
            window.alert(resp.error);
          } else if (resp.id) {
            window.alert('Item has been added to your gear bag!');
          } else {
            window.alert('Error please try again');
          }
        });
    }
  };

  const deleteFromCart = () => {
    if (
      window.confirm(`${productObj.title} will be deleted from your gear bag`)
    ) {
      favoritesProductManager
        .deleteFavoritesProduct(favoritesProductId)
        .then(() => {
          window.location.reload();
        });
    }
  };

  function routePath() {
    routerProps.history.push(`/rent/${productObj.id}`);
  }

  function goToLogin() {
    routerProps.history.push('/login');
  }

  const isMobileView = () => {
    if (window.innerWidth < 420) return true;
    return false;
  };

  const displayQuickMessage = () => {
    const memberIdLS = localStorage.getItem(LOCAL_STORAGE.memberId);
    const productMemberId = productObj.member_id.toString();
    if (!isLoggedIn) {
      message.error('Please log in or create an account to message lender');
    } else if (memberIdLS == productMemberId) {
      window.alert(
        'This is your own product! Other folks have also posted awesome gear, check some of theirs out!'
      );
    } else if (!isQuickMessageVisible) {
      setIsQuickMessageVisible(true);
    }
  };

  const closeQuickMessage = () => {
    if (isQuickMessageVisible) setIsQuickMessageVisible(false);
  };

  const setupCardButtons = () => {
    const tooltipStyle = isMobileView() ? { visibility: 'none' } : undefined;
    return [
      <Tooltip
        placement="left"
        title="Details"
        style={tooltipStyle}
        key="details"
      >
        <UnorderedListOutlined
          className="clickable"
          key="details"
          onClick={routePath}
        />
      </Tooltip>,
      <Tooltip
        onClick={displayQuickMessage}
        placement="bottom"
        title="Contact Lender"
        style={tooltipStyle}
        key="contact"
        visible={!isMobileView}
      >
        <MailOutlined className="clickable" key="contact" />
      </Tooltip>,
    ];
  };

  const RentFromButton = () => (
    <div style={{ padding: 4, display: 'flex', justifyContent: 'center' }}>
      <Button onClick={goToMessages} variant="contained">
        Rent from {productObj.member.user.first_name}
      </Button>
    </div>
  );

  return (
    <div style={{ flexFlow: 'column' }}>
      <Card className={`productCard`} actions={setupCardButtons()}>
        <div className="product-card-image-container">
          <img
            className="product-card-image"
            src={productObj.image_paths[0]}
            style={{ cursor: 'pointer' }}
            onClick={routePath}
          />
        </div>
        <ul
          className="product-card-details-list"
          style={{ listStyleType: 'none' }}
        >
          <li
            className="card-title"
            onClick={routePath}
            style={{ cursor: 'pointer' }}
          >
            <h3>{filterWords.cleanHacked(productObj.title)}</h3>
          </li>
          <li>
            <StarRatingAvg avgRating={avgRating} />
            <span style={{ paddingLeft: 8 }}>{numRatings || 0}</span>
          </li>
          <li>
            <PricePerDuration
              price={productObj.price}
              duration={productObj.duration}
            />
          </li>
          <li>Quantity: {`${productObj.quantity}`}</li>
          <li>Posted by {`${productObj.member.user.first_name}`}</li>
        </ul>
      </Card>
      {isGearBag && <RentFromButton />}
      <QuickMessage
        isQuickMessageVisible={isQuickMessageVisible}
        closeQuickMessage={closeQuickMessage}
        productId={productObj.id}
        productTitle={productObj.title}
      />
    </div>
  );
};

export default ProductCard;
